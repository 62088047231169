export const events = {
  investorLoggedOut: 'Investor Logged Out',
  investorResetPassword: 'Investor Reset Password',
  dealListViewed: 'Deals List Viewed',
  allDealsListViews: 'All Deals List Viewed',
  assetLeasingDealsListViewed: 'Asset Leasing Deals List Viewed',
  invoiceDiscountingDealsListViewed: ' Invoice Discounting Deals List Viewed',
  dealViewed: 'Deal Viewed',
  dealDetailViewed: 'Deal Detail Viewed',
  kycInitiated: 'KYC Initiated',
  kycDetailsSaved: 'KYC Details Saved',
  kycCompleted: 'KYC Completed',
  emailVerificationRequested: 'Email Verification Requested',
  dealAdded: 'Deal Added',
  dealInvestmentDetailsViewed: 'Deal Investment Details Viewed',
  dealAgreementViwed: 'Deal Agreement Viewed',
  dealCheckout: 'Deal Purchased',
  investmentDetailViewed: 'Investment Detail Viewed',
  scheduleCall: 'Schedule Call',
  dealSubscribed: 'Deal Subscribed',
  investorSignup: 'Investor Signup',
  investorLogin: 'Investor Login',
  authScreenViewed: 'Auth Screen Viewed',
  authScreenExited: 'Auth Screen Exited',
  authModeChanged: 'Auth Mode Changed',
  regScreenViewed: 'Registration Screen Viewed',
  regScreenExited: 'Registration Screen Exited',
  dealAssetScreenViewed: 'Deal Assets Screen Viewed',
  dealAssetScreenExited: 'Deal Assets Screen Exited',
  dealAssetSelected: 'Deal Asset Selected',
  dealAssetRemoved: 'Deal Asset Removed',
  dealAssetsFiltered: 'Deal Assets Filtered',
  dealAssetsSorted: 'Deal Assets Sorted',
  dealAssetsCartScreenViewed: 'Deal Assets Cart Screen Viewed',
  dealAssetsCartScreenExited: 'Deal Assets Cart Screen Exited',
  dealAddSuccess: 'Deal Add Success',
  dealAddFailed: 'Deal Add Failed',
  clickEvent: 'Click Event',
  payoutSchedulePopupViewed: 'Payout Schedule Popup Viewed',
  taxNotePopupViewed: 'Tax Note Popup Viewed',
  assetDetailsScreenViewed: 'Asset Details Screen Viewed',
  referralStatScreenViewed: 'Referral Stat Screen Viewed',
  referralOptinScreenViewed: 'Referral Opt In Screen Viewed',
  upsidePageViewed: 'Upside Page Viewed',
  referralIAgreeClicked: 'Referral I Agree',
  referralSignup: 'Referral Signup',
  referralTermsAndConditionPopupViewed: 'Referral Terms And Condition Popup Viewed',
  referralCodeCopyClicked: 'Referral Code Copy',
  referralLinkCopyClicked: 'Referral Link Copy',
  referralLinkShareClicked: 'Referral Link Share',
  referralFaqScreenViewed: 'Referral Faq Screen Viewed',
  totalReferralStatusPopupViewed: 'Total Referral Stats Popup Viewed',
  investedReferralStatusPopupViewed: 'Invested Referral Stats Popup Viewed',
  totalRewardStatusPopupViewed: 'Total Reward Stats Popup Viewed',
  rewardRatePopupViewed: 'Reward Rate Popup Viewed',
  upsideFAQsViewed: 'Upside FAQs Viewed',
  webVitals: 'Web Vitals',
  paymentUploadReference: 'Payment Upload Reference Exited',
  apiErrored: 'API Errored',
  InvestmentAmountEntered: 'Investment Amount Entered',
  BucketsGenerationSuccess: 'Buckets Generation Success',
  BucketsGenerationFailed: 'Buckets Generation Failed',
  BucketSelected: 'Bucket Selected',
  AssetSelectionPopupViewed: 'Asset Selection Popup Viewed',
  AssetSelectedChanged: 'Asset Selection Changed',
  AssetSelectionPopupCancelled: 'Asset Selection Popup Cancelled',
  AssetSelectionEditSelectionClicked: 'Asset Selection Edit Selection Clicked',
  AssetSelectionViewSummaryClicked: 'Asset Selection View Summary Clicked',
  PermissionScreenExited: 'Permissions Screen Exited',
  ALTaxReportDownloadClicked: 'AL Tax Report Download Clicked',
  IDTaxReportDownloadClicked: 'ID Tax Report Download Clicked',
  InvestmentIsBlockedDueToTDS: 'Investment blocked due to TDS',
  InvestorDashboardViewed: 'Investor dashboard viewed',
  ProfileDropdownViewed: 'Profile Dropdown Viewed',
  ProfileDetailsViewed: 'Profile Details Viewed',
  ChangePasswordInitiated: 'Change Password Initiated',
  ChangePasswordSuccess: 'Change Password Success',
  SupportSectionViewed: 'Support Section Viewed',
  SupportWhatsAppClicked: 'Support Section WhatsApp Clicked',
  SupportEmailClicked: 'Support Email Clicked',
  TeamPageViewed: 'Team Page Viewed',
  TnCViewed: 'TnC Viewed',
  PrivacyPolicyViewed: 'Privacy Policy Viewed',
  RiskDisclosureViewed: 'Risk Disclosure Viewed',
  CompanyLinkedInClicked: 'Company LinkedIn Clicked',
  CompanyInstagramClicked: 'Company Instagram Clicked',
  tapAssure: 'Tap Assure',
  DealCompanyFounderLinkedInClicked: 'Deal Company Founder LinkedIn Clicked',
  DealCompanyBrandMentionsClicked: 'Deal Company Brand Mentions Clicked',
  DealDetailPopupViewed: 'Deal Detail Popup Viewed',
  DealOverviewViewed: 'Deal Overview Viewed',
  ClickedOnChangePaymentMethod: 'Clicked On Change Payment Method',
  PaymentOptionSelected: 'Payment Option Selected',
  ClickWrapClicked: 'Clickwrap Clicked ',
  ClickedOnUploadNow: 'Clicked On Upload Now',
  ClickedUploadLater: 'Clicked Upload Later',
  ClickedOnChooseFile: 'Clicked On Choose File',
  SubmitPaymentProof: 'Submit Payment Proof',
  FAQsViewed: 'FAQs Viewed',
  ClickedValidatePAN: 'Clicked On Validate PAN',
  PANConfirmAndProceed: 'PAN Confirm and Proceed',
  aadhaarInitiated: 'Aadhaar Initiated',
  aadhaarOtpInitiated: 'Aadhaar OTP Initiated',
  AadhaarVerificationSuccess: 'Aadhaar Verification Success',
  AadhaarConfirmAndProceed: 'Aadhaar Confirm and Proceed',
  BankDetailsInitiated: 'Bank Details Initiated',
  VerifyAddress: 'Verify Address',
  SubmitBankDetails: 'Submit Bank Details',
  AgreedSourceOfFunds: 'Agreed Source Of Funds',
  NCDFormViewed: 'NCD Form Viewed',
  WalletOverviewViewed: 'Wallet Overview Viewed',
  WalletTransactionViewed: 'Wallet Transaction Viewed',
  WalletFAQsViewed: 'Wallet FAQs Viewed',
  WalletFilterSelected: 'Wallet Filter Selected',
  WalletDateRangeSet: 'Wallet Date Range Set',
  WalletAddMoneyOptionClicked: 'Wallet Add Money Option Clicked',
  BankDetailsTextCopied: 'Bank Details Text Copied',
  WalletWithdrawInitiated: 'Wallet Withdraw Initiated',
  WalletWithdrawSuccess: 'Wallet Withdraw Success',
  WalletWithdrawFailed: 'Wallet Withdraw Failed',
  TransactionClicked: 'Transaction Clicked',
  WalletBalanceRefreshed: 'Wallet Balance Refreshed',
  ViewAllTransactionsClicked: 'View All Transactions Clicked',

  // Asset Education
  Education_Video: 'Education Video Clicked',
  Education_Learn_More: 'Education Learn More Link Clicked',
  Education_Contact_Us: 'Education Contact Us Button Clicked',
  Education_Book_A_Call: 'Education Book a Call With RM Clicked',
  Education_Chat: 'Education Chat With RM Clicked',

  // New KYC Events
  KYC_Initiated: 'KYC Initiated',
  KYC_PAN_Initiated: 'KYC PAN Initiated',
  KYC_PAN_Entry: 'KYC PAN Entered',
  KYC_PAN_Verify: 'KYC PAN Validation',
  KYC_PAN_Success: 'KYC PAN Verification Success',
  KYC_Aadhaar_Initiated: 'KYC Aadhaar Initiated',
  KYC_Aadhaar_Entry: 'KYC Aadhaar Entered',
  KYC_Aadhaar_Request_OTP: 'KYC Aadhaar OTP Request',
  KYC_Aadhaar_OTP_Entry: 'KYC Aadhaar OTP Entered',
  KYC_Aadhaar_Success: 'KYC Aadhaar Verification Success',
  KYC_Bank_Initiated: 'KYC Bank Initiated',
  KYC_Bank_Holder_Name_Entry: 'KYC Bank Holder Name Entered',
  KYC_Bank_Account_Entry: 'KYC Bank Account Number Entered',
  KYC_Bank_Confirm_Account: 'KYC Bank Confirm Account Number Entered',
  KYC_Bank_IFSC_Entry: 'KYC Bank IFSC Entered',
  KYC_Bank_Success: 'KYC Bank Verification Success',
  KYC_TnC_Initiated: 'KYC Terms and Condition Initiated',
  KYC_TnC_Success: 'KYC Terms and Condition Confirmed',
  KYC_Done_Click: 'KYC Completed',
  KYC_Skip: 'KYC Skipped',

  // Merge Investment Flow Events
  MIF_Invest_Clicked: 'Invest Now Button Clicked',
  MIF_Amount_Modified: 'Investment Amount Modified',
  MIF_Pay_Via_Wallet: 'Pay via Wallet Initiated',
  MIF_Pay_Online: 'Pay Online Initiated',
  MIF_Pay_Via_Bank: 'Pay via Bank Button Clicked',
  MIF_Pay_Via_Bank_Back: 'Pay via Bank Back Button Clicked',
  MIF_Investment_Success: 'Investment Success',
  MIF_Investment_Failure: 'Investment Failure',
  MIF_USE_MAX_BALALNCE: 'Use Max Wallet Balance clicked',
  MIF_AMOUNT_STEP: 'Investment Amount step clicked',

  // Payment page Events
  PAYMENT_PAGE_VIEWED: 'Payment Page Viewed',
  PAYMENT_AMOUNT_ENTERED: 'Payment Amount Entered',
  PAYMENT_TOOLTIP_VIEWED: 'Payment Tooltip Viewed',
  PAYMENT_TERMS_CHECKBOX_CHECKED: 'Payment Terms Checkbox Checked',
  PAYMENT_SWIPED: 'Payment Swiped',
  PAYMENT_ADD_MONEY_ONLINE: 'Payment Add Money Online',
  PAYMENT_ADD_MONEY_VIA_BANK: 'Payment Add Money Via Bank',
  PAYMENT_BACK_BUTTON_CLICKED: 'Payment Back Button Clicked',
  PAYMENT_BANK_BACK_BUTTON_CLICKED: 'Payment Bank Back Button Clicked',
  PAYMENT_SUCCESS_SCREEN_VIEW: 'Payment Success Screen View',
  PAYMENT_FAILURE_SCREEN_VIEW: 'Payment Failure Screen View',
  PAYMENT_SUCCESS_GO_TO_DASHBOARD: 'Payment Success Go To Dashboard',
  PAYMENT_SUCCESS_GO_TO_DEALS: 'Payment Success Go To Deals',

  // Deal page events
  DEAL_CARD_CLICK: 'Deal Card Click',
  DEAL_PAGE_VIEWED: 'Deal Page Viewed',
  DEAL_TOOLTIP_VIEWED: 'Deal Tooltip Viewed',
  DEAL_INFO_QUESTION_CLICKED: 'Deal Info Question Clicked',
  DEAL_HOW_IT_WORKS_VIEWED: 'Deal How It Works Viewed',
  DEAL_DISTRIBUTION_VIEWED: 'Deal Distribution Viewed',
  DEAL_DISTRIBUTION_DISABLED: 'Deal Distribution Disabled',
  DEAL_ALL_POOL_COMPANIES_VIEW: 'Deal All Pool Companies View',
  DEAL_DOCUMENT_DOWNLOAD_CLICK: 'Deal Document Download Click',
  DEAL_FAQ_VIEWED: 'Deal Faq Viewed',
  DEAL_INVEST_BUTTON_CLICKED: 'Deal Invest Button Clicked',
  DEAL_AMOUNT_ENTERED: 'Deal Amount Entered',
  DEAL_SECURITY_VIEWED: 'Deal Security Viewed',
  DEAL_ADD_MONEY_ONLINE: 'Deal Add Money Online',
  DEAL_ADD_MONEY_VIA_BANK: 'Deal Add Money Via Bank',
  DEAL_TERMS_CHECKBOX_CHECKED: 'Deal Terms Checkbox Checked',
  DEAL_TERMS_LINK_CLICK: 'Deal Terms Link Click',
  DEAL_BANK_BACK_BUTTON_CLICKED: 'Deal Bank Back Button Clicked',

  // Re-investment events
  REINVESTMENT_TOGGLE_OFF: 'Reinvestment Toggle off',
  REINVESTMENT_TOGGLE_ON: 'Reinvestment Toggle on',
  REINVESTMENT_INFO_HOVER: 'Reinvestment Info Hover',
  REINVESTMENT_INFO_Click: 'Reinvestment Info Click',
  REINVESTMENT_DASHBOARD_OPTOUT: 'Reinvestment Dashboard Optout',
  REINVESTMENT_DASHBOARD_OPTIN: 'Reinvestment Dashboard Optin',

  // Signup and onboarding new events
  SIGNUP_PHONE_ENTERED: 'Signup Phone Entered',
  SIGNUP_PHONE_OTP_REQUESTED: 'Signup Phone OTP Requested',
  SIGNUP_PHONE_OTP_ENTERED: 'Signup Phone OTP Entered',
  SIGNUP_PHONE_OTP_SUBMITTED: 'Signup Phone OTP Submitted',
  SIGNUP_PHONE_OTP_VALIDATED: 'Signup Phone OTP Validated',
  SIGNUP_PHONE_OTP_RESEND: 'Signup Phone OTP Resend',
  SIGNUP_PHONE_OTP_BACK_CLICKED: 'Signup Phone OTP Back Clicked',

  SIGNUP_GOOGLE_CLICKED: 'Signup Google Clicked',

  SIGNUP_EMAIL_CLICKED: 'Signup Email Clicked',
  SIGNUP_EMAIL_ENTERED: 'Signup Email Entered',
  SIGNUP_EMAIL_BACK_CLICKED: 'Signup Email Back Clicked',
  SIGNUP_EMAIL_OTP_REQUESTED: 'Signup Email OTP Requested',
  SIGNUP_EMAIL_OTP_ENTERED: 'Signup Email OTP Entered',
  SIGNUP_EMAIL_OTP_SUBMITTED: 'Signup Email OTP Submitted',
  SIGNUP_EMAIL_OTP_VALIDATED: 'Signup Email OTP Validated',
  SIGNUP_EMAIL_OTP_RESEND: 'Signup Email OTP Resend',
  SIGNUP_EMAIL_OTP_BACK_CLICKED: 'Signup Email OTP Back Clicked',

  ONBOARDING_NAME_ENTERED: 'Onboarding Name Entered',
  ONBOARDING_NAME_SUBMITTED: 'Onboarding Name Submitted',

  ONBOARDING_PHONE_ENTERED: 'Onboarding Phone Entered',
  ONBOARDING_PHONE_OTP_REQUESTED: 'Onboarding Phone OTP Entered',
  ONBOARDING_PHONE_OTP_ENTERED: 'Onboarding Phone OTP Entered',
  ONBOARDING_PHONE_OTP_SUBMITTED: 'Onboarding Phone OTP Submitted',
  ONBOARDING_PHONE_OTP_VALIDATED: 'Onboarding Phone OTP Validated',
  ONBOARDING_PHONE_OTP_RESEND: 'Onboarding Phone OTP Resend',

  ONBOARDING_EMAIL_ENTERED: 'Onboarding Email Entered',
  ONBOARDING_EMAIL_OTP_REQUESTED: 'Onboarding Email OTP Entered',
  ONBOARDING_EMAIL_OTP_ENTERED: 'Onboarding Email OTP Entered',
  ONBOARDING_EMAIL_OTP_SUBMITTED: 'Onboarding Email OTP Submitted',
  ONBOARDING_EMAIL_OTP_VALIDATED: 'Onboarding Email OTP Validated',
  ONBOARDING_EMAIL_OTP_RESEND: 'Onboarding Email OTP Resend',

  ONBOARDING_GOOGLE_CLICKED: 'Onboarding Google Clicked',

  ONBOARDING_REFERRAL_CODE_ENTERED: 'Onboarding Referral Code Entered',
  ONBOARDING_REFERRAL_CODE_SUBMITTED: 'Onboarding Referral Code Submitted',
  ONBOARDING_REFERRAL_CODE_VALIDATED: 'Onboarding Referral Code Validated',
  ONBOARDING_REFERRAL_CODE_NOT_AVAILABLE: 'Onboarding Referral Code Not Available',

  ONBOARDING_REFERRAL_CODE_AUTO_VERIFY_INITIATED: 'Onboarding Referral Code Auto Verify Initiated',
  ONBOARDING_REFERRAL_CODE_AUTO_VERIFY_SUCCESSFUL:
    'Onboarding Referral Code Auto Verify Successful',
  ONBOARDING_REFERRAL_CODE_AUTO_VERIFY_FAILED: 'Onboarding Referral Code Auto Verify Failed',

  // Bonds events
  FILTER_CHIP_CLICKED: 'Filter Tab clicked',
  BONDS_UNITS_INCREASE: 'Enter Units plus button clicked',
  BONDS_UNITS_DECREASE: 'Enter Units minus button clicked',
  BONDS_UNITS_MANUAL: 'Enter Units manually',
  VERIFY_CMR_CLICKED: 'Verify CMR clicked',
  UPLOAD_CMR_INITIATED: 'Upload CMR initiated',
  UPLOAD_CMR_SUCCESSFUL: 'Upload CMR successful',
  UPLOAD_CMR_DELETED: 'Uploaded CMR deleted',
  UPLOAD_CMR_SUBMIT_CLICKED: 'Upload CMR submit clicked',
  UPLOAD_CMR_SUBMIT_SUCCESS: 'Upload CMR submit successful',
  CMR_GUIDE_VIEWED: 'CMR guide viewed',
  RDA_AGREEMENT_CHECKED: 'RDA contract click wrap clicked',
  RDA_CONTRACT_LINK: 'RDA contract link clicked',
  DOWNLOAD_PAYOUTS: 'Download payout schedules clicked',
  PAYOUT_PAGE_CHANGE: 'payout schedule page changed',
  BONDS_REQUEST_CALL_BACK: 'Request Call back for Bonds',

  // cashback events
  CASHBACK_BANNER_FIRST_INVESTMENT: '500 Cashback banner for first investment',
  COMING_SOON_BANNER_CLICK: 'Coming Soon Banner Clicked',
  YOUTUBE_CARD_CLICK: 'Youtube card click',
};
