import {
  getAllSchedulesStats,
  getAllSchedulesStatsInvoiceDiscounting,
} from '../../utils/paymentUtils';
import { convertRate, xirr } from 'node-irr';
import isEmpty from 'lodash/isEmpty';
import {
  arePayoutsTDSApplicable,
  isInvestmentEligibleForFlatTDS,
  isNewSelectedQtyWillBreachTDSLimit,
} from '../../utils/investmentUtils';
import { useCallback, useEffect, useMemo } from 'react';
import { useCart } from '../CartContext';
import { useQuery } from '../../managers/NetworkManager';
import { getADeal } from '../../_deals/actions';
import { checkIfDealFinanceType, DEAL_FINANCE_TYPE } from '../../_deals/constants';

export default function useCartSummary({ onChange = () => {} }) {
  const { totalInvestments, cartMeta, cart, getCartList, totalRental, remove, add, removeAll } =
    useCart();

  const dealId = cartMeta?.dealId;
  const {
    data: deal,
    isError: isErrorFetchingDeal,
    isLoading: isLoadingDeal,
  } = useQuery(['deals', dealId], () => getADeal(dealId), {
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const isDealEmpty = isEmpty(deal);
  const sanitizedDeal = (!isEmpty(deal) && !isEmpty(deal?.data) && deal.data) || {};

  const flatTDSFromDeal = sanitizedDeal?.flatTDS || false;
  const financeType = sanitizedDeal?.financeType || '';
  let isCartEmpty = true;
  const selectedQty = (sanitizedDeal?.assetRequests || []).map((assetRequest) => {
    const assetRequestId = assetRequest.id;
    if (cart[assetRequestId]) {
      isCartEmpty = false;
      return {
        qty: cart[assetRequestId].qty,
        assetRequestId,
      };
    } else {
      return {
        qty: 0,
        assetRequestId,
      };
    }
  });
  const selectedQtyHash = selectedQty.reduce((hash, item, index) => {
    return hash + (item.qty || '0') + '|' + item.assetRequestId + '|';
  }, '');

  const selectedAllQty = (sanitizedDeal?.assetRequests || []).map((assetRequest) => {
    const assetRequestId = assetRequest.id;
    return {
      qty: assetRequest.qty,
      assetRequestId,
    };
  });

  const {
    amounts,
    leaseEndDate,
    transactionFee,
    amountToPay,
    totalReturns,
    relativeTenure,
    isTDSValueZero,
  } = useMemo(() => {
    if (isLoadingDeal) {
      return {};
    }

    const monthlyRentalClosedAndLiveCurrentFyFromLessee =
      sanitizedDeal.myInvestmentDetailsOnLessee?.monthlyRentalClosedAndLiveCurrentFyFromLessee || 0;
    const applyTDSOnAmount =
      flatTDSFromDeal ||
      isNewSelectedQtyWillBreachTDSLimit(
        monthlyRentalClosedAndLiveCurrentFyFromLessee,
        totalRental,
      );

    if (checkIfDealFinanceType(sanitizedDeal, DEAL_FINANCE_TYPE.INVOICE_DISCOUNTING)) {
      return getAllSchedulesStatsInvoiceDiscounting(
        sanitizedDeal.assetRequests || [],
        isCartEmpty ? selectedAllQty : selectedQty,
        sanitizedDeal.firstPayoutDate,
        sanitizedDeal.leaseEndDate,
        applyTDSOnAmount,
        true,
      );
    }
    return getAllSchedulesStats(
      sanitizedDeal.assetRequests || [],
      selectedQty,
      sanitizedDeal.firstPayoutDate,
      sanitizedDeal.leaseEndDate,
      applyTDSOnAmount,
      false,
    );
  }, [
    dealId,
    selectedQtyHash,
    flatTDSFromDeal,
    isLoadingDeal,
    financeType,
    isCartEmpty,
    totalRental,
  ]);

  const finalXirr = useMemo(() => {
    if (isLoadingDeal) {
      return 0;
    }
    if (checkIfDealFinanceType(sanitizedDeal, DEAL_FINANCE_TYPE.INVOICE_DISCOUNTING)) {
      return `${amounts.length ? (convertRate(xirr(amounts).rate, 365) * 100).toFixed(2) : 0}%`;
    }
    return `${amounts.length ? Math.floor(convertRate(xirr(amounts).rate, 365) * 100) : 0}%`;
  }, [dealId, selectedQtyHash, isLoadingDeal, financeType]);

  const grossXirr = useMemo(() => {
    if (isLoadingDeal) {
      return 0;
    }
    let modifiedAmounts = amounts;
    if (
      checkIfDealFinanceType(sanitizedDeal, DEAL_FINANCE_TYPE.INVOICE_DISCOUNTING) &&
      amounts.length
    ) {
      modifiedAmounts = amounts.map((cashflow) => {
        return { ...cashflow, amount: cashflow.amountWithoutGst };
      });
    }

    return `${
      amounts.length ? (convertRate(xirr(modifiedAmounts).rate, 365) * 100).toFixed(2) : 0
    }%`;
  }, [dealId, selectedQtyHash, isLoadingDeal, financeType]);

  const representationalIrr = useMemo(() => {
    return `${
      financeType === 'INVOICE_DISCOUNTING'
        ? grossXirr
        : sanitizedDeal?.assetRequests?.[0]?.representationalIrr
    }%`;
  }, [dealId, selectedQtyHash, isLoadingDeal, financeType]);

  const simpleGain = (totalInvestments / totalReturns) * 100;

  const isTdsApplicable = (() => {
    if (isLoadingDeal || isDealEmpty) return false;
    if (checkIfDealFinanceType(sanitizedDeal, DEAL_FINANCE_TYPE.INVOICE_DISCOUNTING)) {
      return flatTDSFromDeal;
    }
    return flatTDSFromDeal || arePayoutsTDSApplicable(amounts?.slice(1) || []) || false;
  })();

  const modifyToCart = useCallback((qty, assetRequest) => {
    if (qty === 0) {
      remove(assetRequest?.id);
    } else {
      add(
        {
          qty,
          assetRequest,
        },
        assetRequest?.id,
      );
    }
  }, []);

  const flatTDS = useMemo(() => {
    if (isLoadingDeal || isDealEmpty) return false;

    return flatTDSFromDeal || isInvestmentEligibleForFlatTDS(deal, totalRental);
  }, [dealId, totalRental, isLoadingDeal, flatTDSFromDeal, isDealEmpty, financeType]);

  const payoutAmount = (amounts && amounts.length >= 2 && amounts[3]?.amount) || 0;

  const output = {
    finalXirr,
    representationalIrr,
    amounts: isCartEmpty ? [] : amounts,
    relativeTenure,
    leaseEndDate,
    isCartEmpty,
    amountToPay: isCartEmpty ? 0 : amountToPay,
    totalReturns: isCartEmpty ? 0 : totalReturns,
    grossReturns: isCartEmpty ? 0 : totalReturns + transactionFee,
    grossXirr,
    transactionFee,
    isTdsApplicable,
    totalInvestments,
    cartMeta,
    getCartList,
    totalRental,
    deal: sanitizedDeal,
    flatTDS,
    modifyToCart,
    simpleGain,
    selectedQty: selectedQty,
    payoutAmount,
    removeAll,
    isLoadingDeal,
    isTDSValueZero,
  };
  let isSelectedQtyEmpty = isEmpty(selectedQty);
  let emptyOutput = {
    amounts: [],
    leaseEndDate: new Date(),
    relativeTenure: 0,
    isCartEmpty,
    totalReturns: 0,
    grossReturns: 0,
    transactionFee: 0,
    amountToPay: 0,
    grossXirr: 0,
    finalXirr: 0,
    simpleGain: 0,
    isTdsApplicable: false,
    cartMeta,
    totalInvestments,
    getCartList,
    totalRental,
    deal: sanitizedDeal,
    flatTDS: false,
    modifyToCart,
    selectedQty: [],
    payoutAmount: 0,
    removeAll,
    isLoadingDeal,
    isTDSValueZero: true,
  };
  useEffect(() => {
    onChange(isSelectedQtyEmpty ? emptyOutput : output);
  }, [dealId, selectedQtyHash, isSelectedQtyEmpty]);

  if (isEmpty(selectedQty)) {
    return emptyOutput;
  }

  return output;
}
