import React, { forwardRef } from 'react';
import {
  Button as MaterialisedButton,
  styled,
  ChevronRightIcon,
  Box,
  CircularProgress,
} from './../../mui';
import { isTouchDevice } from '../../utils';
import { trackEvent } from '../../../../_deals/actions';
import { appColors, baseColors } from 'ui-commons/ui-kit/configs/theme';

export const VARIANTS = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TEXT: 'TEXT',
  TAP_PRIVATE: 'TAP_PRIVATE',
};

const canTouch = isTouchDevice();

const PrimarySizeStyle = {
  large: {
    lg: {
      fontSize: '20px',
      padding: '20px 40px',
      borderRadius: '8px',
    },
    md: {
      fontSize: '16px',
      padding: '20px 40px',
      borderRadius: '8px',
    },
    sm: {
      fontSize: '16px',
      padding: '18px 40px',
      borderRadius: '8px',
    },
  },
  medium: {
    lg: {
      fontSize: '16px',
      padding: '16px 30px',
      borderRadius: '12px',
    },
    md: {
      fontSize: '14px',
      padding: '16px 30px',
      borderRadius: '12px',
    },
    sm: {
      fontSize: '14px',
      padding: '16px 30px',
      borderRadius: '12px',
    },
  },
  small: {
    lg: {
      fontSize: '14px',
      padding: '12px 20px',
      borderRadius: '6px',
    },
    md: {
      fontSize: '14px',
      padding: '12px 20px',
      borderRadius: '6px',
    },
    sm: {
      fontSize: '14px',
      padding: '14px 16px',
      borderRadius: '6px',
    },
  },
};

const SecondarySizeStyle = {
  medium: {
    lg: {
      fontSize: '16px',
      padding: '14px 30px',
    },
    md: {
      fontSize: '14px',
      padding: '14px 30px',
    },
    sm: {
      fontSize: '14px',
      padding: '14px 30px',
    },
  },
  small: {
    lg: {
      fontSize: '14px',
      padding: '14px 20px',
    },
    md: {
      fontSize: '14px',
      padding: '14px 20px',
    },
    sm: {
      fontSize: '14px',
      padding: '14px 16px',
    },
  },
};

const TextSizeStyle = {
  medium: {
    lg: {
      fontSize: '16px',
      padding: '16px 20px',
    },
    md: {
      fontSize: '16px',
      padding: '16px 20px',
    },
    sm: {
      fontSize: '16px',
      padding: '16px 20px',
    },
  },
  small: {
    lg: {
      fontSize: '14px',
      padding: '14px 20px',
    },
    md: {
      fontSize: '14px',
      padding: '14px 20px',
    },
    sm: {
      fontSize: '14px',
      padding: '12px 20px',
    },
  },
};

const TapPrivateSizeStyle = {
  large: {
    lg: {
      fontSize: '20px',
      padding: '20px 40px',
      borderRadius: '12px',
    },
    md: {
      fontSize: '16px',
      padding: '20px 40px',
      borderRadius: '12px',
    },
    sm: {
      fontSize: '16px',
      padding: '18px 40px',
      borderRadius: '12px',
    },
  },
  medium: {
    lg: {
      fontSize: '16px',
      padding: '16px 30px',
      borderRadius: '12px',
    },
    md: {
      fontSize: '14px',
      padding: '16px 30px',
      borderRadius: '12px',
    },
    sm: {
      fontSize: '14px',
      padding: '16px 30px',
      borderRadius: '12px',
    },
  },
  small: {
    lg: {
      fontSize: '12px',
      padding: '16px 25px',
      borderRadius: '12px',
    },
    md: {
      fontSize: '10px',
      padding: '12px 20px',
      borderRadius: '12px',
    },
    sm: {
      fontSize: '10px',
      padding: '12px 20px',
      borderRadius: '12px',
    },
  },
};

const PrimaryButtonStyled = styled(MaterialisedButton)(
  ({ theme, size = 'large', disabled = false, sx = {} }) => ({
    borderRadius: '12px',
    boxShadow: disabled ? undefined : theme.palette.button.primaryShadow,
    // background: disabled
    //   ? theme.palette.colors.state.disabled
    //   : theme.palette.button.gradient.primary,
    backgroundColor: disabled ? baseColors.slate[300] : theme.palette.primary.main,
    color: disabled ? theme.palette.colors.gray.gray8 : theme.palette.colors.white,
    border: disabled
      ? `1px solid ${theme.palette.colors.state.disabled}`
      : `1px solid ${theme.palette.button.outlined}`,
    fontStyle: 'normal',
    fontWeight: '600',
    textTransform: 'none',
    lineHeight: '100%',
    transition: 'all 0.3s ease-in-out',
    ...PrimarySizeStyle[size].lg,
    '&:hover': {
      // background: theme.palette.button.solids.primary,
      backgroundColor: canTouch ? theme.palette.button.outlined : baseColors.green[600],
      boxShadow: theme.palette.button.primaryHoverShadow,
      // border: `2px solid ${theme.palette.colors.hover}`,
      // transform: !canTouch && 'translateY(-2px)',
    },
    '&:active': {
      background: theme.palette.button.gradient.primaryPressed,
      // transform: 'translateY(0)',
      boxShadow: theme.palette.button.pressed,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      ...PrimarySizeStyle[size].md,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      ...PrimarySizeStyle[size].sm,
    },
    ...sx,
  }),
);

const TapPrivateButtonStyled = styled(MaterialisedButton)(
  ({ theme, size = 'large', disabled = false, sx = {} }) => ({
    borderRadius: '12px',
    boxShadow: disabled ? undefined : theme.palette.button.primaryShadow,
    // background: disabled
    //   ? theme.palette.colors.state.disabled
    //   : theme.palette.button.gradient.primary,
    backgroundColor: disabled ? baseColors.slate[300] : appColors.black8,
    color: disabled ? theme.palette.colors.gray.gray8 : appColors.gold,
    border: disabled
      ? `1px solid ${theme.palette.colors.state.disabled}`
      : `1px solid ${appColors.black8}`,

    fontStyle: 'normal',
    fontWeight: '600',
    textTransform: 'none',
    lineHeight: '100%',
    transition: 'all 0.3s ease-in-out',
    ...TapPrivateSizeStyle[size].lg,
    '&:hover': {
      // background: theme.palette.button.solids.primary,
      backgroundColor: canTouch ? theme.palette.button.outlined : '#1a1917',
      boxShadow: theme.palette.button.primaryHoverShadow,
      // border: `2px solid ${theme.palette.colors.hover}`,
      // transform: !canTouch && 'translateY(-2px)',
    },
    '&:active': {
      backgroundColor: '#2c2a28',
      // transform: 'translateY(0)',
      boxShadow: theme.palette.button.pressed,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      ...TapPrivateSizeStyle[size].md,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      ...TapPrivateSizeStyle[size].sm,
    },
    ...sx,
  }),
);

const SecondaryButtonStyled = styled(MaterialisedButton)(
  ({ theme, disabled = false, size = 'medium' }) => ({
    borderRadius: '12px',
    // boxShadow: disabled ? null : theme.palette.button.secondaryShadow,
    // background: theme.palette.button.gradient.secondary,
    color: theme.palette.colors.secondaryGreenText,
    border: `0.6px solid ${disabled ? theme.palette.border.light : theme.palette.border.secondary}`,
    fontStyle: 'normal',
    fontWeight: '600',
    textTransform: 'none',
    lineHeight: '100%',
    transition: `all 0.3s ease-in-out`,
    ...SecondarySizeStyle[size].lg,
    ...(!canTouch
      ? {
          '&:hover': {
            border: `1px solid rgba(0, 0, 0, 0.08)`,
            background: theme.palette.button.solids.secondaryHover,
          },
        }
      : {}),
    '&:active': {
      border: `1px solid rgba(0, 0, 0, 0.08)`,
      background: theme.palette.button.solids.secondaryHover,
      boxShadow: theme.palette.button.secondaryPressed,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      ...SecondarySizeStyle[size].md,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      ...SecondarySizeStyle[size].sm,
    },
  }),
);

const TextButtonStyled = styled(MaterialisedButton)(({ theme, size = 'medium' }) => ({
  boxShadow: 0,
  color: theme.palette.colors.black4,
  border: `2px solid ${theme.palette.colors.white}`,
  borderRadius: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  letterSpacing: '0.2px',
  textTransform: 'none',
  lineHeight: '100%',
  ...TextSizeStyle[size].lg,
  ...(!canTouch
    ? {
        // '&:hover': {
        //   backgroundColor: theme.palette.secondary.main,
        // },
      }
    : {}),
  [theme.breakpoints.between('sm', 'md')]: {
    ...TextSizeStyle[size].md,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    ...TextSizeStyle[size].sm,
  },
}));

const StyledIcon = styled(ChevronRightIcon)(({ theme, iconSize = '16px' }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    width: iconSize,
    height: '16px',
  },
}));

const loaderWidth = {
  large: '20px',
  medium: '16px',
  small: '14px',
};

const Button = forwardRef(
  (
    {
      analyticsEvent = '',
      analyticsEventProperties = {},
      variant = '',
      size = null,
      children,
      showRightIcon = false,
      onClick = null,
      loading = false,
      ...rest
    },
    ref,
  ) => {
    const body = () => {
      return (
        <>
          <Box
            display={'flex'}
            justifyContent={'center'}
            fontSize={'inherit'}
            alignItems={'center'}
          >
            {children}
            {loading && (
              <CircularProgress
                disableShrink
                sx={{ color: rest?.disabled ? baseColors.green[700] : 'white', marginLeft: 1 }}
                thickness={5}
                style={{ width: loaderWidth[size], height: loaderWidth[size] }}
              />
            )}
          </Box>
          {showRightIcon && (
            <Box
              marginLeft={1}
              display={'flex'}
              height={'16px'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <StyledIcon />
            </Box>
          )}
        </>
      );
    };

    let mSize = size;
    let GenericButton = PrimaryButtonStyled;
    switch (variant) {
      case VARIANTS.PRIMARY:
        mSize = !!size ? size : 'large';
        GenericButton = PrimaryButtonStyled;
        break;
      case VARIANTS.SECONDARY:
        mSize = !!size ? size : 'medium';
        GenericButton = SecondaryButtonStyled;
        break;
      case VARIANTS.TEXT:
        mSize = !!size ? size : 'medium';
        GenericButton = TextButtonStyled;
        break;
      case VARIANTS.TAP_PRIVATE:
        mSize = !!size ? size : 'large';
        GenericButton = TapPrivateButtonStyled;
        break;
      default:
        mSize = !!size ? size : 'large';
        GenericButton = PrimaryButtonStyled;
    }

    return (
      <GenericButton
        disableRipple
        onTouchStart={() => {}}
        ref={ref}
        size={mSize}
        onClick={(e) => {
          if (typeof onClick === 'function') {
            try {
              if (
                analyticsEvent !== null &&
                analyticsEvent !== '' &&
                analyticsEvent !== undefined
              ) {
                trackEvent(analyticsEvent, analyticsEventProperties);
              }
            } catch (err) {}
            onClick(e);
          }
        }}
        {...rest}
      >
        {body()}
      </GenericButton>
    );
  },
);
Button.VARIANTS = VARIANTS;
Button.STYLED_COMPONENT = {
  TEXT: TextButtonStyled,
  PRIMARY: PrimaryButtonStyled,
  SECONDARY: SecondaryButtonStyled,
};
Button.SIZE = {
  TEXT: TextSizeStyle,
  PRIMARY: PrimarySizeStyle,
  SECONDARY: SecondarySizeStyle,
};

export const ResponsiveButton = forwardRef(({ variant = '', children, sx = {}, ...rest }, ref) => {
  return (
    <Button
      variant={variant}
      {...rest}
      ref={ref}
      sx={{
        padding: {
          xs: '0.5rem 1rem',
          sm: '0.5rem 1rem',
          md: '0.5rem 1rem',
          lg: '0.75rem 1.5rem',
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
});

export default Button;
