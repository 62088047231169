export const DEAL_FINANCE_TYPE = {
  INVOICE_DISCOUNTING: 'INVOICE_DISCOUNTING',
  ASSET_LEASING: 'ASSET_LEASING',
};

export function checkIfDealFinanceType(deal, checkFor = null, meta = {}) {
  const financeType = deal?.financeType;
  if (Array.isArray(checkFor)) {
    return !!checkFor.includes(financeType);
  }
  return checkFor === financeType;
}
