import { NetworkManager } from 'managers';
import { getCookie, objectToUrlQueryParams } from 'utils';
import isEmpty from 'lodash/isEmpty';
import Moe from '@moengage/web-sdk';
import { MoECapacitorCore } from 'capacitor-moengage-core';
import { decision } from 'global';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { DEAL_CATEGORIES_CONFIG } from 'utils/dealUtils';

/**
 * To get all deals of an user
 * @returns
 */
export function getDeals() {
  return NetworkManager.Call({ method: 'get', path: NetworkManager.PATH.getAllDeals });
}

export function getLeaderBoardRanks() {
  return NetworkManager.Call({ method: 'get', path: NetworkManager.PATH.referralLeaderBoard });
}
export function getBondsDeals() {
  return NetworkManager.Call({ method: 'get', path: NetworkManager.PATH.bonds });
}
export const getReserveConfig = () => {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.reserveConfig,
  });
};

/**
 * To get all deals of a user
 * @returns
 */
export async function getADeal(dealId = '') {
  const deal = await NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getADeal.replace('{{dealId}}', dealId),
  });
  return deal;
}

export function createInvestmentContract(investmentId = '') {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.createDocumentContract.replace('{{investmentId}}', investmentId),
  });
}

export function getInvoiceUrl(investmentId = '') {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.downloadInvoice.replace('{{investmentId}}', investmentId),
  });
}

export function getRentalUrl(investmentId = '') {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.rentalInvoice.replace('{{investmentId}}', investmentId),
  });
}

export function getContract(investmentId = '') {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.investmentContract.replace('{{investmentId}}', investmentId),
  });
}

export function getMFUrl(investmentId = '') {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.mfInvoice.replace('{{investmentId}}', investmentId),
  });
}

export function getInvoiceUrlByType(investmentId = '', type) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.downloadInvoice
      .replace('{{investmentId}}', investmentId)
      .replace('{{type}}', type),
  });
}

export function getLedger({ type, state, transactionDate, pageNo, pageSize }) {
  let from = transactionDate?.start;
  let to = transactionDate?.end;
  let path =
    NetworkManager.PATH.getTransactions +
    `?type=${type}&state=${state}${
      from && to ? `&from=${from}&to=${to}` : ''
    }&pageNo=${pageNo}&pageSize=${pageSize}&sortByUpdatedAtDesc=true`;

  return NetworkManager.Call({
    method: 'get',
    path,
  });
}

export function getOtp({ mobileNumber }) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getOtp + '/' + mobileNumber,
  });
}

export function withdrawFunds({ phoneNumber, amount, otp }) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.vaultWithdrawRequest,
    data: {
      phoneNumber,
      amount,
      otp,
    },
  });
}

export function getAccountDetails() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getAccountDetail,
  });
}

// KYC

export function verifyAadhar() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        number: 12345678912,
        name: 'Rahul',
      });
    }, 2);
  });
  // NetworkManager.Call({
  //   method: 'post',
  //   path: NetworkManager.PATH.verifyPAN,
  //   data: {
  //     id,
  //   },
  // });
}

export function getProfileDetails({ disableRedirect = false }) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.profile,
    disableRedirect,
  });
}
export function fetchKycStatus() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.kycStatus,
  });
}

export function fetchCmrVerifyStatus() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.cmrVerifyStatus,
  });
}

export function uploadCmrFile(formData) {
  return NetworkManager.Call({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data, ',
    },
    path: NetworkManager.PATH.uploadCmrFile,
    data: formData,
  });
}
export function verifyPan(panNumber) {
  const dataPayload = {
    panNumber: panNumber,
  };

  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.verifyPAN,
    data: dataPayload,
  });
}

export function SavePan(data) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.savePan,
    data,
  });
}
export function getAadhaarOtp(number) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.verifyAadhaar,
    data: {
      aadhaarNumber: number,
    },
  });
}
export function saveAadhaar(request) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.saveAadhaar,
    data: {
      otp: request?.otp,
      aadhaarNumber: request?.aadhaar,
    },
    headers: { 'X-Transaction-Id': request?.transactionId },
  });
}
export function getBankDetails() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getBankDetails,
  });
}

export function saveBankDetails(data) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.saveBankDetails,
    data,
  });
}

export function fetchPincodeDetails(pincode) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.fetchPincodeDetails + '/' + pincode,
  });
}

export function completeKYC(data) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.completeKYC,
    data,
  });
}
export function checkIfAccountNumberExists(accountNumber) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.checkIfAccountNumberExists.replace(
      '{{accountNumber}}',
      accountNumber,
    ),
  });
}

export function completeKYCBankOnly(data) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.completeKYCBankOnly,
    data,
  });
}

/**
 * To create an investment for a particular deal id
 * @param {String} dealId
 * @param {Object} data {"qty": 100, "paymentMode":"UPI"}
 * @returns
 */
export function createInvestment(dealId = null, data = {}, setErrorMessage) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.createInvestment.replace('{{dealId}}', dealId),
    data,
  }).catch((error) => {
    if (setErrorMessage) {
      setErrorMessage(error?.response?.data?.message);
    }
    throw error;
  });
}

export function repayment(investmentId) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.repaymentOfInvestment.replace('{{investmentId}}', investmentId),
  });
}

/**
 * Subscribe to an upcoming deal
 * @param dealId
 * @returns {Promise<*|undefined>}
 */
export function subscribeToAUpcomingDeal(dealId) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.subscribeUpcomingDeal,
    data: {
      dealId,
    },
  });
}

export function reOrderDeals(deals) {
  const liveDeals = [];
  const comingSoonDeals = [];
  const privateDeals = [];
  const soldOut = [];
  const nonSoldOutDeals = deals.filter((deal) => {
    if ((deal?.totalAssets !== 0 && deal?.totalAssetSold) === deal?.totalAssets) {
      soldOut.push(deal);
      return false;
    }
    return true;
  });
  const expiredDeals = nonSoldOutDeals.filter((deal) => {
    if (deal?.status === 'COMING_SOON') {
      comingSoonDeals.unshift(deal);
      return false;
    }

    if (deal?.status === 'LIVE') {
      liveDeals.unshift(deal);
      return false;
    }

    if (deal?.status === 'PRIVATE') {
      privateDeals.unshift(deal);
      return false;
    }

    return deal?.status === 'EXPIRED';
  });

  const orderedDeals = [
    ...liveDeals,
    ...comingSoonDeals,
    ...privateDeals,
    ...expiredDeals,
    ...soldOut,
  ];

  return orderedDeals;
}

export const trackPosthogEvent = (event, properties) => {
  try {
    posthog.capture(event, properties);
  } catch (err) {
    console.error('Something went wrong while tracking PosthogEvent', err);
  }
};

export const trackMoeEvent = (event, properties) => {
  if (decision.isNative) {
    try {
      MoECapacitorCore.trackEvent({
        eventName: event,
        eventAttributes: properties,
        appId: 'D7EK0YS1K88W484YQDQO0AC9',
      });
    } catch (err) {
      console.error({ err });
    }
  } else {
    try {
      Moe.track_event(event, properties);
    } catch (err) {
      console.error({ err });
    }
  }
};

export const trackMixpanelEvent = (event, properties) => {
  mixpanel.track(event, properties);
};

export function trackEvent(event, properties) {
  if (!properties) properties = {};
  const getSessionCookie = getCookie('_session');
  const getDeviceIdCookie = getCookie('_dshan');
  const userAgent = window.navigator.userAgent;

  if (getSessionCookie) properties._session = getSessionCookie;
  if (getDeviceIdCookie) properties._dshan = getDeviceIdCookie;
  if (userAgent) properties._uA = userAgent;
  if (typeof window !== 'undefined') properties.eventSourcePage = window.location.href;

  trackMoeEvent(event, properties);
  if (process.env.REACT_APP_POSTHOG_KEY) {
    trackPosthogEvent(`PH: ${event} `, properties);
  }
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    trackMixpanelEvent(`MX: ${event}`, properties);
  }
  let newEvent = `Investor App: ${event}`;
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.analytics,
    data: { event: newEvent, properties },
  });
}

export function trackWebVitals(context, event, properties) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.analyticsWebVital,
    data: { context, event, properties },
  });
}

export function getAllAssetRequest(dealId, filter, sort) {
  let urlQueryParam = {};
  if (filter) {
    urlQueryParam = { ...urlQueryParam, filter: encodeURIComponent(filter) };
  }
  if (sort) {
  }
  const query = isEmpty(urlQueryParam) ? '' : objectToUrlQueryParams(urlQueryParam);
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getAllAssets.replace('{{id}}', dealId) + query,
  });
}

export function getAnAssetRequest(assetId) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getAnAssetRequest.replace('{{id}}', assetId),
  });
}

export function checkAndBlockTheAssetTemporarily(dealId, qty) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.checkAndBlockInvestment.replace('{{id}}', dealId),
    data: { qty },
  });
}

export function downloadTaxReport() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.downloadTaxReport.replace('{{}}', 'ASSET_LEASING'),
    responseType: 'blob',
    getOriginalResponse: true,
  });
}
export function downloadIDTaxReport() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.downloadTaxReport.replace('{{}}', 'INVOICE_DISCOUNTING'),
    getOriginalResponse: true,
    responseType: 'blob',
  });
}

export function updateDocumentByInvestmentId(investmentId, data = {}) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.updateDocumentByInvestmentId.replace(
      '{{investmentId}}',
      investmentId,
    ),
    data,
  });
}

export function updateDocumentByInvestmentIdV2(investmentId, data = {}) {
  return NetworkManager.Call({
    method: 'put',
    path: NetworkManager.PATH.createInvestorContract.replace('{{investmentId}}', investmentId),
    data,
  });
}

export function postCheckByInvestmentAndUpdatePGOrder(orderId) {
  return NetworkManager.Call({
    method: 'put',
    path: NetworkManager.PATH.postCheckByInvestmentAndUpdatePGOrder.replace('{{orderId}}', orderId),
  });
}

export function postCheckByInvestmentAndUpdateLendboxPGOrder(orderId) {
  return NetworkManager.Call({
    method: 'put',
    path: NetworkManager.PATH.postCheckByInvestmentAndUpdateLendboxPGOrder.replace(
      '{{orderId}}',
      orderId,
    ),
  });
}

export function investorContractCreation(investmentId) {
  // mock api
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        result: {
          signProcessor: 'leegality',
          signLink: 'https://leegality.com',
        },
        meta: {},
      });
    }, 300);
  });

  // return NetworkManager.Call({
  //   method: 'post',
  //   path: NetworkManager.PATH.investorContractCreation,
  //   data: { investmentId },
  // });
}

export const getLegalTerms = (dealId) => {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getLegalTerms + `?dealId=${dealId}`,
  });
};

export function getMyInvestments() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.myInvestments,
  });
}
export function getPoolInvestments() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.poolInvestments,
  });
}

function mockApi(payload) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(payload);
    }, 1000);
  });
}

// V2 actions
export function getDealDetails(dealId, financeType) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getDealDetails
      .replace('{{dealId}}', dealId)
      .replace('{{financeType}}', financeType),
  });
}

export function getDealDetailsV2(dealId, financeType, userId) {
  return NetworkManager.Call({
    method: 'get',
    path:
      financeType === DEAL_CATEGORIES_CONFIG.NON_CONVERTIBLE_DEBENTURES.key ||
      financeType === DEAL_CATEGORIES_CONFIG.SECONDARY_NON_CONVERTIBLE_DEBENTURES.key
        ? NetworkManager.PATH.getBondDealDetails.replace('{{dealId}}', dealId)
        : NetworkManager.PATH.getDealDetailsV2
            .replace('{{dealId}}', dealId)
            .replace('{{financeType}}', financeType),
  });
}

export function getInvestmentTerms({ dealId = '', financeType = '', amount = 0, quantity = 0 }) {
  return NetworkManager.Call({
    method: 'get',
    path:
      financeType === DEAL_CATEGORIES_CONFIG.NON_CONVERTIBLE_DEBENTURES.key ||
      financeType === DEAL_CATEGORIES_CONFIG.SECONDARY_NON_CONVERTIBLE_DEBENTURES.key
        ? NetworkManager.PATH.getBondsInvestmentTerms
            .replace('{{dealId}}', dealId)
            .replace('{{quantity}}', quantity)
        : NetworkManager.PATH.getInvestmentTerms
            .replace('{{dealId}}', dealId)
            .replace('{{financeType}}', financeType)
            .replace('{{amount}}', amount),
  });
}

export function getBondsCashflow({ dealId, qty = 0, pageNo = 0 }) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getBondsCashflow
      .replace('{{dealId}}', dealId)
      .replace('{{qty}}', qty),
  });
}

export function canInvestV2({ dealId = null, financeType = '', amount = '' }) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.canInvestV2
      .replace('{{dealId}}', dealId)
      .replace('{{amount}}', amount),
  });
}

export function canInvestALV2(dealId = null, qty = []) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.canInvestALV2.replace('{{dealId}}', dealId),
    data: { qty },
  });
}

export function getBondsSettlementDate({ dealId = null }) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getBondsSettlementDate.replace('{{dealId}}', dealId),
  });
}

/**
 * To create an investment for a particular deal id v2
 * @param {Number} dealId
 * @param {String} financeType
 * @param {Number} amount
 * @param {String} paymentMode
 * @returns
 */
export function createInvestmentV2({
  dealId = null,
  financeType = '',
  amount = 0,
  paymentMode = '',
  qty = [],
  investAgain = false,
}) {
  const payload = {
    investedAmount: amount,
    mode: paymentMode,
    financeType,
    ...(financeType === 'ASSET_LEASING' && { qty }),
    stayInvested: investAgain,
  };
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.createInvestmentV2.replace('{{dealId}}', dealId),
    data: payload,
  });
}

export function investmentStatusAPI(id) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.investmentStatus.replace('{{investmentId}}', id),
    isV2: true,
  });
}

export function getWalletBalance() {
  // Use mocked response if API is down or under maintenance

  // return mockApi({
  //   withdrawableBalance: 50000,
  // });

  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getWalletBalance,
    isV2: true,
  });
}

export function getAvailablePgModes() {
  return mockApi({
    result: ['WALLET'],
  });
}

export function getDealListV2() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getAllListV2,
  });
}

export function getBondsInvestmentDetails({
  amount,
  successPath,
  activePath,
  failedPath,
  unitConsiderationAmount,
  qty,
  dealId,
  settlementDate,
}) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.getBondsInvestmentDetails,
    data: {
      amount,
      successPath,
      activePath,
      failedPath,
      unitConsiderationAmount,
      qty,
      dealId,
      settlementDate,
    },
  });
}

export function getInvestorContract(investmentId) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.createInvestorContract.replace('{{investmentId}}', investmentId),
  });
}
export function addMoneyToWallet(
  amount,
  successPath,
  activePath,
  failedPath,
  handleRedirection = false,
) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.addMoneyToWallet.replace('{{}}', handleRedirection),
    data: {
      amount,
      successPath,
      activePath,
      failedPath,
    },
  });
}

export function walletOtpRequest(number, email) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.withdrawalOtp
      .replace('{{number}}', number)
      .replace('{{email}}', email),
  });
}

export function walletWithdrawFinalise(phoneNumber, amount, otp) {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.withdrawal,
    data: {
      phoneNumber,
      amount,
      otp,
    },
  });
}
// Payment Reference Upload v2

// Update Document by Id v2

// get rm details

export function getRmDetails() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getRmDetails,
  });
}

// Get Tap Reserve Investment List
export function getLiquidInvestments() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getLiquidInvestments,
  });
}

export function getLiquidInvestmentDetails(investmentId) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getLiquidInvestmentDetails.replace('{{investmentId}}', investmentId),
  });

  // return mockApi({
  //   message: 'String',
  //   meta: {
  //     size: 'Number',
  //     pageNo: 'Number',
  //     totalPages: 'Number',
  //     totalElements: 'Number',
  //   },
  //   result: {
  //     investmentType: 'UNI_FLEXI',
  //     investedAmount: 10000,
  //     currentAmount: 11000,
  //     returns: 1000,
  //     withdrawableAmount: 6000,
  //     documents: ['https://google.co.in'],
  //   },
  // });
}

export function getLiquidInvestmentLedger(investmentId) {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.getLiquidLedger,
  });

  // return mockApi({
  //   message: 'String',
  //   meta: {
  //     size: 'Number',
  //     pageNo: 'Number',
  //     totalPages: 'Number',
  //     totalElements: 'Number',
  //   },
  //   result: [
  //     {
  //       type: 'Debit',
  //       amount: 10100,
  //       date: new Date(),
  //       closingBalance: 0,
  //     },
  //     {
  //       type: 'Interest',
  //       amount: 100,
  //       date: new Date(),
  //       closingBalance: 10100,
  //     },
  //     {
  //       type: 'Credit',
  //       amount: 10000,
  //       date: new Date(),
  //       closingBalance: 10000,
  //     },
  //   ],
  // });
}

export function downloadLedger() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.downloadLedger,
  });
}

export function getEducationData() {
  return NetworkManager.Call({
    method: 'get',
    path: NetworkManager.PATH.education,
  });
}
