import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
export * from './GlobalWrapper';
export * from './actions';
export * from './native';
const defaultDecisions = {};

export const decision = {
  ...defaultDecisions,
  isNative: Capacitor.isNativePlatform(),
  platform: Capacitor.getPlatform(),
  isThroughDeeplink: false,
  deeplinkPath: '',
  deviceInfo: Device.getInfo(),
  getAppInfo: App.getInfo,
};
