import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './normalize.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { LeafLoader } from './loaders';
import { trackWebVitals } from './_deals/actions';
import * as Sentry from '@sentry/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Moe from '@moengage/web-sdk';
import { decision } from './global';
import { MoECapacitorCore } from 'capacitor-moengage-core';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

const RouterWithAppBusiness = React.lazy(() => import('./app/business.app'));
const RouterWithAppInvestor = React.lazy(() => import('./app/investor.app'));

window.Ilog = console.log;

const getDebugLogMode = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return 0;
  } else if (process.env.REACT_APP_ENV === 'alpha') {
    return 1;
  }
  return 4;
};

// mixpanel.init(process.env.REACT_APP_MIXPANEL_ID ?? '310ca9dc8a073c25723291e36444f079', {
//   debug: process.env.REACT_APP_ENV !== 'production',
//   track_pageview: true,
//   persistence: 'localStorage',
// });

/*
This Initialises Sentry Error tracing and tracking
The initialisation is done only for production and stage environments
  @param Browser Tracing sets up the domains that are under the whitelist for tracing
  @param Replay sets up the masking of the data that is sent to Sentry
  @param TracesSampleRate sets the sampling rate for the traces, Currently set to 50% as a test and/or billing strategy
  @param ReplaysSessionSampleRate sets the sampling rate for the session replay, Currently set to 10%
  @param ReplaysOnErrorSampleRate sets the sampling rate for the session replay on error, Currently set to 100% to ensure the session replay is captured on error with 100% traceability
*/
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://30dbb9039cd4d79a8deb3e4cb3cc0bcb@o4506500337172480.ingest.sentry.io/4506500340580352',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/https:\/\/app(-stage)?\.tapinvest\.in(\/[^s]*)?/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

/*
The following code initialises the Posthog SDK
*/
if (process.env.REACT_APP_POSTHOG_KEY) {
  if (!window.location.host.includes('127.0.0.1')) {
    posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
      api_host: 'https://us.posthog.com',
      debug:
        process.env.REACT_APP_ENV === 'stage' ||
        process.env.REACT_APP_ENV === 'staging' ||
        process.env.REACT_APP_ENV === 'development',
    });
  }
}
if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  if (!window.location.host.includes('127.0.0.1')) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      debug: process.env.REACT_APP_ENV !== 'production',
      track_pageview: true,
      persistence: 'localStorage',
    });
  }
}

if (decision.isNative) {
  MoECapacitorCore.initialize({
    appId: 'D7EK0YS1K88W484YQDQO0AC9',
    cluster: 'DC_3',
  });
} else {
  Moe.initialize({
    app_id: 'D7EK0YS1K88W484YQDQO0AC9',
    cluster: 'DC_3',
    debug_logs: getDebugLogMode(),
    swPath: '/service-worker.js',
  });
}

function loadTheApp() {
  const appContext = process.env.REACT_APP_CONTEXT;
  switch (appContext) {
    case 'BUSINESS':
      return <RouterWithAppBusiness />;
    case 'INVESTOR':
      return <RouterWithAppInvestor />;
    default:
      return <div>APP CONTEXT IS NOT SET PROPERLY. CHECK ENV FOR MORE DETAILS</div>;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LeafLoader />}>{loadTheApp()}</Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics(metric) {
  delete metric?.entries;
  trackWebVitals('investor_app', metric?.name, metric);
}

reportWebVitals(sendToAnalytics);
