import { Box, Button, Typography, useTheme } from '../../index';
import { isFunction } from 'lodash';
import { Else, If, Then, When } from 'react-if';
import { useState } from 'react';

function ActionLayout1({
  headerText = '',
  Header = null,
  HeaderRightComponent = null,
  confirmButtonText = 'Confirm',
  confirmButtonRightArrow = true,
  cancelButtonText = 'Cancel',
  onConfirm = null,
  onCancel = null,
  children = null,
  removeBottomBottom = false,
  hideCTAButtonPadding,
  id=""
}) {
  const theme = useTheme();
  const hasOnCancelHandler = isFunction(onCancel);
  const hasOnConfirmHandler = isFunction(onConfirm);
  const hasAnyCTAHandler = hasOnCancelHandler || hasOnConfirmHandler || false;
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);
  return (
    <Box>
      {/* <When condition={!!headerText || !!Header}>
        <Box
          borderBottom={
            !removeBottomBottom ? '1px solid' + theme.palette.colors.border.border1 : 'none'
          }
          padding={'16px 20px'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <If condition={!!Header}>
              <Then>
                {Header}
              </Then>
              <Else>
                <Typography variant={'phTextLarge'} color={'text.label'}>
                  {headerText}
                </Typography>
              </Else>
            </If>
          </Box>
          <When condition={!!HeaderRightComponent}>
            {HeaderRightComponent}
          </When>

        </Box>
      </When> */}
      <Box mt={removeBottomBottom ? (!!headerText ? 1.5 : 1) : 0}>{children}</Box>
      <When condition={hasAnyCTAHandler}>
        <Box
          mt={1.5}
          paddingLeft={hideCTAButtonPadding ? 0 : 2.5}
          paddingRight={hideCTAButtonPadding ? 0 : 2.5}
          paddingBottom={hideCTAButtonPadding ? 0 : 2.5}
        >
          <When condition={hasOnConfirmHandler}>
            <Box>
              <Button
                disabled={disableConfirmButton}
                variant={'PRIMARY'}
                size={'medium'}
                fullWidth
                onClick={() => {
                  setDisableConfirmButton(true);
                  onConfirm();
                }}
                id={id}
                showRightIcon={confirmButtonRightArrow}
              >
                {confirmButtonText}
              </Button>
            </Box>
          </When>
          <When condition={hasOnCancelHandler}>
            <Box mt={2.5}>
              <Button
                disabled={disableConfirmButton}
                variant={'SECONDARY'}
                size={'medium'}
                fullWidth
                onClick={onCancel}
              >
                {cancelButtonText}
              </Button>
            </Box>
          </When>
        </Box>
      </When>
    </Box>
  );
}

export default ActionLayout1;
