import React, { Fragment, useEffect } from 'react';
import {
  useRadio,
  openDrawer,
  openBottomMenu,
  openDialog,
  openIframe,
  closeDialog,
  closeSnackBar,
  closeBottomMenu,
} from './actions';
import BottomMenu from './BottomMenu';
import GlobalDialog from './Dialog';
import { openPaymentUploadReference } from '../_payments/actions/index';
import { CartContextProvider } from '../_cart/CartContext';
import GlobalSanckBar from './SanckBar';
import { Box } from 'ui-commons/ui-kit';
import UseSummaryCartDebug from '../_cart/UseSummaryCartDebug';
import { API_URL } from 'managers/NetworkManager';
import { useNavigate } from 'react-router-dom';
import { Echo, PaymentGatewayCapacitorPlugin, WebViewPlugin } from 'global';

//TODO add abstraction on window
window.globalComponentData = {};

function GlobalWrapper({ children }) {
  const [globalComponentData, setGlobalComponentData] = React.useState({});
  const [onScreenDebugger, setOnScreenDebugger] = React.useState(false);
  const radio = useRadio();
  const navigate = useNavigate();

  useEffect(() => {
    radio.subscribe('global', (newData) => {
      setGlobalComponentData((currentData) => {
        window.globalComponentData = { ...currentData, ...newData };
        return window.globalComponentData;
      });
    });
  }, [radio, setGlobalComponentData]);

  const isTestScreenActive = globalComponentData && globalComponentData.enabledTest;
  // const isDrawarShown = globalComponentData && globalComponentData.enableDrawer;
  const isBottomMenuShownSlot1 =
    (globalComponentData && globalComponentData.showBottomMenu) || false;
  const isBottomMenuShownSlot2 =
    (globalComponentData && globalComponentData?.showBottomMenuSlot2) || false;
  const isBottomMenuShownSlot3 =
    (globalComponentData && globalComponentData?.showBottomMenuSlot3) || false;

  const isBottomMenuShownSlot4 =
    (globalComponentData && globalComponentData?.showBottomMenuSlot4) || false;

  const isDialogOpen = (globalComponentData && globalComponentData.showDialog) || false;
  const isSnackBarOpen = (globalComponentData && globalComponentData.showSnackBar) || false;
  return (
    <Fragment>
      <CartContextProvider>
        {children}

        {/*<Drawer*/}
        {/*  anchor={'right'}*/}
        {/*  show={isDrawarShown}*/}
        {/*  onClose={() => {*/}
        {/*    radio.broadcast('global', {*/}
        {/*      enableDrawer: false,*/}
        {/*    });*/}
        {/*  }}*/}
        {/*  component={globalComponentData?.drawerData?.component}*/}
        {/*/>*/}
        <BottomMenu
          open={isBottomMenuShownSlot1}
          onClose={() => {
            if (typeof globalComponentData?.bottomMenuData?.onClose === 'function') {
              globalComponentData?.bottomMenuData?.onClose();
            } else {
              closeBottomMenu({}, { slot: 1 });
            }
          }}
          component={globalComponentData?.bottomMenuData?.component}
          height={globalComponentData?.bottomMenuData?.height}
          closable={globalComponentData?.bottomMenuData?.closable}
          keepMounted={globalComponentData?.bottomMenuData?.keepMounted}
        />
        <BottomMenu
          open={isBottomMenuShownSlot2}
          onClose={() => {
            if (typeof globalComponentData?.bottomMenuDataSlot2?.onClose === 'function') {
              globalComponentData?.bottomMenuDataSlot2?.onClose();
            } else {
              closeBottomMenu({}, { slot: 2 });
            }
          }}
          component={globalComponentData?.bottomMenuDataSlot2?.component}
          height={globalComponentData?.bottomMenuDataSlot2?.height}
          closable={globalComponentData?.bottomMenuDataSlot2?.closable}
          keepMounted={globalComponentData?.bottomMenuDataSlot2?.keepMounted}
        />
        <BottomMenu
          open={isBottomMenuShownSlot3}
          onClose={() => {
            if (typeof globalComponentData?.bottomMenuDataSlot3?.onClose === 'function') {
              globalComponentData?.bottomMenuDataSlot3?.onClose();
            } else {
              closeBottomMenu({}, { slot: 3 });
            }
          }}
          component={globalComponentData?.bottomMenuDataSlot3?.component}
          height={globalComponentData?.bottomMenuDataSlot3?.height}
          closable={globalComponentData?.bottomMenuDataSlot3?.closable}
          keepMounted={globalComponentData?.bottomMenuDataSlot3?.keepMounted}
        />
        <BottomMenu
          open={isBottomMenuShownSlot4}
          onClose={() => {
            if (typeof globalComponentData?.bottomMenuDataSlot4?.onClose === 'function') {
              globalComponentData?.bottomMenuDataSlot4?.onClose();
            } else {
              closeBottomMenu({}, { slot: 4 });
            }
          }}
          component={globalComponentData?.bottomMenuDataSlot4?.component}
          height={globalComponentData?.bottomMenuDataSlot4?.height}
          closable={globalComponentData?.bottomMenuDataSlot4?.closable}
          keepMounted={globalComponentData?.bottomMenuDataSlot4?.keepMounted}
        />

        <GlobalDialog
          open={isDialogOpen}
          close={closeDialog}
          component={globalComponentData?.dialogData?.component}
          dialogData={globalComponentData?.dialogData}
        />
        <GlobalSanckBar
          open={isSnackBarOpen}
          close={closeSnackBar}
          action={globalComponentData?.snackBarData?.action}
          message={globalComponentData?.snackBarData?.message}
          anchorOrigin={globalComponentData?.snackBarData?.anchorOrigin}
        />

        {/*-----DEBUG SCREEN-----*/}

        {process.env.NODE_ENV === 'development' && (
          <>
            <div
              onClick={() => {
                setOnScreenDebugger(!onScreenDebugger);
              }}
              id={'debug_screen'}
              style={{
                borderRadius: '2rem',
                top: 200,
                right: 0,
                zIndex: 9999,
                position: 'fixed',
                padding: '0.8rem',
                backgroundColor: 'white',
                border: '2px solid yellow',
                opacity: 0.5,
              }}
            />
            {onScreenDebugger && (
              <div
                style={{
                  bottom: 0,
                  zIndex: 9999,
                  position: 'fixed',
                  padding: '1rem',
                  backgroundColor: 'white',
                  border: '2px solid',
                  opacity: 0.8,
                  width: '100%',
                  boxSizing: 'border-box',
                }}
              >
                <button
                  onClick={() => {
                    setOnScreenDebugger(!onScreenDebugger);
                  }}
                >
                  Close
                </button>
                <h4>useCartSummary Debug</h4>
                <div>
                  <UseSummaryCartDebug />
                </div>
                <h3>On Screen Debugger</h3>
                <button
                  onClick={() => {
                    radio.broadcast('global', {
                      enabledTest: !isTestScreenActive,
                    });
                  }}
                >{`${isTestScreenActive ? 'Hide' : 'Show'} Test`}</button>
                {/*<button*/}
                {/*  onClick={() => {*/}
                {/*    radio.broadcast('global', {*/}
                {/*      enableDrawer: !isDrawarShown,*/}
                {/*    });*/}
                {/*  }}*/}
                {/*>{`${isDrawarShown ? 'Hide' : 'Show'} Drawer`}</button>*/}
                <button
                  onClick={() => {
                    if (isBottomMenuShownSlot1) {
                      closeBottomMenu({});
                      return;
                    }
                    openBottomMenu({
                      component: <Box height={'200px'}>I am bottom menu in slot 1</Box>,
                    });
                  }}
                >{`${isBottomMenuShownSlot1 ? 'Hide' : 'Show'} Bottom Menu ( Slot 1 )`}</button>
                <button onClick={openDialog}>{`${isDialogOpen ? 'Hide' : 'Show'} Dialog`}</button>
                <button
                  onClick={() => {
                    if (isBottomMenuShownSlot2) {
                      closeBottomMenu({}, { slot: 2 });
                      return;
                    }
                    openBottomMenu(
                      {
                        component: <Box height={'200px'}>I am bottom menu in slot 2</Box>,
                      },
                      {
                        slot: 2,
                      },
                    );
                  }}
                >{`${isBottomMenuShownSlot2 ? 'Hide' : 'Show'} Bottom Menu ( Slot 2 )`}</button>
                <button
                  onClick={() => {
                    openIframe({
                      onLoad: (e) => {
                        // user e.target to access iframe dom
                      },
                      src: 'https://wikipedia.org',
                      onClose: () => {
                        alert('Do Some actions');
                      },
                    });
                  }}
                >{`Open IFrame`}</button>
                <button
                  onClick={() => {
                    openPaymentUploadReference(27, () => {
                      closeDialog();
                    });
                  }}
                >{`Open Payment Reference`}</button>
                <button
                  onClick={() => {
                    window.location.href = '/dashboard';
                  }}
                >{`Move to Dashboard`}</button>
                <button
                  onClick={() => {
                    const token = prompt('Enter Token');
                    if (token) {
                      localStorage.setItem('token', token);
                    }
                  }}
                >{`Set Token`}</button>
                <button
                  onClick={() => {
                    const token = prompt('Enter Route');
                    if (token) {
                      window.location.href = token;
                    }
                  }}
                >{`Hard Navigate to`}</button>
                <button
                  onClick={() => {
                    const route = prompt('Enter Route');
                    if (route) {
                      navigate(route);
                    }
                  }}
                >{`In App Navigate to`}</button>
                <button
                  onClick={() => {
                    const route = prompt('Enter Server Url');
                    if (route) {
                      window.server_url = route;
                    }
                  }}
                >{`Set Server Url`}</button>
                <button
                  onClick={async () => {
                    const echo = prompt('Enter Some thing as Echo');
                    const { value } = await Echo.echo({ value: echo });
                    alert(value);
                  }}
                >{`Test "Echo" Native plugin`}</button>
                <button
                  onClick={async () => {
                    const { value } = await PaymentGatewayCapacitorPlugin.start({
                      orderId: 'bhah',
                      paymentSessionID: 'session',
                    });
                  }}
                >{`Start PaymentGatewayCapacitorPlugin`}</button>

                <button
                  onClick={async () => {
                    WebViewPlugin.open({ url: 'https://google.com' });
                    WebViewPlugin.addListener('urlChanged', (info) => {});
                  }}
                >{`Open WebView`}</button>
                <button
                  onClick={async () => {
                    const hello = '';
                    hello = 'crash test';
                  }}
                >{`Crash`}</button>
                <button
                  onClick={() => {
                    throw new Error('This is a random thrown / handled error');
                  }}
                >
                  {`Throw Error`}
                </button>

                <div>API BASE URL: {API_URL}</div>
              </div>
            )}
          </>
        )}
      </CartContextProvider>
    </Fragment>
  );
}

export { GlobalWrapper, useRadio, openDrawer, openBottomMenu };
