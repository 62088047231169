import DOMPurify from 'dompurify';
import HTMLReactParser from 'html-react-parser';

export const isNumberOrEmptyValue = (val) =>
  (!isNaN(parseFloat(val)) && isFinite(val)) || val?.length === 0;

export const convertHTMLstringToJSX = (htmlString) => {
  const sanitizedString = DOMPurify.sanitize(htmlString);
  return HTMLReactParser(sanitizedString ?? '');
};

export const checkStringIsEnglish = (testString) => {
  return !/^[\w\s~`!@#$%^&*()-_=+[{\]}\\|;:'",<.>/?]*$/.test(testString);
};

export function formatNumber(num) {
  let numStr = `${parseFloat(num)}`;
  numStr = numStr.replace(/(\.[0-9]*[1-9])0+$/, '');
  return numStr;
}

export const formatSentenceByCharsAndWords = (text, length) => {
  const charFormattedText = text?.slice(0, length);
  const commonWords = [];
  const titleWords = text?.split(' ');
  const charFormattedWords = charFormattedText?.split(' ');
  for (let i = 0; i < titleWords?.length; i++) {
    if (titleWords[i] === charFormattedWords[i]) {
      commonWords?.push(titleWords[i]);
    } else {
      break;
    }
  }
  return commonWords?.join(' ') + (commonWords?.length !== titleWords?.length ? '...' : '');
};
