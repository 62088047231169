import React from 'react';
import { Typography, Box } from 'ui-commons/ui-kit';

function TaxApplicable() {
  return (
    <Box>
      <Typography variant={'subtitle'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          fontWeight={'bold'}
          fontSize={'27px'}
          fontFamily={'Inter, sans-serif'}
          height={'100px'}
          marginLeft={'20px'}
        >
          Tax Note
        </Box>
      </Typography>
      <Box pl={'20px'} pr={'20px'}>
        <Typography
          fontSize={'16px'}
          lineHeight={'24px'}
          fontWeight={'300'}
          mb={2}
          display={'block'}
        >
          The monthly rentals received against leasing assets to Businesses are taxed under the
          ‘Profits & Gains from Business & Profession (PGBP)’ head, while filing ITR.
        </Typography>
        {/*<Typography*/}
        {/*  fontSize={'16px'}*/}
        {/*  lineHeight={'24px'}*/}
        {/*  fontWeight={'300'}*/}
        {/*  mb={2}*/}
        {/*  display={'block'}*/}
        {/*>*/}
        {/*  All investments in Invoice Discounting attract a flat 10% Tax Deducted at source (TDS) on*/}
        {/*  the return amount.*/}
        {/*</Typography>*/}

        <Typography
          fontSize={'16px'}
          lineHeight={'24px'}
          fontWeight={'300'}
          mb={2}
          display={'block'}
        >
          Eligible depreciation can be claimed on Written Down Value (WDV) of the asset against its
          rental income, as a business expense.
        </Typography>
        <Typography
          fontSize={'16px'}
          lineHeight={'24px'}
          fontWeight={'300'}
          mb={2}
          display={'block'}
        >
          Please refer to the below link before filing your next ITR, to identify the correct format
          applicable to you.
        </Typography>
        <a
          href={'https://incometaxindia.gov.in/Pages/downloads/income-tax-return.aspx'}
          target="_blank"
        >
          {' '}
          <Typography
            fontSize={'16px'}
            lineHeight={'24px'}
            fontWeight={'300'}
            mb={2}
            display={'block'}
            sx={{
              overflowWrap: 'break-word',
            }}
          >
            https://incometaxindia.gov.in/Pages/downloads/income-tax-return.aspx
          </Typography>
        </a>
        <Typography
          fontSize={'16px'}
          lineHeight={'24px'}
          fontWeight={'300'}
          mb={2}
          display={'block'}
        >
          To know more about the ITR filing process, visit the below link
        </Typography>
        <a
          href={'https://incometaxindia.gov.in/Pages/tax-services/file-income-tax-return.aspx'}
          target={'_blank'}
        >
          {' '}
          <Typography
            fontSize={'16px'}
            lineHeight={'24px'}
            fontWeight={'300'}
            mb={2}
            display={'block'}
            sx={{
              overflowWrap: 'break-word',
            }}
          >
            https://incometaxindia.gov.in/Pages/tax-services/file-income-tax-return.aspx
          </Typography>
        </a>
        <Typography fontSize={'16px'} lineHeight={'24px'} fontWeight={'300'} mb={2}>
          For any further clarifications, feel free to reach out to our support team&nbsp;
          <a href={'https://wa.me/916362667523'} target={'_blank'}>
            here
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default TaxApplicable;
