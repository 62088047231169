class Radio {
  constructor() {
    this.events = {};
  }

  broadcast(event, data) {
    let self = this;
    if (!self.events.hasOwnProperty(event)) {
      return [];
    }

    return self.events[event].map((callback) => callback(data));
  }

  unSubscribe(event, callbackPosition = -1) {
    let self = this;

    if (!self.events.hasOwnProperty(event)) {
      return -1
    }

    self.events[event] =  [...self.events[event].slice(0,callbackPosition), ...self.events[event].slice(callbackPosition+1)];

    return self.events[event].length;
  }

  unSubscribeAll(event) {
    let self = this;

    if (!self.events.hasOwnProperty(event)) {
      return -1
    }

    delete self.events[event]

    return 0
  }

  subscribe(event, callback) {
    let self = this;
    if (!self.events.hasOwnProperty(event)) {
      self.events[event] = [];
    }
    return self.events[event].push(callback);
  }
}

export default Radio;
