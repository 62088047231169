import { useTheme, Box } from '../../mui';
import get from 'lodash/get';

function ShadowCard({ shadow = '', children, backgroundColor = '', background = '', ...rest }) {
  const theme = useTheme();
  return (
    <Box
      boxShadow={get(theme.palette.colors.shadow, shadow, theme.palette.colors.shadow.elevation1)}
      backgroundColor={get(theme.palette.colors, backgroundColor, 'transparent')}
      sx={{ ...rest?.sx }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default ShadowCard;
