import React, { useState } from 'react';
import {
  ActionLayout1,
  Box,
  Button,
  CircularProgress,
  Close,
  Grid,
  IconButton,
  Image,
  ModalBase,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from 'ui-commons/ui-kit';
import { uploadExternalPaymentReference, uploadExternalPaymentReferenceV2 } from './actions';
import { trackEvent } from '../_deals/actions';
import { events } from '../utils/analytics';
import { useConfig } from '../utils/configContext';

export default function PaymentUploadReference({
                                                 onClose,
                                                 onSuccess,
                                                 investmentId = null,
                                                 investmentCreationCallback = null,
                                                 financeType,
                                               }) {
  const [txId, setTxId] = useState('');
  const [errorTx, setErrorTx] = useState(null);
  const [errorFile, setErrorFile] = useState(null);
  const [notes, setNotes] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { config } = useConfig();
  // const newV2Flow = config?.features?.newV2Flow ?? false
  const newV2Flow = false;
  // useEffect(() => {
  //   if (!investmentId) {
  //     console.error('Investment Id cannot be null');
  //   }
  // }, [investmentId]);

  const handletxIdChange = (e) => {
    setErrorTx(null);
    setTxId(e.target.value);
  };

  const handleFileChange = (e) => {
    setErrorFile('');
    setFile(e.target.files[0]);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleUpload = (investmentId, formData) => {
    if (newV2Flow) {
      uploadExternalPaymentReferenceV2(investmentId, formData)
        .then((res) => {
          setIsSuccess(true);
          setTimeout(() => {
            typeof onSuccess === 'function' && onSuccess();
            setIsLoading(false);
          }, 1500);
        })
        .catch((err) => {
          setErrorFile('Please ensure that you are uploading an image with size less than 1 MB');
          setIsLoading(false);
        });
    } else {
      uploadExternalPaymentReference(investmentId, formData)
        .then((res) => {
          setIsSuccess(true);
          setTimeout(() => {
            typeof onSuccess === 'function' && onSuccess();
            setIsLoading(false);
          }, 1500);
        })
        .catch((err) => {
          setErrorFile('Please ensure that you are uploading an image with size less than 1 MB');
          setIsLoading(false);
        });
    }
  };


  const onSubmit = () => {
    if (!txId) {
      setErrorTx('Transaction Id is required');
      return;
    }
    trackEvent(events.SubmitPaymentProof);
    setIsLoading(true);
    const data = {
      txId,
      notes,
      file,
      financeType,
    };


    if (!investmentId) {
      // Trigger investment mutation
      investmentCreationCallback((investmentId) => {
        handleUpload(investmentId, data);
      });
    } else {
      handleUpload(investmentId, data);
    }
  };

  const renderOnSumission = () => {
    return (
      <ModalBase display={'flex'} alignItems={'center'} flexDirection={'column'}>
        <Box>
          {isSuccess ? (
            <Image
              style={{
                width: '108px',
              }}
              src={'/images/tick.webp'}
              srcSet={[
                {
                  src: '/images/tick.webp',
                },
                {
                  src: '/images/tick.png',
                },
              ]}
            />
          ) : (
            <CircularProgress color='primary' />
          )}
        </Box>
        <Box mb={2} textAlign={'center'} padding={'32px'}>
          <Box mt={1}>
            {isSuccess ? (
              <Typography variant={'h5'} color={theme.palette.colors.primaryGreenText}>
                Upload Successful
              </Typography>
            ) : (
              <>
                <Typography variant={'h5'}>We are uploading your submission!</Typography>
              </>
            )}
          </Box>
          <Box mt={1}>
            {isSuccess ? (
              <Typography variant={'b3'}>Redirecting to the dashboard</Typography>
            ) : (
              <Typography variant={'phTextMediumReg'} color={'text.warning'}>
                Do not leave the page until successful.
              </Typography>
            )}
          </Box>
        </Box>
      </ModalBase>
    );
  };

  return (
    <ActionLayout1 headerText={isLoading ? null : 'Upload Payment proof'}>
      {isLoading && renderOnSumission()}
      {!isLoading && (
        <>
          {!!onClose && (
            <IconButton
              aria-label='close'
              onClick={() => {
                if (isLoading) return;
                typeof onClose === 'function' && onClose();
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.colors.black,
              }}
            >
              <Close />
            </IconButton>
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{
                paddingLeft: 2.5,
                paddingRight: 2.5,
              }}
            >
              <Grid item xs={12}>
                <Box mt={1}>
                  <Typography variant={'phTextLargeReg'} color={'text.secondary'}>
                    Please share your transaction details and{' '}
                    <span
                      style={{
                        color: theme.palette.text.action,
                      }}
                    >
                      'Payment Success'
                    </span>{' '}
                    screenshot, if available.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} mb={{ xs: 0, md: 1 }}>
                <TextField
                  fullWidth
                  name={'Transaction Id'}
                  label={'Transaction Id *'}
                  onChange={handletxIdChange}
                  helperText={errorTx}
                  error={!!errorTx}
                  value={txId}
                />
              </Grid>
              <Grid item xs={12} mb={{ xs: 1, md: 4 }}>
                <TextField
                  fullWidth
                  helperText={errorFile ?
                    <Typography variant={'phTextSmall'} color={'text.error'}>{errorFile}</Typography> : null}
                  type={'file'}
                  name={'Upload Payment Screenshot'}
                  onChange={handleFileChange}
                  onClick={() => {
                    trackEvent(events.ClickedOnChooseFile, { investmentId: investmentId });
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      paddingTop: '16px',
                      paddingBottom: '16px',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Box
              mt={{ xs: 2, md: 2 }}
              mb={{ xs: 2.5, md: 4 }}
              sx={{
                paddingLeft: 2.5,
                paddingRight: 2.5,
              }}
            >
              <Button fullWidth onClick={onSubmit} size={'medium'}>
                Submit
              </Button>
            </Box>
          </form>
        </>
      )}
    </ActionLayout1>
  );
}
