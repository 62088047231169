import { QueryClient, QueryClientProvider, useMutation, useQuery } from './dependency/reactQuery';
import axios from './dependency/axios';
import { API_URL, PATH, API_URL_V2 } from './endpoints';
import { decision } from 'global';
import UAParser from 'ua-parser-js';
import { eraseCookie, getCookie, setCookie } from 'utils';

/**
 * This is an abstracted method over axios.
 * Just provide the path and base url will be taken up based on node environment automatically
 * @param {*} data  { path: '/login/'}
 * @returns
 */

let appInfo = null;
let parsedUSerAgent = null;
// Function to check if a given path is unauthenticated
function isUnauthenticatedAPI(path) {
  // Define unauthenticated paths
  const unauthPaths = [
    '/v2/ping',
    '/v2/auth',
    '/v2/preferences',
    '/analytics',
    'api/config',
    '/users/check',

    // Add other unauthenticated API paths here
  ];
  // Check if the provided path matches any of the unauthenticated paths
  return unauthPaths.some((unauthPath) => path.includes(unauthPath));
}

async function Call(data) {
  var isLoggedOut = localStorage.getItem('isLoggedOut');
  if (isLoggedOut == null) {
    localStorage.setItem('isLoggedOut', 'true');
    isLoggedOut = 'true';
  }

  const authToken = localStorage.getItem('token');
  const sessionId = localStorage.getItem('_session');
  const deviceId = localStorage.getItem('_dshan');
  const deviceType =
    window.innerWidth <= 600 ? 'Mobile' : window.innerWidth <= 900 ? 'Tablet' : 'Desktop';
  const isFlutter = window.localStorage.getItem('isFlutter');
  const utms = getCookie('_lrutm');
  let x = await window.cookieStore?.get('X-Tap-Session');
  let sessionCookieExpired = new Date(x?.expires) < new Date();

  // ! If there is no token - only pass ping
  // if (!authToken && !data.path.includes('/v2/ping')) return;
  // ! If logged in
  if (isLoggedOut !== 'true') {
    // ! logged in but session or deviceid is not present - hit ping first
    if ((!sessionId || !deviceId) && !data.path.includes('/v2/ping')) {
      return;
    }
    // ! Logged in but session has expired - hit ping first
    if (sessionId && sessionCookieExpired && !data.path.includes('/v2/ping')) {
      return;
    }
  }
  const isNotPingAndKraken = (path) => {
    if (path?.includes('v2')) return path?.includes('v2/ping');
    return true;
  };
  if (isLoggedOut == 'true') {
    if ((!sessionId || sessionCookieExpired || !deviceId) && !isNotPingAndKraken(data?.path)) {
      return;
    } else if (!isUnauthenticatedAPI(data.path)) {
      // Prevent API call if user is logged out and the endpoint is not unauthenticated
      console.warn('User is logged out.', data.path);
      return;
    }
  }
  let headers = {};
  if (data?.headers) {
    headers = { ...headers, ...(data?.headers || {}) };
    delete data.headers;
  }
  headers = {
    ...headers,
    'X-Client-Type': 'Web Investor App',
    'X-Platform-Request': isFlutter ? 'webview' : decision?.platform || 'web',
    'X-Device-Type': deviceType,
  };
  setCookie('X-Client-Type', 'Web Investor App');
  setCookie('X-Platform-Request', isFlutter ? 'webview' : decision?.platform || 'web');
  if (authToken) {
    headers = { ...headers, 'X-Tap-Auth': authToken };
  }
  if (sessionId) {
    headers = { ...headers, _session: sessionId, 'X-Tap-Session': sessionId };
  }
  if (deviceId) {
    headers = { ...headers, _dshan: deviceId, 'X-Device-Id': deviceId };
  }
  if (parsedUSerAgent === null) {
    let parser = new UAParser(window.navigator.userAgent);
    parsedUSerAgent = parser.getResult();
  }
  let { os, device } = parsedUSerAgent;
  try {
    if (appInfo === null) {
      appInfo = await decision.getAppInfo();
    }
  } catch (err) {
    // Handle error while getting app info
  }
  if (os) {
    headers = {
      ...headers,
      'X-OS-Type': os.name,
      'X-OS-Version': os.version,
    };
    setCookie('X-OS-Type', os.name);
    setCookie('X-OS-Version', os.version);
  }
  if (device) {
    headers = {
      ...headers,
      'X-Device-Model': device.model,
    };
    setCookie('X-Device-Model', device.model);
  }
  if (utms) {
    headers = {
      ...headers,
      'X-User-Lrutm': utms,
    };
  }
  // headers = { ...headers, 'x-platform-request': isFlutter ? 'webview' : decision.platform };
  if (appInfo?.build) {
    headers = { ...headers, 'x-client-build-id': appInfo.build };
  }

  let baseURL = data.path.includes('/v2/') ? API_URL_V2 : API_URL;
  if (data?.isV2) {
    baseURL = API_URL_V2;
  }
  if (data?.overrideBaseURL) {
    baseURL = data?.overrideBaseURL;
  }

  let option = {
    url: data.path,
    baseURL,
    headers,
    withCredentials: true,
    ...data,
  };

  try {
    const r = await axios(option);
    const responseSessionId = r.headers['x-tap-session'];
    const responseDeviceId = r.headers['x-device-id'];
    if (responseSessionId) {
      localStorage.setItem('_session', responseSessionId);
    }
    if (responseDeviceId) {
      localStorage.setItem('_dshan', responseDeviceId);
    }

    return data.getOriginalResponse === true ? r : r.data;
  } catch (err) {
    if (err?.response?.status === 401) {
      // Logout user and prevent further authenticated API calls
      localStorage.removeItem('token');
      localStorage.removeItem('_session');
      localStorage.removeItem('_dshan');
      sessionStorage.removeItem('_session');
      eraseCookie('X-Tap-Session');
      eraseCookie('_session');
      localStorage.setItem('isLoggedOut', 'true'); // Set logout flag
      if (!data?.disableRedirect) {
        if (window) {
          // Redirect to /signup
          window.location.replace('/signup');
        } else {
          console.error('Failed to redirect to /signup');
        }
      }
    }
    if (err?.response?.status === 504) {
      // Handle gateway timeout error
      return;
    }
    throw err; // Rethrow the error for further handling if necessary
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

const CustomQueryClientProvider = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export {
  Call,
  PATH,
  API_URL,
  CustomQueryClientProvider,
  useQuery,
  useMutation,
  queryClient,
  API_URL_V2,
};
