import React, { useCallback, useContext, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

const CartContext = React.createContext({});

// Serializing cart and cart Meta
// Make sure to clear the localstorage if the data is stale or item are being added from a new deal
export function CartContextProvider({ children }) {
  const localStorageCartData = JSON.parse(localStorage.getItem('cartData') || '{}');
  const localStorageCart = (localStorageCartData && localStorageCartData?.cartData?.cart) || {};
  const localStorageCartMeta =
    (localStorageCartData && localStorageCartData?.cartData?.cartMeta) || {};

  const [cart, setCart] = useState(localStorageCart);
  const [cartMeta, setCartMeta] = useState(localStorageCartMeta);


  const saveSetCart = useCallback((newCart) => {
    let localStorageCartData = JSON.parse(localStorage.getItem('cartData') || '{}');
    if (isEmpty(localStorageCartData)) {
      localStorageCartData = {
        cartData: {
          cart: newCart,
          updatedAt: new Date(),
        },
      };
    } else {
      localStorageCartData['cartData']['cart'] = newCart;
      localStorageCartData['cartData']['updatedAt'] = new Date();
    }
    localStorage.setItem('cartData', JSON.stringify(localStorageCartData));
  }, []);

  const saveCartMeta = useCallback((newCartMeta) => {
    let localStorageCartData = JSON.parse(localStorage.getItem('cartData') || '{}');
    if (isEmpty(localStorageCartData)) {
      localStorageCartData = {
        cartData: {
          cartMeta: newCartMeta,
          updatedAt: new Date(),
        },
      };
    } else {
      localStorageCartData['cartData']['cartMeta'] = newCartMeta;
      localStorageCartData['cartData']['updatedAt'] = new Date();
    }
    localStorage.setItem('cartData', JSON.stringify(localStorageCartData));
  }, []);

  const contextValue = {
    cart,
    setCart,
    cartMeta,
    setCartMeta,
    saveSetCart,
    saveCartMeta,
  };
  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
}

export const removeCartKey = () => { localStorage.removeItem('cartData')};

export function useCart() {
  const {
    setCart,
    cart,
    cartMeta,
    setCartMeta: _setCartMeta,
    saveSetCart,
    saveCartMeta,
  } = useContext(CartContext);

  const add = useCallback((item, id) => {
    setCart((prevState) => {
      const newCart = { ...prevState, [id]: item };
      saveSetCart(newCart);
      return newCart;
    });
  }, []);

  const setCartMeta = useCallback((cartMeta) => {
    _setCartMeta((prevState) => {
      const newCartMeta = { ...prevState, ...cartMeta };
      saveCartMeta(newCartMeta);
      return newCartMeta;
    });
  }, []);

  const remove = useCallback((id) => {
    setCart((prevState) => {
      const newState = { ...prevState };
      delete newState[id];
      saveSetCart(newState);
      return newState;
    });
  }, []);

  const removeAll = useCallback(() => {
    saveSetCart({});
    saveCartMeta({});
    setCartMeta({});
    setCart({});
  }, []);

  const getCartList = () => Object.values(cart);

  const itemsInCart = useMemo(() => {
    return Object.values(cart).length;
  }, [cart]);

  const totalInvestments = useMemo(() => {
    return Object.values(cart).reduce((sum, item) => {
      return sum + item?.assetRequest?.buyPrice * item?.qty;
    }, 0);
  }, [cart]);

  const totalRental = useMemo(() => {
    return Object.values(cart).reduce((sum, item, index) => {
      const availableQuantity = (item?.assetRequest?.available || 0) > item?.qty ? item?.qty : item?.assetRequest?.available;
      return sum + item?.assetRequest?.rentalAmount * availableQuantity;
    }, 0);
  }, [cart]);

  const totalReturn = useMemo(() => {
    return Object.values(cart).reduce((sum, item) => {
      return (
        sum +
        item?.qty *
          (item?.assetRequest?.payoutAmount * item?.assetRequest?.tenure +
            item?.assetRequest?.saleValue)
      );
    }, 0);
  }, [cart]);

  const cartItemLength = getCartList().length;
  const isCartEmpty = cartItemLength === 0;

  return {
    cart,
    add,
    remove,
    itemsInCart,
    totalInvestments,
    totalRental,
    totalReturn,
    getCartList,
    setCartMeta,
    cartMeta,
    removeAll,
    isCartEmpty,
  };
}
