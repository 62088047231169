import React, { useEffect } from 'react';
import { Dialog, Backdrop, DialogContent, Box } from 'ui-commons/ui-kit';

function GlobalDialog({
  open,
  close,
  component,
  dialogData = null,
  fullScreen = false,
  dialogStyles = {},
}) {
  const ref = React.useRef(null);
  const noDialogContent = dialogData?.noDialogContent;
  const disableInteraction = dialogData?.disableInteraction ? true : false;
  const modifiedOnClose = disableInteraction ? null : close;
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [component]);

  if (!dialogData) {
    return null;
  }
  if (noDialogContent) {
    return (
      <Backdrop
        ref={ref}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={modifiedOnClose}
        tabIndex={0}
        onKeyDown={
          modifiedOnClose
            ? (e) => {
                if (e.key === 'Escape') {
                  modifiedOnClose();
                }
              }
            : null
        }
      >
        {component}
      </Backdrop>
    );
  }

  return (
    <Dialog
      open={open}
      keepMounted
      TransitionComponent={dialogData?.transitionComponent}
      fullScreen={dialogData?.fullScreen}
      maxWidth={dialogData.width || 'md'}
      onBackdropClick={modifiedOnClose}
      sx={{
        transition: 'height 0.5s ease-in-out',
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
        },
      }}
    >
      <Box
        ref={ref}
        sx={{
          padding: 0,
          borderRadius: '16px',
        }}
        display={'flex'}
        flexDirection={'column'}
        onKeyDown={
          modifiedOnClose
            ? (e) => {
                if (e.key === 'Escape') {
                  modifiedOnClose();
                }
              }
            : null
        }
      >
        {component}
      </Box>
    </Dialog>
  );
}

export default GlobalDialog;
