import React from 'react';
import { formatNumber } from 'utils/stringUtils';

const Currency = ({ value, emptyDash, decimalScale = 2, isNumericString, showRuppee = true }) => {
  const NumberFormat = require('react-number-format').default;
  let decimalScaleParam = decimalScale;
  if (!`${value}`?.includes(','))
    decimalScaleParam = Math.min((`${formatNumber(value)}`?.split('.')?.[1] ?? [])?.length, 2);
  return (
    <React.Fragment>
      {emptyDash && !value ? (
        '0'
      ) : (
        <NumberFormat
          thousandSeparator
          thousandsGroupStyle={'lakh'}
          value={value}
          prefix={showRuppee && '₹'}
          displayType={'text'}
          fixedDecimalScale
          decimalScale={decimalScaleParam ?? decimalScale}
          isNumericString={isNumericString}
        />
      )}
    </React.Fragment>
  );
};

export default Currency;
