import Loader from '../ui-commons/ui-kit/assets/loader.gif';
export const LeafLoader = () => (
  <div
    id="leaf-loader-logo"
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
  >
    <img src={Loader} alt="loader" />
    {/* <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.99976" y="7.5" width="89.9998" height="25" fill="black" />
      <rect x="22.4998" y="37.5" width="54.9999" height="25" fill="black" />
      <rect x="37.4998" y="67.5" width="25" height="25" fill="black" />
    </svg>

    <svg id="ring" fill="none" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stop-color="#000000"></stop>
          <stop offset="100%" stop-color="#FFFFFF"></stop>
        </linearGradient>
      </defs>
      <circle cx="50%" cy="50%" r="70" stroke="url(#linearColors1)" stroke-width="3" />
    </svg> */}
  </div>
);
