import { toRoundDown } from './index';

export const getMinInvestmentRequiredOfADeal = (deal) => {
  if (!deal) return '';
  if (deal.minInvestment) {
    return deal.minInvestment;
  }
  return toRoundDown(deal.assetRequests.reduce((s, r) => s + r.minInvestment, 0));
};

export const getMinQtyMapForDeal = (deal) =>
  deal.assetRequests.reduce(
    (acc, arq) => ({
      ...acc,
      [arq.id]: arq.minQty,
    }),
    {},
  );

export const getUnitPriceMapForDeal = (deal) =>
  deal.assetRequests.reduce(
    (acc, arq) => ({
      ...acc,
      [arq.id]: arq.buyPrice,
    }),
    {},
  );

export function isInvoiceDiscountingFinanceType(deal) {
  return deal?.financeType === 'INVOICE_DISCOUNTING';
}

/**
 * function calculates the possible investments for a given amount and asset request data by maximizing the Internal Rate of Return (IRR). The function first sorts the asset request data in descending order of representational IRR. Then, it iterates over the sorted asset request data and calculates the required number of units to invest the remaining investment amount in each asset. If the calculated number of units is less than 1, the function returns null. The function then checks if the rounded up and rounded down values of the number of units are available. If both are available, the function calculates the opportunity gain/lost if higher or lower units are chosen and pushes the data to a buckets array. If none of the rounded values of the number of units are available, the function checks if there is any available unit and invests in it. Finally, the function returns the bucket array.
 * @param investmentAmount
 * @param assetRequest
 * @returns {*[]}
 */
export function getPossibleInvestmentsByMaximizingIRR(investmentAmount, assetRequest = []) {
  // const assetRequestSortedByIRRInDesc =  assetRequest.sort((first,second)=>{
  //   return second.representationalIrr - first.representationalIrr;
  // })

  const assetRequestSortedByIRRInDesc = assetRequest
    .sort((first, second) => {
      return second.netPrice - first.netPrice;
    })
    .filter((ar) => ar.available > 0);

  let remainingInvestmentAmount = investmentAmount;
  let lastLowestAmount = 0;
  // let lastChosenAssetRequestId = []
  let lastChosenAssetRequest = [];
  const buckets = [];

  assetRequestSortedByIRRInDesc.forEach((as) => {
    // Fractional Value
    const unitRequired = remainingInvestmentAmount / as.netPrice;
    if (unitRequired === 0) {
      return;
    }

    const unitAvailable = as.available;
    const isHigherUnitAvailable = Math.ceil(unitRequired) <= unitAvailable;
    const isLowerUnitAvailable = Math.floor(unitRequired) <= unitAvailable;

    if (
      isHigherUnitAvailable &&
      isLowerUnitAvailable &&
      Math.ceil(unitRequired) === Math.floor(unitRequired)
    ) {
      let amount = Math.ceil(unitRequired) * as.netPrice;
      remainingInvestmentAmount = remainingInvestmentAmount - amount;
      lastChosenAssetRequest.push({
        assetRequestId: as.id,
        qty: Math.ceil(unitRequired),
        assetRequest: as,
      });
      const chosenAmount = amount + lastLowestAmount;
      buckets.push({
        chosenAmount: chosenAmount,
        chosenAssetRequests: [...lastChosenAssetRequest],
        chosenQty: Math.ceil(unitRequired),
        fromInvestedAmount: investmentAmount - chosenAmount,
        diff: remainingInvestmentAmount,
      });
    } else {
      if (isHigherUnitAvailable) {
        let higherUnits = Math.ceil(unitRequired);
        if (higherUnits === 0) {
          return;
        }
        let higherAmount = higherUnits * as.netPrice;
        let opportunityGain = higherAmount - investmentAmount;
        const chosenAmount = higherAmount + lastLowestAmount;
        buckets.push({
          chosenAmount: higherAmount + lastLowestAmount,
          chosenAssetRequests: [
            ...lastChosenAssetRequest,
            { assetRequestId: as.id, qty: higherUnits, assetRequest: as },
          ],
          chosenQty: higherUnits,
          fromInvestedAmount: investmentAmount - chosenAmount,
          diff: opportunityGain,
        });
      }

      if (isLowerUnitAvailable) {
        let lowerUnits = Math.floor(unitRequired);
        if (lowerUnits === 0) {
          return;
        }

        let lowerAmount = lowerUnits * as.netPrice;
        let opportunityLost = remainingInvestmentAmount - lowerAmount;
        remainingInvestmentAmount = remainingInvestmentAmount - lowerAmount;
        lastChosenAssetRequest.push({ assetRequestId: as.id, qty: lowerUnits, assetRequest: as });
        const chosenAmount = lowerAmount + lastLowestAmount;

        buckets.push({
          chosenAmount: chosenAmount,
          chosenAssetRequests: [...lastChosenAssetRequest],
          chosenQty: lowerUnits,
          fromInvestedAmount: investmentAmount - chosenAmount,
          diff: -opportunityLost,
        });
        lastLowestAmount = lowerAmount + lastLowestAmount;
      }

      if (!isLowerUnitAvailable && !isLowerUnitAvailable) {
        if (as.available > 0) {
          let amount = as.available * as.netPrice;
          remainingInvestmentAmount = remainingInvestmentAmount - amount;
          lastChosenAssetRequest.push({
            assetRequestId: as.id,
            qty: as.available,
            assetRequest: as,
          });
          const chosenAmount = amount + lastLowestAmount;
          buckets.push({
            chosenAmount: chosenAmount,
            chosenAssetRequests: [...lastChosenAssetRequest],
            chosenQty: as.available,
            fromInvestedAmount: investmentAmount - chosenAmount,
            diff:
              remainingInvestmentAmount > amount
                ? -remainingInvestmentAmount
                : remainingInvestmentAmount,
          });
          lastLowestAmount = amount + lastLowestAmount;
        }
      }
    }
  });

  return buckets.sort((first, second) => {
    return Math.abs(first.fromInvestedAmount) - Math.abs(second.fromInvestedAmount);
  });
}

/**
 *
 * @param rentalAmount
 * @param assetRequest
 * @returns {*[]}
 */
export function getPossibleInvestmentsByMaximisingRental(rentalAmount, assetRequest = []) {
  // const assetRequestSortedByIRRInDesc =  assetRequest.sort((first,second)=>{
  //   return second.representationalIrr - first.representationalIrr;
  // })

  const assetRequestSortedByIRRInDesc = assetRequest
    .sort((first, second) => {
      return second.rentalAmount - first.rentalAmount;
    })
    .filter((ar) => ar.available > 0);

  let remainingRentalAmount = rentalAmount;
  let lastLowestAmount = 0;
  // let lastChosenAssetRequestId = []
  let lastChosenAssetRequest = [];
  const buckets = [];

  assetRequestSortedByIRRInDesc.forEach((as) => {
    // Fractional Value
    const unitRequired = remainingRentalAmount / as.rentalAmount;
    if (unitRequired === 0) {
      return;
    }

    const unitAvailable = as.available;
    const isHigherUnitAvailable = Math.ceil(unitRequired) <= unitAvailable;
    const isLowerUnitAvailable = Math.floor(unitRequired) <= unitAvailable;

    if (
      isHigherUnitAvailable &&
      isLowerUnitAvailable &&
      Math.ceil(unitRequired) === Math.floor(unitRequired)
    ) {
      let amount = Math.ceil(unitRequired) * as.rentalAmount;
      remainingRentalAmount = remainingRentalAmount - amount;
      lastChosenAssetRequest.push({
        assetRequestId: as.id,
        qty: Math.ceil(unitRequired),
        assetRequest: as,
      });
      const chosenAmount = amount + lastLowestAmount;
      buckets.push({
        chosenAmount: chosenAmount,
        chosenAssetRequests: [...lastChosenAssetRequest],
        chosenQty: Math.ceil(unitRequired),
        fromInvestedAmount: rentalAmount - chosenAmount,
        diff: remainingRentalAmount,
      });
    } else {
      if (isHigherUnitAvailable) {
        let higherUnits = Math.ceil(unitRequired);
        if (higherUnits === 0) {
          return;
        }
        let higherAmount = higherUnits * as.rentalAmount;
        let opportunityGain = higherAmount - rentalAmount;
        const chosenAmount = higherAmount + lastLowestAmount;
        buckets.push({
          chosenAmount: higherAmount + lastLowestAmount,
          chosenAssetRequests: [
            ...lastChosenAssetRequest,
            { assetRequestId: as.id, qty: higherUnits, assetRequest: as },
          ],
          chosenQty: higherUnits,
          fromInvestedAmount: rentalAmount - chosenAmount,
          diff: opportunityGain,
        });
      }

      if (isLowerUnitAvailable) {
        let lowerUnits = Math.floor(unitRequired);
        if (lowerUnits === 0) {
          return;
        }

        let lowerAmount = lowerUnits * as.rentalAmount;
        let opportunityLost = remainingRentalAmount - lowerAmount;
        remainingRentalAmount = remainingRentalAmount - lowerAmount;
        lastChosenAssetRequest.push({ assetRequestId: as.id, qty: lowerUnits, assetRequest: as });
        const chosenAmount = lowerAmount + lastLowestAmount;

        buckets.push({
          chosenAmount: chosenAmount,
          chosenAssetRequests: [...lastChosenAssetRequest],
          chosenQty: lowerUnits,
          fromInvestedAmount: rentalAmount - chosenAmount,
          diff: -opportunityLost,
        });
        lastLowestAmount = lowerAmount + lastLowestAmount;
      }

      if (!isLowerUnitAvailable && !isLowerUnitAvailable) {
        if (as.available > 0) {
          let amount = as.available * as.rentalAmount;
          remainingRentalAmount = remainingRentalAmount - amount;
          lastChosenAssetRequest.push({
            assetRequestId: as.id,
            qty: as.available,
            assetRequest: as,
          });
          const chosenAmount = amount + lastLowestAmount;
          buckets.push({
            chosenAmount: chosenAmount,
            chosenAssetRequests: [...lastChosenAssetRequest],
            chosenQty: as.available,
            fromInvestedAmount: rentalAmount - chosenAmount,
            diff: remainingRentalAmount > amount ? -remainingRentalAmount : remainingRentalAmount,
          });
          lastLowestAmount = amount + lastLowestAmount;
        }
      }
    }
  });

  return buckets.sort((first, second) => {
    return Math.abs(first.fromInvestedAmount) - Math.abs(second.fromInvestedAmount);
  });
}

/**
 * Calculates the annualized total return of an investment
 * @param {number} totalReturns - The total amount returned from the investment
 * @param {number} amountInvested - The initial amount invested
 * @param {number} years - The number of years the investment was held for
 * @returns {number} The annualized total return as a percentage (e.g. 5.5% is returned as 5.5)
 */
export function annualizedTotalReturn(totalReturns, amountInvested, years) {
  return (Math.pow(totalReturns / amountInvested, 1 / years) - 1) * 100;
}

export function splitDealsIntoListingCategory(liveDeals = []) {
  const addAsArray = (container = {}, key = '', element) => {
    if (container[key] === undefined) {
      container[key] = [element];
    } else {
      container[key].push(element);
    }
  };

  const result = liveDeals.reduce(
    (acc, deal) => {
      if (deal?.meta?.listingCategory === 'LISTING_CATEGORY_KICK_STARTER') {
        addAsArray(acc, 'kickStarter', deal);
      } else {
        const financeType = deal?.financeType;
        switch (financeType) {
          case 'ASSET_LEASING':
            addAsArray(acc, 'asset', deal);
            break;
          case 'INVOICE_DISCOUNTING':
            addAsArray(acc, 'invoice', deal);
            break;
          case 'NON_CONVERTIBLE_DEBENTURES':
            addAsArray(acc, 'ncd', deal);
            break;
          default:
            break;
        }
      }
      return acc;
    },
    {
      asset: [],
      invoice: [],
      ncd: [],
      kickStarter: [],
    },
  );
  return result;
}

export function splitDealsIntoListingCategoryV2(liveDeals = []) {
  const addAsArray = (container = {}, key = '', element) => {
    if (container[key] === undefined) {
      container[key] = [element];
    } else {
      container[key].push(element);
    }
  };

  const result = liveDeals.reduce(
    (acc, deal) => {
      if (deal?.listingCategory === 'LISTING_CATEGORY_KICK_STARTER') {
        addAsArray(acc, 'kickStarter', deal);
      } else {
        const financeType = deal?.financeType;
        switch (financeType) {
          case 'ASSET_LEASING':
            addAsArray(acc, 'asset', deal);
            break;
          case 'INVOICE_DISCOUNTING':
            addAsArray(acc, 'invoice', deal);
            break;
          case 'NON_CONVERTIBLE_DEBENTURES':
            addAsArray(acc, 'ncd', deal);
            break;
          default:
            break;
        }
      }
      return acc;
    },
    {
      asset: [],
      invoice: [],
      ncd: [],
      kickStarter: [],
    },
  );
  return result;
}

export const DEAL_CATEGORIES_CONFIG = {
  LISTING_CATEGORY_KICK_STARTER: {
    key: 'LISTING_CATEGORY_KICK_STARTER',
    displayName: 'Kick Starter',
  },
  ASSET_LEASING: {
    key: 'ASSET_LEASING',
    displayName: 'Asset Leasing',
  },
  INVOICE_DISCOUNTING: {
    key: 'INVOICE_DISCOUNTING',
    displayName: 'Invoice Discounting',
  },
  POOLING: {
    key: 'POOLING',
    displayName: 'Invoice Pooling',
  },
  NON_CONVERTIBLE_DEBENTURES: {
    key: 'NON_CONVERTIBLE_DEBENTURES',
    displayName: 'ncd',
  },
  SECONDARY_NON_CONVERTIBLE_DEBENTURES: {
    key: 'SECONDARY_NON_CONVERTIBLE_DEBENTURES',
    displayName: 'sncd',
  },
};
