import {Box, ErrorOutlineOutlinedIcon, CheckCircleIcon,  styled} from "../../mui";
import Typography from "./../Typography";
import React from "react";
import {Else, If, Then} from "react-if";
import { useMobileLayout } from '../../../../hooks/layout';

// Keep adding new variants here
const ALERT_FLOATING_BANNER_VARIANT_CONFIG = {
  'warning': {
    icon: ErrorOutlineOutlinedIcon,
    color: (theme) => theme.palette.text.warning,
    borderColor: (theme) => theme.palette.border.warning,
  },
  'success': {
    icon: CheckCircleIcon,
    color: (theme)=>theme.palette.text.action,
    borderColor: (theme) => theme.palette.border.green1,
  },
}

const AlertContainerStyled = styled(Box)(({variant = 'success', theme}) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '6px',
    paddingLeft: '8px',
    paddingRight:'8px',
    paddingBottom: '6px',
    borderRadius: '5px',
    border: '1px dashed '+ALERT_FLOATING_BANNER_VARIANT_CONFIG[variant].borderColor(theme)

  }
})

export default function AlertFloatingBanner({variant = 'success', size = 'small', children, text = ''}) {

  const Icon = ALERT_FLOATING_BANNER_VARIANT_CONFIG[variant].icon;
  const isMobile = useMobileLayout()
  return <AlertContainerStyled
      variant={variant}
  >
    <Box display={'flex'} alignItems={'center'}>
      <Box
          color={(theme) => ALERT_FLOATING_BANNER_VARIANT_CONFIG[variant].color(theme)}
          display={'flex'}
          alignItems={'center'}
      >
        <Icon
          fontSize={'14px'}
        />
      </Box>
      <Box ml={'6px'}>
        <If condition={children}>
          <Then>
            {children}
          </Then>
          <Else>
            <Typography variant={!isMobile ? 'phTextXSmallReg': 'phTextSmall'} sx={(theme) => {
              return {color: ALERT_FLOATING_BANNER_VARIANT_CONFIG[variant].color(theme)}
            }}>
              {text}
            </Typography>
          </Else>
        </If>
      </Box>
    </Box>
  </AlertContainerStyled>
}