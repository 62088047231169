import { useMediaQuery, useTheme } from 'ui-commons/ui-kit';

// to be used for mobile
export function useMobileLayout() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
}
export function useBetweenMobileAndTabletLayout() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('xs', 'md'));
}
// to be used for tablet
export function useTabletLayout() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('md', 'lg'));
}

export function useInMobileAndTablet() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('xs', 'md'));
}

export function useSmallDesktop() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('md', 'lg'));
}

export function useSmallBigDesktop() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('md', 'xl'));
}

export function useInMobileAndTabletAndDesktop() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('xs', 'lg'));
}
