import { registerPlugin } from '@capacitor/core';
const Echo = registerPlugin('Echo');
const PaymentGatewayCapacitorPlugin = registerPlugin('PaymentGatewayCapacitorPlugin');
const LeegalitySignPlugin = registerPlugin('LeegalitySignPlugin');
const WebViewPlugin = registerPlugin('WebViewPlugin');

export  {
  Echo,
  PaymentGatewayCapacitorPlugin,
  LeegalitySignPlugin,
  WebViewPlugin
};