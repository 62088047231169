import React from 'react';
import { Snackbar, Alert } from 'ui-commons/ui-kit';

function GlobalSanckBar({
  open,
  close,
  hideDuration = 6000,
  message = '',
  action = null,
  severity,
  ...props
}) {
  const isMobileAndTablet = window.innerWidth < 900;
  return (
    <Snackbar
      open={open}
      autoHideDuration={hideDuration}
      onClose={close}
      action={action}
      {...props}
      anchorOrigin={
        isMobileAndTablet
          ? { vertical: 'top', horizontal: 'center' }
          : { vertical: 'bottom', horizontal: 'left' }
      }
    >
      <Alert onClose={close} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default GlobalSanckBar;
