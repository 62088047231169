import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Card,
  IconButton,
  appTheme,
  ContentCopy,
  ResponsiveButton,
  Close,
  useTheme,
  Button,
  Grid,
} from 'ui-commons/ui-kit';

import { useNavigate } from 'react-router-dom';
import { closeDialog, showSnackBar } from 'global/actions';
import { trackEvent } from '../_deals/actions';
import { events } from '../utils/analytics';
function hideWithExternalChar(text = null, char = '*', visibleLength = 4) {
  if (!text) return text;
  const lengthToHide = text.length > visibleLength ? text.length - visibleLength : text.length;
  const newText = text.split('').map((item, i) => {
    if (i < lengthToHide) {
      return char;
    } else {
      return item;
    }
  });

  return newText;
}

function ItemWithAction({
  text = '',
  onClick = null,
  ctaComponent = null,
  isHidden = false,
  hint,
  ...rest
}) {
  const theme = useTheme();
  const actualDisplayText = isHidden ? hideWithExternalChar(text) : text;
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} {...rest}>
      <Box>
        <Box mb={0.5}>
          <Typography variant={'phTextSmallReg'} color={'text.label'}>
            {hint}
          </Typography>
        </Box>
        <Typography variant={'hlMed'} color={'text.active'}>
          {actualDisplayText}
        </Typography>
      </Box>
      {!!ctaComponent && (
        <Box>
          <IconButton onClick={onClick} edge="start" color="inherit" aria-label="menu">
            {ctaComponent}
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
function PaymentBankTransfer({ onClickSecondaryBtn = () => {}, onClickPrimaryBtn = () => {} }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const details = [
    { hint: 'Account Holder Name', text: 'Purple Petal Invest Pvt Ltd Collection Escrow Account' },
    { hint: 'Bank Account Number', text: '004705017351' },
    { hint: 'IFSC Code', text: 'ICIC0000047' },
    { hint: 'Branch Details', text: 'Bangalore - Koramangala' },
  ];
  const onClickCopy = (value) => {
    if (navigator) {
      showSnackBar({
        message: 'Text copied',
      });
      navigator.clipboard.writeText(value);
    }
  };
  return (
    <Paper>
      <IconButton
        aria-label="close"
        onClick={() => {
          navigate('/dashboard', { replace: true });
          closeDialog();
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.colors.black,
        }}
      >
        <Close />
      </IconButton>
      <Box padding={{ xs: 2, sm: 4, md: 4 }}>
        <Typography variant={'h4'}>Continue Bank Transfer</Typography>
        <Grid container spacing={2} sx={{ marginTop: '-4px' }}>
          <Grid item xs={6}>
            <Card variant={'outlined'} sx={{ padding: 2 }}>
              <Box mb={3} display={'flex'} alignItems={'center'}>
                <Typography variant={'phTextLarge'} display="block">
                  Steps to complete
                </Typography>
              </Box>
              <Box marginBottom={2}>
                <Typography variant={'b4'}>
                  1. Log-In to your Net Banking portal and make the payment to our Bank Account
                  credentials mentioned below.
                </Typography>
              </Box>
              <Box marginBottom={2}>
                <Typography variant={'b4'}>
                  2. Click on ‘Upload Payment Reference’ and share the details of your transaction.
                </Typography>
              </Box>
              <Box marginBottom={2}>
                <Typography variant={'b4'}>
                  3. Our team will validate the payment and send you a confirmation on your
                  investment.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Card variant="outlined" sx={{ padding: 2 }}>
                <Box marginBottom={2}>
                  <Box mb={3} display={'flex'} alignItems={'center'}>
                    <Typography variant={'phTextLarge'} display="block">
                      Bank Details
                    </Typography>
                    <Box ml={1}>
                      <Typography variant={'b4'} color={'text.label'}>
                        (IMPS/NEFT/RTGS)
                      </Typography>
                    </Box>
                  </Box>
                  {details.map((item, i) => (
                    <ItemWithAction
                      hint={item.hint}
                      text={item.text}
                      key={i}
                      isHidden={item?.isHidden}
                      marginTop={i === 0 ? 0 : 2}
                      onClick={() => {
                        trackEvent('Copied ' + item.hint, { Source: 'Dashboard Screen' });
                        onClickCopy(item.text);
                      }}
                      ctaComponent={<ContentCopy />}
                      sx={{
                        borderBottom: '1px solid ' + theme.palette.border.light,
                        paddingBottom: '4px',
                      }}
                    />
                  ))}
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Box marginTop={2}>
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <Button
              analyticsEvent={events.ClickedOnUploadNow}
              analyticsEventProperties={{ source: 'Dashboard screen' }}
              onClick={() => {
                typeof onClickPrimaryBtn === 'function' && onClickPrimaryBtn();
              }}
              size={'medium'}
            >
              Upload Proof
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default PaymentBankTransfer;
