import { Box, Card } from '../../mui';
import { baseColors } from 'ui-commons/ui-kit/configs/theme';

function InfoCard({ icon, children, variant = 'none', sx, sxChildren = {} }) {
  return (
    <Card
      sx={{
        boxShadow: 'none',
        border: `1px solid ${baseColors.slate[200]}`,
        padding: 3,
        borderRadius: '10px',
        ...sx,
      }}
    >
      <Box display={'flex'}>
        {icon ? <div style={{ marginRight: '12px' }}>{icon}</div> : null}
        <Box sx={sxChildren}>{children}</Box>
      </Box>
    </Card>
  );
}

export default InfoCard;
