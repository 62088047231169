import { createElement, useState, Fragment } from 'react';

// TODO make the position of the tab and tab body dynamic
function TabController({ children }) {
  const [active, setActive] = useState(0);
  const onChangeHandler = (e, value) => {
    setActive(value);
  };

  let newChildren = {};
  if (children[0]) {
    newChildren = [
      {
        ...children[0],
        props: {
          ...children[0].props,
          value: active,
          onChange: onChangeHandler,
        },
      },
      {
        ...children[1],
        props: {
          ...children[1].props,
          value: active,
        },
      },
    ];
  }

  return createElement(Fragment, {
    children: newChildren,
  });
}

export default TabController;
