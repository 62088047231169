import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  Slide,
  useScrollTrigger,
  MenuIcon,
  Grid,
  Box,
  Stack,
  IconButton,
  styled,
} from './../../mui';

import { appTheme, appValues } from './../../configs/theme';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const CustomToolBar = styled(Toolbar)(() => {
  return {};
});

const LogoImage = styled('img')(({ theme }) => {
  return {
    width: 80,
  };
});

export default function NavHeader({
  showLogo = false,
  onClickLogo,
  actionItemsLeft = [],
  actionItemsRight = [],
  actionItemsSpacing = 0,
  toolbarStyle = {},
  appBarStyle = {},
  maxWidth = null,
  onClickHamburgerIcon = null,
  shadowOn,
  showPointer = false,
  ...rest
}) {
  const appBarRef = React.useRef(null);
  const lastKnownScrollPosition = React.useRef();
  const ticking = React.useRef();

  //   window.addEventListener('scroll', handler);
  //   return () => {
  //     window.removeEventListener('scroll', handler);
  //   };
  // }, []);

  const hasActionsItemsLeft = actionItemsLeft.length > 0;
  const hasActionsItemsRight = actionItemsRight.length > 0;
  const isUpMd = useMediaQuery(appTheme.breakpoints.up('md'));
  return (
    <React.Fragment>
      {/* Uncomment this to re-enable header being hidden on scroll */}
      <HideOnScroll {...rest}>
        <AppBar
          ref={appBarRef}
          color={'header'}
          sx={{
            minHeight: '56px',
            backgroundColor: '#FBFBF6',
            boxShadow: 'none',
            justifyContent: 'center',
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              // width: `calc(50vw - 50%)`,
              ...toolbarStyle,
            }}
          >
            <Grid
              container
              sx={{
                width: appValues.screenWidth,
                justifyContent: 'space-between',
              }}
            >
              <Grid
                item
                sx={{
                  marginLeft: { xs: 3, md: 0, lg: 0 },
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {showLogo && (
                  <Box onClick={onClickLogo} my={'17px'} mr={8}>
                    <picture>
                      <source type="image/webp" srcSet="/images/tapLogo.webp" />
                      <source type="image/png" srcSet="/images/tapLogo.png" />
                      <LogoImage src="/images/tapLogo.webp" alt="logo" onClick={onClickLogo} />
                    </picture>
                  </Box>
                )}
                <Box display={'flex'} height={'100%'}>
                  {hasActionsItemsLeft && isUpMd && (
                    <Stack spacing={actionItemsSpacing} alignItems={'center'} direction="row">
                      {actionItemsLeft.map((item) => {
                        return <Fragment key={item.text}>{item.component}</Fragment>;
                      })}
                    </Stack>
                  )}
                </Box>
              </Grid>
              <Box display={'flex'} height={'100%'}>
                {hasActionsItemsRight && isUpMd && (
                  <Stack spacing={actionItemsSpacing} alignItems={'center'} direction="row">
                    {actionItemsRight.map((item) => {
                      return <Fragment key={item.text}>{item.component}</Fragment>;
                    })}
                  </Stack>
                )}
              </Box>
            </Grid>
          </Box>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}
