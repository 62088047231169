export const API_URL =
  process.env.REACT_APP_API_BASE_URL ?? 'https://investor-api-stage.tapinvest.in';

export const API_URL_V2 =
  process.env.REACT_APP_API_BASE_URL_2 ?? 'https://kraken-stage.tapinvest.in';

// Put your endpoint here
export const PATH = {
  hello: '/hello',
  login: '/users/login',
  reset: '/users/reset',
  requestReset: '/users/requestReset',
  subscribe: '/subscribe',
  getAllDeals: '/api/deals',
  getProfile: '/my/profile',
  createInvestment: '/api/deals/{{dealId}}/investments',
  getMyInvestmentById: '/my/investments/{{investmentId}}',
  repaymentOfInvestment: '/api/deals/{{investmentId}}/repayment',
  getADeal: '/api/deals/{{dealId}}?financeType={{financeType}}',
  createDocumentContract: '/api/investments/{{investmentId}}/documents',
  downloadInvoice: '/my/investments/invoice/{{investmentId}}',
  rentalInvoice: '/my/rental/invoice/{{investmentId}}',
  mfInvoice: '/my/mf/invoice/{{investmentId}}',
  investmentContract: '/my/investments/contract/{{investmentId}}',
  downloadInvoiceByType: '/my/investments/invoice/{{investmentId}}/{{type}}',
  // kyc
  completeKYC: '/v2/kyc',
  kycStatus: '/v2/user/kyc-status',
  cmrVerifyStatus: '/v2/bonds/cmr/status',
  uploadCmrFile: '/v2/bonds/cmr',
  profile: '/v2/user/profile',
  verifyPAN: '/v2/user/pan/verify',
  savePan: '/v2/user/pan',
  verifyAadhaar: '/v2/user/aadhaar/verify',
  saveAadhaar: '/v2/user/aadhaar',
  getBankDetails: '/v2/user/bank-details',
  saveBankDetails: '/v2/user/bank-details',
  checkIfAccountNumberExists: 'my/accountNumber/check/{{accountNumber}}',
  kycContract: '/v2/contract/fetch/{contractId}',
  kycContractAccept: '/v2/contract/user/accept',

  beneficiaries: '/my/beneficiary',
  completeKYCBankOnly: '/my/kyc/bank',
  uploadPaymentReference: '/api/investments/{{investmentId}}/paymentOrder/externalreference',
  fetchPincodeDetails: '/my/kyc/pincode',
  taxReportDownload: '/my/taxReport',
  idTaxReportDownload: '/my/id_taxReport',

  // vault
  getTransactions: '/v2/wallet/transactions',
  // getOtp: '/v2/wallet/otp/request',
  vaultWithdrawRequest: '/v2/wallet/withdraw-request',
  getAccountDetail: '/v2/wallet/account-detail',

  // Investments
  checkAndBlockInvestment: '/api/deals/{{id}}/canInvest',

  // referral
  referralLeaderBoard: '/v2/referral/leaderboard',

  //Singup
  getOtp: '/users/signup/v1/sendOtp',
  registerInvestorUser: '/users/register/',
  checkUserExist: '/users/check',

  //Business
  getDealsOfLessee: '/api/deals/lessee/deals/{{lessee_id}}',
  getLesseeInfo: '/my/assets/{{id}}',
  signContract: '/api/investments/contractStatus',
  getInvestments: '/api/investments/deal/investments/{{deal_id}}',
  getDealInfo: '/api/deals/status/{{dealId}}',
  getInvoicesOfLessee: '/my/invoices/{{type}}/{{lesseeId}}',
  getContractsOfLessee: '/my/contracts/{{lesseeId}}',
  getRentalInvoices: '/api/investments/rentalInvoices/{{investmentId}}',
  subscribeUpcomingDeal: '/api/subscribe/deal',
  taxReport: '/api/investments/taxreport',
  myAssets: '/my/asset?from={{from}}&to={{to}}',
  downloadDocument: '/my/documents/download',
  getInvestmenstByAssetReq: '/api/investments/asset/investments/{{id}}',
  getRentalByAsset: '/api/investments/asset/rentalInvoices/{{id}}',
  getContractByAsset: '/api/investments/asset/contract/{{id}}',
  getSaleAgreementByAsset: '/api/investments/asset/saleAgreement/{{id}}',
  getDocumentsByAsset: '/api/investments/documents/download/{{id}}',
  analytics: '/v2/analytics/send',
  analyticsWebVital: '/api/analytics/web-vitals',
  getDealsWithFilter: 'api/deals',
  getActiveDealsInfo: '/api/deals/activeDeals/{{lesseeId}}',
  getActiveDealsMonthWise: '/api/deals/status/active/lessee/{{lesseeId}}?date={{date}}',
  getLesseeSummary: '/api/lessee/summary/{{lesseeId}}',
  getInvoices: '/api/lessee/invoices/{{lesseeId}}?to={{to}}&type={{type}}',
  getLiveDealsInfo: '/api/deals/status/live/lessee/{{lesseeId}}',
  getAssetMapping: '/api/lessee/assetMapping/{{lesseeId}}?to={{to}}',
  getAssetMappingByDeal: '/api/deals/dealAssetMapping/{{dealId}}',
  getLesseeById: '/api/lessee/{{lesseeId}}',
  getAssetsInfo: '/api/deals/assets/{{dealId}}',
  getInvestorsByAssetReq: '/api/investments/investors/assetRequest/{{assetReqId}}',
  getInvoicesByAsset: '/api/investments/assetRequest/invoices/{{assetReq}}?type={{type}}',
  getScheduleForLessee: '/api/lessee/rentals/{{lesseeId}}?date={{date}}',
  getWorkingsForLessee: '/api/lessee/workings/{{lesseeId}}?date={{date}}',
  getSignatureManagerByDeal: '/api/documents/signatureManager/deal/{{dealId}}',
  getSignatureManagerByAssetRequest: '/api/documents/signatureManager/assetRequest/{{assetReqId}}',
  getSignStatusBySourceId: '/api/documents/signatureManager/source/{{sourceId}}',
  getGSTTracker: '/api/lessee/gstTracker/{{lesseeId}}?date={{date}}',
  getTDSTracker: '/api/lessee/tdsTracker/{{lesseeId}}?date={{date}}',
  getComplianceData: '/api/lessee/compliance/{{lesseeId}}',
  getInvoicesByIrqIdIn: '/api/lessee/invoices/Irq',
  updateSignStatusBySourceIds:
    '/api/documents/signStatus/sourceIds?ids={{ids}}&lesseeId={{lesseeId}}',
  updateSignStatusByDeal: '/api/documents/signStatus/deal?id={{id}}&lesseeId={{lesseeId}}',
  downloadGenericDocumentsBySourceId:
    '/api/documents/genericDocument/source/{{sourceId}}?type={{type}}',
  getGenericDocumentsOfDealsForMonth:
    '/api/documents/genericDocument/lessee/{{lesseeId}}?date={{date}}&type={{type}}',
  getGenericDocumentsByDeals:
    '/api/documents/genericDocument/deals?dealIds={{dealIds}}&type={{type}}',
  getListViewOfGenericDocumentByDeal:
    '/api/documents/genericDocument/view/deal/{{dealId}}?type={{type}}',
  getSettledPaymentByDealsOfLessee: '/api/lessee/deals/settled/{{lesseeId}}',
  // Assets
  getAllAssets: 'api/deals/{{id}}/assets',
  getAnAssetRequest: 'api/assets/{{id}}',

  getDataforCSV: '/api/investments/csv/{{id}}',
  getRentalDataforCSV: '/my/csv/rentalInvoices/{{id}}',

  downloadRentalInvoices: '/my/rentalInvoices/download',
  submitLesseeOnboaringRequest: '/api/lessee/onboard',
  getLesseeOnboardingInfo: '/api/lessee/all',

  //document section -business
  getInvoicesByAssetReqIds:
    '/api/lessee/assetRequests/invoices?assetReqIds={{assetReqIds}}&from={{from}}&to={{to}}&type={{type}}',
  getInvoiceCSVByAssetReqIds:
    '/api/lessee/assetRequests/invoices/csv?assetReqIds={{assetReqIds}}&from={{from}}&to={{to}}&type={{type}}',
  //csv active deals month wise view
  getInvoiceCSVByLessee: '/api/lessee/{{lesseeId}}/invoices/csv?to={{to}}&type={{type}}',
  getPurchaseInvoiceCSVByLessee: '/api/lessee/{{  lesseeId}}/purchaseInvoices/csv?to={{to}}',

  // Referral
  myReferral: '/my/referral',
  verifyGst: '/api/lessee/verify/gst',
  myInvestments: '/my/investments',
  poolInvestments: '/v2/investments/pool',
  updateDocumentByInvestmentId: '/api/documents/updateDocument/{{investmentId}}',

  // modify re-investment option
  optoutReinvestment: '/v2/payment/reinvestment/optout/{{investmentId}}',
  optinReinvestment: '/v2/reinvestment/opt-in/{{investmentId}}',

  // Is first time investor
  isNewInvestor: '/v2/user/is-invested',
  //V2
  getAllListV2: '/v2/deals',
  canInvestV2: '/v2/deals/{{dealId}}/can-invest?amount={{amount}}',
  canInvestALV2: '/v2/deals/asset-leasing/{{dealId}}/can-invest',
  getInvestmentTerms:
    '/v2/deals/{{dealId}}/investment-terms?amount={{amount}}&financeType={{financeType}}',
  getBondsInvestmentTerms: '/v2/bonds/deals/{{dealId}}/investment-term?qty={{quantity}}',
  createInvestmentV2: '/v2/deals/{{dealId}}/invest', // For Invoice Discounting ONLY
  getDealDetails: '/v2/deals/data/{{dealId}}',
  getDealDetailsV2: '/v2/deals/{{dealId}}?financeType={{financeType}}',
  getBondDealDetails: '/v2/bonds/deals/{{dealId}}',
  getBondsSettlementDate: '/v2/bonds/deals/{{dealId}}/settlement-date',
  getBondsCashflow: '/v2/bonds/deals/{{dealId}}/cashflow?qty={{qty}}',
  getBondsInvestmentDetails: '/v2/investments/bonds',
  createInvestorContract: '/v2/investments/{{investmentId}}/investor-contract',
  downloadInvestorContract: '/v2/investments/{{investmentId}}/download-contract',
  getWalletBalance: '/v2/wallet/balance',
  addMoneyToWallet: '/v2/wallet/recharge?handleRedirection={{}}',
  withdrawalOtp: '/v2/wallet/otp/request?mobileNumber={{number}}&email={{email}}',
  withdrawal: '/v2/wallet/withdraw-request',
  postCheckByInvestmentAndUpdatePGOrder: '/v2/payment/cashfree/{{orderId}}',
  postCheckByInvestmentAndUpdateLendboxPGOrder: '/v2/payment/cashfree/lendbox/{{orderId}}',
  bonds: '/v2/deals/bonds',
  autoWithdraw: '/v2/preferences/auto-withdraw',
  reserveConfig: '/v2/preferences',
  // rm details
  getRmDetails: '/v2/rm',
  // Referral v2
  getReferral: '/v2/referral',
  getNudge: '/v2/referral/insights',
  getInvitees: '/v2/referral/invites',
  getInfoBox: '/v2/referral/info-box/{{path}}',

  // P2P Lending
  getLiquidInvestmentTerms: '/v2/p2p/investment-terms',
  getLiquidNudge: '/v2/p2p/nudge',
  getLiquidInvestments: '/v2/p2p/investments',
  getLiquidInvestmentDetails: '/v2/p2p/investment-details?investmentId={{investmentId}}',
  createLiquidInvestment: '/v2/p2p/invest',
  requestLiquidWithdrawalOtp: '/v2/p2p/withdraw/request-otp',
  submitLiquidWithdrawal: '/v2/p2p/withdraw/verify-otp',
  // Investment Type and ID are not mandatory for Flexi Investments but are needed for tenured investments (3M and 6M)
  getLiquidLedger: '/v2/p2p/ledger',
  getLiquidAadhaarOtp: '/v2/p2p/aadhaar/request-otp',
  submitLiquidAadhaarOtp: '/v2/p2p/aadhaar/verify-otp',
  downloadLedger: '/v2/p2p/ledger/download',
  getTotalCurrentInvestedAmount: '/v2/p2p/current-investment',
  getLendboxKycStatus: '/v2/p2p/kyc-status',

  // legality
  getLegalTerms: '/v2/investments/master-document',

  //education
  education: '/v2/education/user',

  // download Buyback Invoices
  buybackInvoices: '/v2/investments/{{investmentId}}/al-buyback-invoices',

  // clearTax User, Login, OTP, Download Reports
  clearTaxEmailUserCheck: '/cleartax/email/{{email}}',
  clearTaxPhoneUserCheck: '/cleartax/phone/{{phone}}',
  clearTaxEmailOTP: '/cleartax/email/{{email}}/otp',
  clearTaxPhoneOTP: '/cleartax/phone/{{phone}}/otp',
  clearTaxEmailLogin: '/cleartax/login/email',
  clearTaxPhoneLogin: '/cleartax/login/phone',

  isReportAvailable: '/v2/user/taxreport-status',
  downloadTaxReport: '/v2/user/taxreport-download?financeType={{}}',

  getRedirectionURL: '/cleartax/redirect/{{email}}?financialYear={{year}}',

  // investment success
  investmentStatus: '/v2/investments/{{investmentId}}',

  // new login, signup and onboarding, referral
  signupEmailRequestOTP: '/v2/auth/email/otp/request',
  signupEmailVerifyOTP: '/v2/auth/email/otp/verify',

  signupPhoneRequestOTP: '/v2/auth/phone/otp/request',
  signupPhoneVerifyOTP: '/v2/auth/phone/otp/verify',

  signupGoogleAuth: '/v2/auth/google',

  referCodeVerify: '/v2/referral/apply',

  onboardingNameEntry: '/v2/user/profile/update-name',
};
