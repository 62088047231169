import { SwipeableDrawer, Box, styled, Global, Drawer, Backdrop } from 'ui-commons/ui-kit';
import { useMemo } from 'react';

// TODO should transition based on the content inside

const Puller = styled(Box)(({ theme }) => ({
  width: '160px',
  left: '50%',
  transform: 'translateX(-80px)',
  height: '5px',
  backgroundColor: theme.palette.border.light,
  position: 'absolute',
  borderRadius: '24px',
  top: '-10px',
}));

export default function BottomMenu({
  onOpen = () => {},
  onClose = () => {},
  open = false,
  component = null,
  height = null,
  closable = true,
  keepMounted = true,
  ...rest
}) {
  const _component = useMemo(() => {
    return component;
  }, [component]);

  const DrawerWrapper = closable ? SwipeableDrawer : Drawer;

  return (
    <>
      <DrawerWrapper
        anchor="bottom"
        open={open}
        onClose={closable ? onClose : undefined}
        onOpen={onOpen}
        swipeAreaWidth={30}
        disableSwipeToOpen={true}
        sx={{
          zIndex: 12,
        }}
        ModalProps={{
          keepMounted: keepMounted,
        }}
      >
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              maxHeight: '100%',
              overflow: 'visible',
              borderRadius: '16px 16px 0 0',
              zIndex: 3,
            },
          }}
        />
        <Puller />
        <Box
          sx={{
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          {_component}
        </Box>
      </DrawerWrapper>
    </>
  );
}
