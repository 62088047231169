import { CurrencyRupeeRounded, Box } from './../mui';

function RupeeOnRoundedIcon({ fontSize = '16px', color = 'black', fontSizeIcon = '80%' }) {
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        boxSizing: 'border-box',
        width: fontSize,
        height: fontSize,
      }}
    >
      <Box
        sx={{
          width: '83.3%',
          height: '83.3%',
          borderRadius: '50%',
          background: color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CurrencyRupeeRounded style={{ fontSize: fontSizeIcon }} htmlColor={'white'} />
      </Box>
    </span>
  );
}

export default RupeeOnRoundedIcon;
