import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import {
  Box,
  useTheme,
  IconButton,
  KeyboardArrowRightRoundedIcon,
  useMediaQuery,
  KeyboardArrowLeftRoundedIcon,
  ArrowBackIosNewRoundedIcon,
  baseColors,
} from 'ui-commons/ui-kit';
import { When } from 'react-if';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

export function ActionBarWrapper({ children = null, onClickBack = null, responsive = true }) {
  const theme = useTheme();
  let shouldAbsolute;
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  if (responsive) {
    shouldAbsolute = !isSm;
  } else {
    shouldAbsolute = false;
  }

  const hasVisibleChildren = Array.isArray(children) && children.some((i) => !!i === true);
  return (
    <Box display={'flex'} alignItems={'center'}>
      {typeof onClickBack === 'function' && (
        <Box
          sx={{
            position: shouldAbsolute && hasVisibleChildren ? 'absolute' : 'relative',
            zIndex: 2,
          }}
        >
          <IconButton
            onClick={onClickBack}
            sx={{
              position: 'relative',

              left: '-5px',
            }}
          >
            {/* <ArrowBackIosNewRoundedIcon
              sx={{
                fontSize: '20px',
                color: theme.palette.text.active,
              }}
            /> */}
            <KeyboardBackspaceRoundedIcon
              style={{
                color: baseColors.green[700],
                backgroundColor: '#15803D12',
                marginLeft: '-2px',
                padding: '8px',
                borderRadius: '50%',
                width: 24,
                height: 24,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                marginRight: 3,
              }}
            />
          </IconButton>
        </Box>
      )}
      {!!children && (
        <Box
          flex={1}
          sx={{
            position: 'relative',
            left: '-7px',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export function ActionBarBreadcrumbs({ breadCrumbs = [] }) {
  const theme = useTheme();
  const renderCrumbs = () => {
    return breadCrumbs.map((crumb, index, arr) => {
      const text = crumb?.text || '';
      const component = crumb?.component || null;
      const isLast = index === arr.length - 1;
      const hasOnClickHandler = typeof crumb?.onClick === 'function';
      return (
        <Fragment key={index}>
          <When condition={component}>{component}</When>
          <When condition={text}>
            <Typography
              variant={'h5'}
              onClick={hasOnClickHandler ? crumb?.onClick : null}
              sx={{
                cursor: hasOnClickHandler ? 'pointer' : 'auto',
              }}
              color={
                isLast && index !== 0
                  ? theme.palette.colors.secondaryGreenText
                  : !isLast
                  ? theme.palette.colors.gray.gray5
                  : theme.palette.colors.gray.gray1
              }
            >
              {text}
            </Typography>
          </When>
          {!isLast && (
            <Box display={'flex'} alignItems={'center'} marginRight={'4px'} marginLeft={'4px'}>
              <KeyboardArrowRightRoundedIcon style={{ color: theme.palette.colors.gray.gray1 }} />
            </Box>
          )}
        </Fragment>
      );
    });
  };
  return <>{renderCrumbs()}</>;
}

function ActionBar({
  onBack = () => {},
  crumbs = [],
  children = null,
  bodyContainerStyle = {},
  crumbsStyle = {},
  variant = 'center',
}) {
  const isResponsive = variant === 'center';
  return (
    <ActionBarWrapper onClickBack={onBack} responsive={isResponsive}>
      {crumbs.length > 0 && (
        <Box
          display={'flex'}
          justifyContent={isResponsive ? { xs: 'inherit', sm: 'center' } : 'initial'}
        >
          <Box
            alignItems={'center'}
            display={'flex'}
            sx={{
              ...bodyContainerStyle,
            }}
          >
            <Box display={'flex'} alignItems={'center'} sx={{ ...crumbsStyle }}>
              <ActionBarBreadcrumbs breadCrumbs={crumbs} />
            </Box>
          </Box>
        </Box>
      )}
      {!!children && children}
    </ActionBarWrapper>
  );
}

export default ActionBar;
