import { Box, Close, IconButton } from '../../mui';
import React from 'react';

export default function ModalBase({ children, ...rest }) {
  return (
    <Box
      maxWidth={'460px'}
      width={{ xs: 'auto', sm: '460px' }}
      padding={{ xs: 3, md: '32px' }}
      borderRadius={{ xs: '15px', md: '25px' }}
      backgroundColor={'white'}
      onClick={(e) => {
        e.stopPropagation();
      }}
      position={'relative'}
      {...rest}
    >
      {!!rest?.onClose && (
        <Box position={'relative'}>
          <IconButton
            aria-label="close"
            onClick={() => {
              typeof rest.onClose === 'function' && rest.onClose();
            }}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: (theme) => theme.palette.colors.black,
            }}
          >
            <Close />
          </IconButton>
        </Box>
      )}
      {children}
    </Box>
  );
}
