// A simple component to show list
import { Grid } from '../../mui';
import React, { useEffect, useMemo, useRef } from 'react';

function FlatList({
  data = [],
  renderItems,
  onEndReached,
  endReachingThreshold = 1,
  rootMargin = '0px',
  endReachedComponent = null,
  additionalDataProps={},
  // Change the id to render it
  renderId = '',
}) {
  const lastElementRef = useRef();
  const isOnEndReachedPresent = typeof onEndReached === 'function';

  useEffect(() => {
    if (isOnEndReachedPresent) {
      const lastElementObserver = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            onEndReached();
          }
        },
        {
          threshold: endReachingThreshold,
          rootMargin,
        },
      );
      lastElementObserver.observe(lastElementRef.current);
    }
  }, [isOnEndReachedPresent]);

  const mapDataToJsx = useMemo(() => {
    return data.map((item, index, array) => {

      return renderItems(item, index, additionalDataProps, array);
    });
  }, [data.length, renderId, additionalDataProps]);

  return (
    <Grid container item xs={12} spacing={2}>
      {mapDataToJsx}
      {isOnEndReachedPresent && (
        <Grid item xs={12}>
          <div ref={lastElementRef}>{endReachedComponent}</div>
        </Grid>
      )}
    </Grid>
  );
}
export default FlatList;
