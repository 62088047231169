const addToObjectAt = (obj, k, v) => {
  obj[k] = obj[k] ? obj[k] + v : v;
  return obj;
};
// assetRequests => Shape => {id: number, buyPrice: number, saleValue: number, tenure: number, leaseEndDate: date}
// qtySelection => Shape => {qty: number, assetRequestId: arqId}

let DEFAULT_TDS_VALUE_FOR_INVOICE_DISCOUNTING = 0;
let DEFAULT_TDS_VALUE_FOR_ASSET_LEASING = 10;

export const getAllSchedulesStatsInvoiceDiscounting = (
  assetRequests,
  qtySelection,
  firstPayoutDate,
  leaseEndDateOfDeal,
  applyTdsOnAmount = false,
  applyTdsOnSaleValue = true,
) => {
  const arqMap = assetRequests.reduce(
    (acc, arq) => ({
      ...acc,
      [arq.id]: arq,
    }),
    {},
  );
  const combinedTxMap = {};
  const combinedTransactionFeeMap = {};
  const combinedRentalMap = {};
  const combinedTDSonRentalMap = {};
  const combinedTxMapWithoutGST = {};
  let leaseEndDate = new Date();
  let amountToPay = 0;
  let totalReturns = 0;
  let tdsOnSaleValue = 0;
  let totalTransactionFee = 0;
  let relativeTenure = 0;
  let isTDSValueZero = true;

  // Current date
  const rawInvestmentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const investmentDate = new Date(rawInvestmentDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));


  qtySelection
    .filter((q) => q.qty !== 0)
    .forEach((qty) => {
      const arq = arqMap[qty.assetRequestId];
      const arqLeaseDateObj = new Date(arq.leaseEndDate || leaseEndDateOfDeal);
      if (arqLeaseDateObj > leaseEndDate) {
        leaseEndDate = arqLeaseDateObj;
      }

      relativeTenure =
        (new Date(arqLeaseDateObj).getTime() - investmentDate.getTime()) / (1000 * 60 * 60 * 24);
      addToObjectAt(combinedTxMap, investmentDate.toDateString(), -(arq.buyPrice * qty.qty));
      addToObjectAt(combinedTxMapWithoutGST, investmentDate.toDateString(), -(arq.buyPrice * qty.qty));
      amountToPay += arq.buyPrice * qty.qty;

      if (applyTdsOnSaleValue) {
        const tdsToApply = (arq?.finalIndicativeTerm?.tds || DEFAULT_TDS_VALUE_FOR_INVOICE_DISCOUNTING) / 100;
        if (tdsOnSaleValue === 0) {
          // INVOICE_DISCOUNTING
          if(tdsToApply !== 0) {
            isTDSValueZero = false
          }
          tdsOnSaleValue = tdsToApply;
          addToObjectAt(
            combinedTDSonRentalMap,
            arqLeaseDateObj.toDateString(),
            (arq.saleValueForLessee * qty.qty - amountToPay) * tdsOnSaleValue,
          );
        }
      }
      let saleValueWithoutManagementFee =
        arq.saleValue / (1 - arq?.finalIndicativeTerm?.managementFee / 100);

      let transactionFee = (saleValueWithoutManagementFee - arq.saleValue) * qty.qty;
      totalTransactionFee += transactionFee;
      addToObjectAt(combinedTxMap, arqLeaseDateObj.toDateString(), arq.saleValue * qty.qty);
      addToObjectAt(combinedTxMapWithoutGST, arqLeaseDateObj.toDateString(), arq.saleValueForLessee * qty.qty)
      addToObjectAt(combinedTransactionFeeMap, arqLeaseDateObj.toDateString(), transactionFee);
      // INVOICE_DISCOUNTING
      totalReturns += arq.saleValue * qty.qty;
    });
  const transactions = Object.keys(combinedTxMap)
    .sort()
    .map((k) => ({
      date: new Date(k),
      // Excluding management fee
      // Applying gts
      amount: combinedTxMap[k],
      amountWithoutGst: combinedTxMapWithoutGST[k],
      rentalAmount: combinedRentalMap[k] || 0,
      tdsAmount: combinedTDSonRentalMap[k] || 0,
      transactionFee: combinedTransactionFeeMap[k] || 0,
    }))
    .sort((a, b) => a.date - b.date)
    .filter((trx) => {
      return trx.amount !== 0;
    });

  return {
    amounts: transactions,
    // Applying gts
    transactionFee: totalTransactionFee * (1.18),
    leaseEndDate,
    amountToPay,
    relativeTenure,
    totalReturns,
    isTDSValueZero,
  };
};

// ASSET LEASING
export const getAllSchedulesStats = (
  assetRequests,
  qtySelection,
  firstPayoutDate,
  leaseEndDateOfDeal,
  applyTdsOnAmount = false,
  applyTdsOnSaleValue = false,
) => {
  const arqMap = assetRequests.reduce(
    (acc, arq) => ({
      ...acc,
      [arq.id]: arq,
    }),
    {},
  );
  const combinedTxMap = {};
  const combinedRentalMap = {};
  const combinedTDSonRentalMap = {};
  let leaseEndDate = new Date();
  let amountToPay = 0;
  let totalReturns = 0;
  let tdsOnSaleValue = 0;
  const now = new Date();
  let isTDSValueZero = true;
  // Hack to set investment on last date of month
  // Setting month to next month and setting date 0 shifts date to last day of current month
  const investmentDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  qtySelection
    .filter((q) => q.qty !== 0)
    .forEach((qty) => {
      const arq = arqMap[qty.assetRequestId];
      const arqLeaseDateObj = new Date(arq.leaseEndDate || leaseEndDateOfDeal);
      if (arqLeaseDateObj > leaseEndDate) {
        leaseEndDate = arqLeaseDateObj;
      }
      addToObjectAt(combinedTxMap, investmentDate.toDateString(), -(arq.buyPrice * qty.qty));
      amountToPay += arq.buyPrice * qty.qty;

      [...Array(arq.tenure)].forEach((v, index) => {
        let d = new Date(firstPayoutDate);
        d.setMonth(d.getMonth() + index);
        addToObjectAt(combinedTxMap, d.toDateString(), arq.payoutAmount * qty.qty);
        addToObjectAt(combinedRentalMap, d.toDateString(), arq.rentalAmount * qty.qty);
        const tdsToApply = (arq?.finalIndicativeTerm?.tds || DEFAULT_TDS_VALUE_FOR_ASSET_LEASING) / 100;

        if (applyTdsOnAmount) {
          if(tdsToApply !== 0) {
            isTDSValueZero = false
          }
          addToObjectAt(
            combinedTDSonRentalMap,
            d.toDateString(),
            arq.rentalAmount * qty.qty * tdsToApply,
          );
        }

        if (applyTdsOnSaleValue) {
          if (tdsOnSaleValue === 0) {
            // INVOICE_DISCOUNTING
            tdsOnSaleValue = tdsToApply;
          }
        }
        totalReturns += arq.payoutAmount * qty.qty;
      });
      if (applyTdsOnSaleValue) {
        // INVOICE_DISCOUNTING
        addToObjectAt(
          combinedTDSonRentalMap,
          arqLeaseDateObj.toDateString(),
          (arq.saleValue * qty.qty - amountToPay) * tdsOnSaleValue,
        );
      }
      addToObjectAt(combinedTxMap, arqLeaseDateObj.toDateString(), arq.saleValue * qty.qty);
      // INVOICE_DISCOUNTING
      totalReturns += arq.saleValue * qty.qty;
    });

  const transactions = Object.keys(combinedTxMap)
    .sort()
    .map((k) => ({
      date: new Date(k),
      amount: combinedTxMap[k],
      amountWithoutGst: [],
      rentalAmount: combinedRentalMap[k] || 0,
      tdsAmount: combinedTDSonRentalMap[k] || 0,
    }))
    .sort((a, b) => a.date - b.date)
    .filter((trx) => {
      return trx.amount !== 0;
    });

  return {
    amounts: transactions,
    leaseEndDate,
    amountToPay,
    totalReturns,
    transactionFee: 0,
    relativeTenure: null,
    isTDSValueZero,
  };
};
