import React from 'react';
import MaterialTooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useMediaQuery, useTheme, styled } from '../../mui';

const MaterialTooltipStyled = styled(MaterialTooltip)(() => {
  return {
    '&.MuiTooltip-tooltip': {
      width: '330px',
    },
  };
});
function Tooltip({
  title = null,
  children = null,
  arrow = false,
  componentsProps = false,
  placement = 'bottom',
}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const respondToHover = useMediaQuery(theme.breakpoints.up('md'));
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (respondToHover) {
    return (
      <MaterialTooltipStyled
        PopperProps={{
          disablePortal: true,
        }}
        arrow={arrow}
        onClose={handleTooltipOpen}
        title={title}
        componentsProps={componentsProps}
        placement={placement}
      >
        <span onClick={handleTooltipOpen}>{children}</span>
      </MaterialTooltipStyled>
    );
  }
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MaterialTooltipStyled
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipOpen}
        open={open}
        title={title}
      >
        <span onClick={handleTooltipOpen}>{children}</span>
      </MaterialTooltipStyled>
    </ClickAwayListener>
  );
}

export default Tooltip;
