import React from 'react';
import { styled } from './mui';

/**
 * Image component that can be used either with a single image source or multiple image sources.
 * @param {Object[]} [srcSet=[]] - An array of image sources represented as objects. Each object has the following properties:
 *   - src: A string that represents the source
 *   - type: A string that represents the image type, such as 'image/webp'.
 * @param {React.ComponentType} [overrideNativeComponent=null] - An optional component to render the image instead of the default 'img' component.
 * @param {Object} [sx] - Style object to be passed to the component for styling using styled-components.
 * @param {...any} rest - Any other props to be passed to the component, such as 'alt', 'src', etc.
 */
export default function Image({ srcSet = [], overrideNativeComponent = null, sx, ...rest }) {
  const Img = overrideNativeComponent || 'img';
  if (srcSet.length === 0) {
    return <Img {...rest} sx={sx} />;
  } else {
    return (
      <picture>
        {srcSet.map((img, index) => {
          return <source key={index} sx={sx} type={img.type} srcSet={img.src} />;
        })}
        <Img {...rest} sx={sx} />
      </picture>
    );
  }
}

export const StatusImages = {
  success: (
    <Image
      style={{
        width: '108px',
      }}
      src={'/images/tick.webp'}
      srcSet={[
        {
          src: '/images/tick.webp',
        },
        {
          src: '/images/tick.png',
        },
      ]}
    />
  ),
  warning: (
    <Image
      style={{
        width: '108px',
      }}
      src={'/images/warning.webp'}
      srcSet={[
        {
          src: '/images/warning.webp',
        },
        {
          src: '/images/warning.png',
        },
      ]}
    />
  ),
  failed: (
    <Image
      style={{
        width: '108px',
        backgroundColor:'lightgray',
        borderRadius: '100px'
      }}
      src={'/images/failed.png'}

      srcSet={[
        {
          src: '/images/failed.png',
        },
      ]}
    />
  )
};
