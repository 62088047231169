import React from 'react';
import { Box, Currency, IconButton, InfoIcon, Tooltip, Typography, useTheme } from 'ui-commons/ui-kit';

import { Grid, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { getFormattedDate, toRoundDown } from 'utils';
import { When } from 'react-if';
import {
  arePayoutsTDSApplicable,
  getTheCurrentFY, getTheFY,
  isOfCurrentFY,
  splitByFinancialYear,
} from '../../utils/investmentUtils';
import { useMobileLayout } from '../../hooks/layout';

function PaymentSchedule({
  tenure,
  payoutAmount,
  selectedQty,
  payoutDate,
  leaseEndDate,
  saleValue,
  flatTDS,
}) {
  const amounts = [...Array(tenure)].map((v, index) => {
    let d = new Date(payoutDate);
    d.setMonth(d.getMonth() + index);
    return {
      date: d,
      amount: payoutAmount * selectedQty,
    };
  });
  amounts.push({
    date: new Date(leaseEndDate),
    amount: saleValue * selectedQty,
  });

  return <PaymentScheduleUneven schedules={amounts} flatTDS={flatTDS} />;
}

export const PaymentScheduleUneven = ({ schedules, flatTDS, totalReturn = 0, financeType = '' }) => {
  const applyTDS = true
  const isMobile = useMobileLayout();
  const theme = useTheme();
  let totalNetReturn = 0;
  let splittedSchedules = {}
  let fyYear = ''

  let showCurrentFY = true;

  if(financeType === "INVOICE_DISCOUNTING"){
    showCurrentFY = false;
  }

  if(schedules && schedules.length > 0){
    splittedSchedules = splitByFinancialYear(schedules);

    let splittedSchedulesArray = Object.values(splittedSchedules);
    if(splittedSchedulesArray.length > 0){
      totalNetReturn = Object.values(splittedSchedules)[0].reduce((acc, curr, index) => {
        if(index === 0){
          fyYear = getTheFY(curr.date)
        }
        if(financeType !== "INVOICE_DISCOUNTING"){
          return acc + curr.amount
        }
        else if( curr.rentalAmount){
          return acc + curr.amount
        }

        return acc;
      }, 0);
    }

  }




  return (
    <Box
      flex={1}
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{
        height: '100%',
        overflowY: isMobile ? 'scroll' : 'auto',
      }}
    >
      <When condition={showCurrentFY}>
      <Box
        px={'16px'}
        py={'6px'}
        mb={'10px'}
        mt={'10px'}
        borderRadius={'5px'}
        border={'1px solid ' + theme.palette.border.green}
        bgcolor={theme.palette.surface.light.green1}
        textAlign={'center'}
      >
        <Typography
          variant='phTextSmallReg'
          color={'text.label'}
        >
          Total Returns for FY {fyYear}&nbsp;
          <Typography
            variant='phTextSmall'
            color={'text.active'}
          >
            <Currency value={totalNetReturn} decimalScale={0}/>
          </Typography>
        </Typography>
      </Box>
      </When>
      <Table>
        <TableHead
          sx={{
            background: '#F6F7FB',
            color: '#4F4F4F',
          }}
        >
          <TableCell
            sx={{
              paddingLeft: '20px',
              width: {
                xs: `${!applyTDS ? 'calc(100%/8)' : '5%'}`,
                sm: `${!applyTDS ? 'calc(100%/8)' : '5%'}`,
              },
            }}
          >
            #
          </TableCell>
          <TableCell
            sx={{
              width: {
                xs: `${!applyTDS ? 'calc(100%/2.5)' : '20%'}`,
                sm: `${!applyTDS ? 'calc(100%/2)' : '20%'}`,
              },
            }}
          >
            Tentative Date
          </TableCell>
          <TableCell
            sx={{
              width: {
                xs: `${!applyTDS ? 'calc(100%/3.33)' : '20%'}`,
                sm: `${!applyTDS ? '300px' : '20%'}`,
              },
            }}
          >
            Payout Amount
          </TableCell>
          <When condition={applyTDS}>
            <TableCell sx={{ width: { xs: '20%', sm: '20%' } }}>
              TDS
              <Tooltip title="TDS certificates will be issued for below mentioned TDS deductions on a quarterly basis">
                <IconButton
                  style={{
                    padding: 0,
                    marginLeft: 2,
                    top: 0,
                  }}
                >
                  <InfoIcon fontSize={'small'} />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell sx={{ width: { xs: '20%', sm: '20%' } }}>
              Payout Amount ( Post TDS )
            </TableCell>
          </When>
        </TableHead>
        {schedules.map((value, index) => (
          <TableRow
            key={+value.date}
            sx={{
              color: '#000000',
            }}
          >
            <TableCell sx={{ paddingLeft: '20px' }}>{index + 1}</TableCell>
            <TableCell>{getFormattedDate(value.date)}</TableCell>
            <TableCell>
              <Currency decimalScale={0} value={toRoundDown(value.amount) || '0'} />
            </TableCell>
            <When condition={applyTDS}>
              <TableCell>
                <Currency decimalScale={0} value={toRoundDown(value?.tdsAmount) || '0'} />
              </TableCell>
              <TableCell>
                <Currency
                  decimalScale={0}
                  value={toRoundDown(value.amount - Math.floor(value?.tdsAmount)) || '0'}
                />
              </TableCell>
            </When>
          </TableRow>
        ))}
      </Table>
    </Box>
  );
};

export default PaymentSchedule;
