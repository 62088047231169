import React from 'react';
import { Else, If, Then } from 'react-if';
import { Box } from '../../mui';

function Loading({ condition = true, fallbackComponent = <Box></Box>, children }) {
  return (
    <If condition={condition}>
      <Then>
        <Box>{fallbackComponent}</Box>
      </Then>
      <Else>{children}</Else>
    </If>
  );
}

export default Loading;
