import { Box, Card, useTheme } from '../../mui';
import React from 'react';
import get from 'lodash/get';

function GradientCard({
  gradient = null,
  header = null,
  headerStyle = {},
  bodyStyle = {},
  footer = null,
  footerStyle = {},
  children = null,
  sx = {},
  ...rest
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        boxShadow: theme.palette.colors.shadow.elevation1,
        overflow: 'hidden',
        ...sx,
      }}
      {...rest}
    >
      <Box
        sx={{
          background: get(theme.palette.colors.gradient, gradient, null),
          padding: { xs: '12px 16px', md: '12px 16px' },
          ...headerStyle,
        }}
      >
        {header}
      </Box>

      <Box
        sx={{
          padding: 2,
          ...bodyStyle,
        }}
      >
        {children}
      </Box>
      {!!footer && <Box>{footer}</Box>}
    </Box>
  );
}

export default GradientCard;
