import { baseColors } from 'ui-commons/ui-kit/configs/theme';
import { Box, WarningAmberRoundedIcon, CheckCircleIcon, styled } from '../../mui';
import Typography from './../Typography';
import React from 'react';
import { Else, If, Then } from 'react-if';

// Keep adding new variants here
const ALERT_BANNER_VARIANT_CONFIG = {
  warning: {
    icon: WarningAmberRoundedIcon,
    color: (theme) => theme.palette.text.warning,
    backgroundColor: (theme) => theme.palette.surface.light.warning,
  },
  warningV2: {
    icon: WarningAmberRoundedIcon,
    color: (theme) => theme.palette.text.warningV2,
    backgroundColor: (theme) => theme.palette.surface.light.warningV2,
  },
  success: {
    icon: CheckCircleIcon,
    color: (theme) => theme.palette.text.action,
    backgroundColor: (theme) => theme.palette.surface.light.green1,
  },
};

const AlertContainerStyled = styled(Box)(({ variant = 'success', theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ALERT_BANNER_VARIANT_CONFIG[variant].backgroundColor(theme),
    paddingTop: '6px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '6px',
    borderRadius: '5px',
  };
});

export default function AlertBanner({ variant = 'success', children, text = '' }) {
  const Icon = ALERT_BANNER_VARIANT_CONFIG[variant].icon;
  return (
    <AlertContainerStyled variant={variant}>
      <Box display={'flex'} alignItems={'center'}>
        <Box
          color={(theme) => ALERT_BANNER_VARIANT_CONFIG[variant].color(theme)}
          display={'flex'}
          alignItems={'center'}
        >
          <Icon fontSize={'14px'} />
        </Box>
        <Box ml={'6px'}>
          <If condition={children}>
            <Then>{children}</Then>
            <Else>
              <Typography
                variant={'phTextXSmallReg'}
                sx={(theme) => {
                  return { color: ALERT_BANNER_VARIANT_CONFIG[variant].color(theme) };
                }}
              >
                {text}
              </Typography>
            </Else>
          </If>
        </Box>
      </Box>
    </AlertContainerStyled>
  );
}
