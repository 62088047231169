import { Typography } from '@mui/material';
import React from 'react';
import { Box, Button, ModalBase, Image, StatusImages } from 'ui-commons/ui-kit';

const STATUS_IMAGE_COMP = StatusImages;

export default function StatusModal({
  status = 'success',
  onClickPrimary = null,
  onClose = null,
  primaryButtonText = '',
  title = '',
  subTitle = '',
  textAlignment = 'left',
}) {
  const showPrimaryButton = typeof onClickPrimary === 'function' && primaryButtonText;
  return (
    <ModalBase onClose={onClose}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        mt={2}
      >
        <Box>{STATUS_IMAGE_COMP[status]}</Box>
        <Box mt={4} textAlign={textAlignment}>
          <Typography variant={'b2'}>{title}</Typography>
        </Box>
        {!!subTitle && (
          <Box mt={2} textAlign={textAlignment}>
            <Typography variant={'b3'}>{subTitle}</Typography>
          </Box>
        )}
        {!!showPrimaryButton && (
          <Box mt={3}>
            <Button variant={'SECONDARY'} size={'medium'} onClick={onClickPrimary}>
              {primaryButtonText}
            </Button>
          </Box>
        )}
      </Box>
    </ModalBase>
  );
}
