import React from 'react';
import { ActionLayout1, Box, Close, IconButton } from 'ui-commons/ui-kit';
import { closeBottomSheet, openBottomMenu, openBottomSheet, openDialog } from 'global/actions';
import PaymentOptionDialog from '../PaymentOptions';
import PaymentBankTransfer from '_payments/PaymentBankTransfer';
import TaxApplicable from '_payments/components/TaxApplicable';
import PaymentSchedule, { PaymentScheduleUneven } from '_payments/components/PaymentSchedule';
import PaymentUploadReference from '_payments/PaymentUploadReference';
import { NetworkManager } from 'managers';
import TaxApplicableInvoiceDiscounting from '../components/TaxApplicableInvoiceDiscounting';
import { trackEvent } from '../../_deals/actions';
import { events } from '../../utils/analytics';

/* @deprecated */
export function initialtePayment({ onClickPay }) {
  openDialog({
    width: 'sm',
    component: <PaymentOptionDialog onClickPay={onClickPay} />,
  });
}

export function openBankTranfer({ ...rest }) {
  openDialog({
    component: <PaymentBankTransfer {...rest} />,
    disableInteraction: true,
    fullScreen: rest?.fullScreen,
  });
}

export function openPaymentUploadReference(
  investmentId,
  onClose = null,
  onSuccess = null,
  showBottomSheet = false,
  investmentCreationCallback = null,
  financeType = '',
) {
  if (showBottomSheet) {
    openBottomMenu({
      disableInteraction: true,
      keepMounted: true,
      component: (
        <PaymentUploadReference
          investmentId={investmentId}
          onSuccess={() => {
            onSuccess();
          }}
          investmentCreationCallback={investmentCreationCallback}
          financeType={financeType}
        />
      ),
    });
    return;
  }
  openDialog({
    disableInteraction: true,
    noDialogContent: true,
    component: (
      <Box
        width={'425px'}
        position={'relative'}
        sx={{
          backgroundColor: 'white',
          borderRadius: '25px',
        }}
      >
        <PaymentUploadReference
          onClose={onClose}
          investmentId={investmentId}
          onSuccess={onSuccess}
          investmentCreationCallback={investmentCreationCallback}
          financeType={financeType}
        />
      </Box>
    ),
  });
}

export function uploadExternalPaymentReference(investmentId, data) {
  let formData = new FormData();
  if (data.file) {
    formData.append('file', data.file);
  }
  formData.append('txId', data.txId);
  return NetworkManager.Call({
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    path: NetworkManager.PATH.uploadPaymentReference.replace('{{investmentId}}', investmentId),
    data: formData,
  });
}

export function openTaxApplicable({ onClose, fullScreen = false }) {
  openDialog({
    disableInteraction: true,
    fullScreen,
    component: (
      <Box
        onClick={() => {
          trackEvent(events.taxNotePopupViewed, { source: 'Investment overview screen' });
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            typeof onClose === 'function' && onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.colors.black,
          }}
        >
          <Close />
        </IconButton>
        <TaxApplicable />
      </Box>
    ),
  });
}

// INVOICE_DISCOUNTING
export function openTaxApplicableInvoiceDiscounting({ onClose, fullScreen = false }) {
  openDialog({
    disableInteraction: true,
    fullScreen,
    component: (
      <Box>
        <IconButton
          aria-label="close"
          onClick={() => {
            typeof onClose === 'function' && onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.colors.black,
          }}
        >
          <Close />
        </IconButton>
        <TaxApplicableInvoiceDiscounting />
      </Box>
    ),
  });
}

export function openPaymentSchedule(
  { tenure, payoutAmount, selectedQty, payoutDate, leaseEndDate, salevalue, rentalAmount, flatTDS },
  { onClose, fullScreen = false },
) {
  openDialog({
    disableInteraction: true,
    fullScreen,
    component: (
      <Box display={'flex'} width={{ md: '720px', sm: '560px' }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            typeof onClose === 'function' && onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.colors.black,
          }}
        >
          <Close />
        </IconButton>
        <PaymentSchedule
          tenure={tenure}
          payoutAmount={payoutAmount}
          selectedQty={selectedQty}
          payoutDate={payoutDate}
          saleValue={salevalue}
          flatTDS={flatTDS}
          rentalAmount={rentalAmount}
          leaseEndDate={leaseEndDate}
        />
      </Box>
    ),
  });
}

export function openUnevenPaymentSchedule(
  { schedules, flatTDS, financeType = '' },
  { onClose, fullScreen = false, isDialog = false, confirmBtnText = '', onConfirm = null },
) {
  if (isDialog) {
    openDialog({
      noDialogContent: true,
      component: (
        <Box
          sx={{
            background: 'white',
            borderRadius: '25px',
          }}
        >
          <ActionLayout1 headerText={'Payout Schedule'}>
            <Box
              width={'max-content'}
              maxHeight={'500px'}
              paddingLeft={2}
              paddingRight={2}
              sx={{
                overflowY: 'scroll',
              }}
            >
              <PaymentScheduleUneven
                schedules={schedules}
                flatTDS={flatTDS}
                financeType={financeType}
              />
            </Box>
          </ActionLayout1>
        </Box>
      ),
    });
  } else {
    openBottomMenu(
      {
        component: (
          <ActionLayout1
            headerText={'Payout Schedule'}
            onConfirm={onConfirm || onClose}
            confirmButtonRightArrow={false}
            confirmButtonText={confirmBtnText || 'Close'}
          >
            <Box height={'440px'}>
              <PaymentScheduleUneven
                schedules={schedules}
                flatTDS={flatTDS}
                financeType={financeType}
              />
            </Box>
          </ActionLayout1>
        ),
      },
      { slot: 3 },
    );
  }

  // openDialog({
  //   disableInteraction: true,
  //   fullScreen,
  //   component: (
  //     <Box display={'flex'} width={{ md: '720px', sm: '560px' }}>
  //       <IconButton
  //         aria-label="close"
  //         onClick={() => {
  //           typeof onClose === 'function' && onClose();
  //         }}
  //         sx={{
  //           position: 'absolute',
  //           right: 8,
  //           top: 8,
  //           color: (theme) => theme.palette.colors.black,
  //         }}
  //       >
  //         <Close />
  //       </IconButton>
  //       <PaymentScheduleUneven schedules={schedules} flatTDS={flatTDS} />
  //     </Box>
  //   ),
  // });
}

export function uploadExternalPaymentReferenceV2(data) {
  let formData = new FormData();
  if (data.file) {
    formData.append('file', data.file);
  }
  formData.append('txId', data.txId);
  formData.append('investmentId', data.investmentId);
  formData.append('financeType', data.financeType);

  return;
  return NetworkManager.Call({
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    path: NetworkManager.PATH.uploadPaymentReferenceV2,
    data: formData,
  });
}
