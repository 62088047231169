import { getDateFromSQLDate } from './index';

export const isInvestmentSuccesful = (i) => i.investmentStatus === 'SUCCESS';

export const isInvestmentDocumentPending = (i) => i.investmentStatus === 'DOCUMENT_SIGN_PENDING';
export const isInvestmentInvoiceDiscounting = (i) =>
  i.deal.financeType === 'INVOICE_DISCOUNTING' || i.poolName;

export const isInvestmentPaymentPendingUPI = (i) =>
  i.investmentStatus === 'PAYMENT_PENDING' || i.investmentStatus === 'PAYMENT_FAILED';

export const isNCDInvestment = (i) => i?.investedDealType === 'NON_CONVERTIBLE_DEBENTURES';
export const isSecondaryNCDInvestment = (i) =>
  i?.investedDealType === 'SECONDARY_NON_CONVERTIBLE_DEBENTURES';
export const isNCDorSecondaryNCDInvestment = (i) =>
  isNCDInvestment(i) || isSecondaryNCDInvestment(i);

export const isInvestmentPaymentPending = (i) =>
  isInvestmentDocumentSignPending(i) ||
  isInvestmentPaymentPendingUPI(i) ||
  isInvestmentProofUploadPending(i) ||
  isInvestmentVerificationPending(i);

export const isInvestmentOnlyPaymentPending = (i) =>
  isInvestmentPaymentPendingUPI(i) || isInvestmentProofUploadPending(i);

export const isInvestmentProofUploadPending = (i) =>
  i.investmentStatus === 'PAYMENT_OFFLINE_PENDING';

export const isInvestmentDocumentSignPending = (i) =>
  i.investmentStatus === 'DOCUMENT_SIGN_PENDING';

export const isInvestmentVerificationPending = (i) =>
  i.investmentStatus === 'PAYMENT_VERIFICATION_PENDING';

export const getScheduleDateFromSchedule = (schedule) => {
  return getDateFromSQLDate(schedule.disbursementCalendardate);
};

export const isInvestmentEligibleForFlatTDS = (deal, totalRental) =>
  deal?.flatTDS || totalRental > 20000;

export const splitByFinancialYear = (list) => {
  const fyBreakDown = {};

  list?.forEach((listObj) => {
    const d = listObj.date;
    const fy = d.getMonth() > 2 ? d.getFullYear() : d.getFullYear() - 1;
    fyBreakDown[fy] = fyBreakDown[fy] || [];
    fyBreakDown[fy].push(listObj);
  });

  return fyBreakDown;
};

export const arePayoutsTDSApplicable = (payouts) => {
  const fyBreakdown = splitByFinancialYear(payouts);
  let isTDSApplicable = false;
  Object.keys(fyBreakdown).some((fy) => {
    const rentalForFY = fyBreakdown[fy].reduce(
      (rental, schedule) => rental + schedule.rentalAmount,
      0,
    );
    if (rentalForFY / 12 > 20000) {
      isTDSApplicable = true;
      return true;
    }
    return false;
  });

  return isTDSApplicable;
};

export const monthsLeftinFY = () => {
  const date = new Date();
  const currentMonth = date.getMonth() + 1; // months start with 0
  if (currentMonth > 3) {
    return 12 - currentMonth + 3; // return months left in this year + 3
  }
  return 3 - currentMonth;
};

export const sortAssetleasingDeals = (deals) => {
  const soldOutDeals = deals.filter((d) => d.isSoldOut);
  const openDeals = deals.filter((d) => !d.isSoldOut);
  const sortedByClosingDate = openDeals.sort((a, b) => {
    const aDate = new Date(a.leaseStartDate);
    const bDate = new Date(b.leaseStartDate);
    return aDate - bDate;
  });
  return [...sortedByClosingDate, ...soldOutDeals];
};

export const sortInvoiceDiscountingDeals = (deals) => {
  const soldOutDeals = deals.filter((d) => d.isSoldOut);
  const openDeals = deals.filter((d) => !d.isSoldOut);
  const sortedByClosingDate = openDeals.sort((a, b) => {
    const aDate = new Date(a.closeRaiseDate);
    const bDate = new Date(b.closeRaiseDate);
    return aDate - bDate;
  });
  return [...sortedByClosingDate, ...soldOutDeals];
};

// @TDS
export const allowableRentalWithoutTDSPenalty = (existingMonthlyRental) => {
  // Per FY 2.4L allowed
  if (existingMonthlyRental >= 20000) {
    return 0;
  }

  return 20000 - existingMonthlyRental;
};

// @TDS
export const isNewSelectedQtyWillBreachTDSLimit = (existingMonthlyRental, newMonthlyRental) => {
  const cashFlowForNextMonth = existingMonthlyRental + newMonthlyRental;
  // Per FY 2.4L allowed
  if (cashFlowForNextMonth >= 20000) {
    return true;
  }

  return false;
};

export const mergeSchedules = (schedules) => {
  const schedulesMap = {};
  schedules?.forEach((s) => {
    schedulesMap[s.disbursementCalendardate] = schedulesMap[s.disbursementCalendardate] || {
      disbursementCalendardate: s.disbursementCalendardate,
      scheduleStatus: s.scheduleStatus,
      payments: [],
      amount: 0,
      tds: 0,
      gst: 0,
      netPayout: 0,
      pgTransactionId: s.pgTransactionId,
      reinvestmentOn: s.reinvestmentOn,
    };
    schedulesMap[s.disbursementCalendardate].pgTransactionId = s.pgTransactionId;
    schedulesMap[s.disbursementCalendardate].scheduleType = s.scheduleType;
    schedulesMap[s.disbursementCalendardate].amount += s.amount;
    schedulesMap[s.disbursementCalendardate].tds += s.tds;
    schedulesMap[s.disbursementCalendardate].gst += s.gst;
    schedulesMap[s.disbursementCalendardate].netPayout += s.amount + s.gst - s.tds;
    s.payments = s.payments || [];
    schedulesMap[s.disbursementCalendardate].payments = [
      ...schedulesMap[s.disbursementCalendardate].payments,
      s.payment,
    ];
  });
  return Object.keys(schedulesMap).map((scheduleDate) => schedulesMap[scheduleDate]);
};

export const getViewObjectForInvestment = (investment) => {
  const isInvestmentSuccesfulStatus = isInvestmentSuccesful(investment);
  const isInvestmentDocumentPendingStatus = isInvestmentDocumentPending(investment);
  const isInvestmentPaid = isInvestmentSuccesfulStatus || isInvestmentDocumentPendingStatus;
  const tenureMap = investment?.deal?.assetRequests.reduce((coll, arq) => {
    return {
      ...coll,
      [arq.id]: arq.tenure,
    };
  }, {});
  let tenure = 0;
  let assetsLeased = 0;
  investment.qty?.forEach((q) => {
    assetsLeased += q.qty;
    if (tenureMap[q.assetRequest.id] > tenure) {
      tenure = tenureMap[q.assetRequest.id];
    }
  });
  if (!investment.investmentId) investment.tenure = tenure;
  investment.assetsLeased = assetsLeased;
  investment.schedules = investment?.schedules
    ? mergeSchedules(
        investment.schedules.filter(
          (s) =>
            s.scheduleStatus === 'SETTLED' ||
            s.scheduleStatus === 'PENDING' ||
            s.scheduleStatus === 'HOLD' ||
            s.scheduleStatus === 'PENDING_PG' ||
            s.scheduleStatus === 'INITIATED',
        ),
      )
    : [];
  investment.nextSchedule =
    investment.schedules
      .filter(
        (s) =>
          s.scheduleStatus !== 'SETTLED' ||
          s.scheduleStatus !== 'HOLD' ||
          s.scheduleStatus !== 'PENDING_PG' ||
          s.scheduleStatus !== 'INITIATED',
      )
      .sort((a, b) => a.disbursementCalendarDate - b.disbursementCalendarDate)[0] || {};
  investment.nextDisbursement = isInvestmentPaid
    ? getScheduleDateFromSchedule(investment.nextSchedule)
    : '-';

  investment.invested = investment.investedAmount || investment.investmentAmount;

  if (isInvestmentPaid) {
    investment.totalAmount = 0;

    investment.settled = 0;
    investment.totalSchedules = 0;
    investment.returnReceived = investment.schedules.reduce((c, s) => {
      if (s.scheduleStatus === 'SETTLED') {
        investment.settled++;
        c += s.amount;
      }
      investment.totalSchedules++;
      investment.totalAmount += s.amount;
      return c;
    }, 0);

    investment.percentageReceived = parseInt(
      (investment.returnReceived / investment.totalAmount) * 100,
      10,
    );
  } else {
    investment.totalAmount = 0;
    investment.returnReceived = 0;
    investment.percentageReceived = 0;
  }
  if (investment.poolName) investment.investedDealType = 'INVOICE_DISCOUNTING';
  return investment;
};

export const SCHEDULE_STATUS = {
  PENDING: {
    text: 'Scheduled',
    color: 'colors.gray.light',
  },
  SETTLED: {
    text: 'Completed',
    color: 'primary.main',
  },
  HOLD: {
    text: 'Delayed',
    color: 'colors.red.main',
  },
  PENDING_PG: {
    text: 'Processing',
    color: 'colors.gray.light',
  },
  INITIATED: {
    text: 'Initiated',
    color: 'colors.gray.light',
  },
};

export function getScheduleStatusObject(status) {
  return SCHEDULE_STATUS[status] || { text: status, color: '' };
}

export function getTheCurrentFY() {
  const currentDate = new Date();
  if (currentDate.getMonth() <= 2) {
    return `${currentDate.getFullYear() - 1}-${currentDate.getFullYear()}`;
  }

  return `${currentDate.getFullYear()}-${currentDate.getFullYear() + 1}`;
}

export function getTheFY(date) {
  if (date.getMonth() <= 2) {
    return `${date.getFullYear() - 1}-${date.getFullYear()}`;
  }

  return `${date.getFullYear()}-${date.getFullYear() + 1}`;
}

export function isOfCurrentFY(rawDate) {
  const date = new Date(rawDate);
  const currentFY = getTheCurrentFY();
  const dateFY = getTheFY(date);
  if (currentFY === dateFY) {
    return true;
  }

  return false;
}

export function processSuccessfulInvestments(month, successfulInvestments = []) {
  let totalPayoutAmount = 0;
  let lesseePayoutInvestmentsList = [];
  let lesseeInvestmentMap = {};

  successfulInvestments?.forEach((investment) => {
    let totalPayoutAmountForAnInvestment = 0;
    let payoutDate = null;
    const investmentId = investment?.id;
    const lessee = investment?.deal?.lessee || {};
    const deal = {
      company: investment?.deal?.company,
    };
    const investmentType = investment?.investedDealType;
    if (investment?.poolName) {
      const netPayout = investment.payoutAmount;
      const currentDate = new Date(month);
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      const disbursementCalendardate = new Date(investment.payoutDate);
      const disbursementCalendarYear = disbursementCalendardate.getFullYear();
      const disbursementCalendardateMonth = disbursementCalendardate.getMonth();
      const isCurrentMonthSchedule =
        disbursementCalendardateMonth === currentMonth && currentYear === disbursementCalendarYear;
      if (isCurrentMonthSchedule) {
        totalPayoutAmountForAnInvestment += netPayout;
        payoutDate = investment.payoutDate;
      }
    } else
      investment.schedules?.forEach((sch) => {
        const scheduleStatus = sch?.scheduleStatus;
        const netPayout = sch?.netPayout;
        const currentDate = new Date(month);
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        const disbursementCalendardate = new Date(sch?.disbursementCalendardate);
        const disbursementCalendarYear = disbursementCalendardate.getFullYear();
        const disbursementCalendardateMonth = disbursementCalendardate.getMonth();

        const isCurrentMonthSchedule =
          disbursementCalendardateMonth === currentMonth &&
          currentYear === disbursementCalendarYear;
        if (isCurrentMonthSchedule) {
          totalPayoutAmountForAnInvestment += netPayout;
          payoutDate = sch?.disbursementCalendardate;
        }
      });

    totalPayoutAmount += totalPayoutAmountForAnInvestment;

    if (totalPayoutAmountForAnInvestment !== 0) {
      lesseePayoutInvestmentsList.push({
        investmentId,
        lessee,
        deal,
        payoutDate,
        investmentType,
        totalPayoutAmountForAnInvestment,
      });
    }

    const investedAmount = investment?.investedAmount;

    const key = lessee.id + '_' + investmentType;
    if (lesseeInvestmentMap[key]) {
      const lesseeInvestmentValue = lesseeInvestmentMap[key];
      lesseeInvestmentValue.totalAmount += investedAmount;
      lesseeInvestmentValue.investments.push(investment);
    } else {
      lesseeInvestmentMap[key] = {
        totalAmount: investedAmount,
        investmentType,
        lessee,
        deal,
        investments: [investment],
      };
    }

    // Reset the variable
    totalPayoutAmountForAnInvestment = 0;
    payoutDate = null;
  });

  return {
    totalPayoutAmount,
    lesseePayoutInvestmentsList,
    lesseeInvestmentList: Object.values(lesseeInvestmentMap),
  };
}

export function processPendingInvestments(pendingInvestments = []) {
  let totalPendingInvestmentsAmount = 0;
  let totalBankTransferInvestmentsAmount = 0;
  let totalOnlineInvestmentsAmount = 0;

  pendingInvestments?.forEach((investment) => {
    const investedAmount = investment?.investedAmount;
    if (isInvestmentPaymentPendingUPI(investment)) {
      totalOnlineInvestmentsAmount += investedAmount;
    }

    if (isInvestmentProofUploadPending(investment)) {
      totalBankTransferInvestmentsAmount += investedAmount;
    }
    totalPendingInvestmentsAmount += investedAmount;
  });
  return {
    totalPendingInvestmentsAmount,
    totalBankTransferInvestmentsAmount,
    totalOnlineInvestmentsAmount,
    investments: pendingInvestments,
  };
}

export function sortInvestmentList({ investments = [] }) {
  return investments
    .reduce(
      (seats, i) => {
        switch (i.investmentStatus) {
          case 'DOCUMENT_SIGN_PENDING':
            seats[0].push(i);
            break;
          case 'PAYMENT_PENDING':
            seats[1].push(i);
            break;
          case 'PAYMENT_OFFLINE_PENDING':
            seats[2].push(i);
            break;
          case 'PAYMENT_VERIFICATION_PENDING':
            seats[3].push(i);
            break;
          default:
            seats[4].push(i);
        }
        return seats;
      },
      [
        [], // PAYMENT_PENDING
        [], // PAYMENT_OFFLINE_PENDING
        [], //PAYMENT_VERIFICATION_PENDING
        [], // DOCUMENT_SIGN_PENDING
        [], //OTHERS
      ],
    )
    .reduce((flat, category, index, array) => {
      if (index === array.length - 1) {
        const otherSorting = category.reduce((newSortedOthers, investments) => {
          if (investments.nextDisbursement === '-') {
            return [...newSortedOthers, investments];
          }

          return [investments, ...newSortedOthers];
        }, []);
        return [...flat, ...otherSorting];
      }
      return [...flat, ...category];
    }, []);
}
