import { Box } from './../../mui';
import React from 'react';

function Touchable({ children, onClick, sx = {}, disabled = false,...rest }) {
  return (
    <Box onClick={!disabled && onClick} sx={{ ...disabled ? {} :{ cursor: 'pointer'}, ...sx }} {...rest}>
      {children}
    </Box>
  );
}

export default Touchable;
