import React from 'react';
import { Box, styled, Typography, useTheme } from './../../mui';

const StyledTag = styled(Box)(({ theme, backgroundColor, containerStyle = {} }) => {
  return {
    backgroundColor: backgroundColor || theme.palette.primary.main,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    borderRadius: '0.25rem',
    ...containerStyle,
  };
});

function Tag({ title, backgroundColor, containerStyle = {}, labelColor = null, labelVariant=null }) {
  const theme = useTheme();
  return (
    <StyledTag containerStyle={containerStyle} backgroundColor={backgroundColor}>
      <Typography
        sx={!labelVariant ?{
          fontSize: '10px',
          fontWeight: '300',
          lineHeight: '10px',
        }: {}}
        varinat={labelVariant}
        color={labelColor || theme.palette.colors.gray.dark2}
      >
        {title}
      </Typography>
    </StyledTag>
  );
}

export default Tag;