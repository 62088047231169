import React from 'react';
import Radio from './utils/Radio';
import FullScreenLoader from './components/FullScreenLoader';
import {
  Box,
  IconButton,
  Close,
  Button,
  Typography,
  appTheme,
  ActionLayout1,
} from 'ui-commons/ui-kit';
import StatusModal from './components/StatusModal';
import { When } from 'react-if';
import { Grid } from '@mui/material';
import InvestmentsTable from '../pages/dashboard/dealsdetails/atoms/InvestmentsTable';

const radio = new Radio();

export function useRadio() {
  return radio;
}

export function openDrawer(data) {
  radio.broadcast('global', {
    enableDrawer: true,
    drawerData: data,
  });
}

export function closeDrawer(data) {
  radio.broadcast('global', {
    enableDrawer: false,
    drawerData: null,
  });
}

export function openBottomMenu(data = {}, options = { slot: 1 }) {
  switch (options?.slot) {
    case 4:
      radio.broadcast('global', {
        showBottomMenuSlot4: true,
        bottomMenuDataSlot4: data,
      });
      break;
    case 3:
      radio.broadcast('global', {
        showBottomMenuSlot3: true,
        bottomMenuDataSlot3: data,
      });
      break;
    case 2:
      radio.broadcast('global', {
        showBottomMenuSlot2: true,
        bottomMenuDataSlot2: data,
      });
      break;
    default:
      radio.broadcast('global', {
        showBottomMenu: true,
        bottomMenuData: data,
      });
  }
}

export function closeBottomMenu(data = {}, options = { slot: 1 }) {
  switch (options?.slot) {
    case 4:
      if (data?.clear) {
        radio.broadcast('global', {
          showBottomMenuSlot4: false,
          bottomMenuDataSlot4: null,
        });
      } else {
        radio.broadcast('global', {
          showBottomMenuSlot4: false,
        });
      }
      break;
    case 3:
      if (data?.clear) {
        radio.broadcast('global', {
          showBottomMenuSlot3: false,
          bottomMenuDataSlot3: null,
        });
      } else {
        radio.broadcast('global', {
          showBottomMenuSlot3: false,
        });
      }
      break;
    case 2:
      if (data?.clear) {
        radio.broadcast('global', {
          showBottomMenuSlot2: false,
          bottomMenuDataSlot2: null,
        });
      } else {
        radio.broadcast('global', {
          showBottomMenuSlot2: false,
        });
      }
      break;
    default:
      if (data?.clear) {
        radio.broadcast('global', {
          showBottomMenu: false,
          bottomMenuData: null,
        });
      } else {
        radio.broadcast('global', {
          showBottomMenu: false,
        });
      }
  }
}

export function openDialog(data) {
  radio.broadcast('global', {
    showDialog: true,
    dialogData: data,
  });
}

export function closeDialog(data) {
  radio.broadcast('global', {
    showDialog: false,
    dialogData: data,
  });
}

export function showSnackBar(data) {
  radio.broadcast('global', {
    showSnackBar: true,
    snackBarData: data,
  });
}

export function closeSnackBar(data) {
  radio.broadcast('global', {
    showSnackBar: false,
    snackBarData: data,
  });
}

export function showFullScreenLoader(data) {
  openDialog({
    component: <FullScreenLoader {...data} />,
    noDialogContent: true,
    disableInteraction: true,
  });
}

export function closeFullScreenLoader() {
  closeDialog();
}

/**
 * Open a url in iframe. Note: The url which has X-Frame-Options in response header will not load in iframe
 * @param {*} param0 { src, srcdoc, onLoad, onClose, sandBox }
 */
export function openIframe({
  src = '',
  srcDoc = '',
  onLoad = null,
  onClose = () => {},
  sandbox = '',
}) {
  const Component = (
    <Box sx={{ width: '100vw', height: '100vh' }}>
      <IconButton
        aria-label="close"
        onClick={() => {
          typeof onClose === 'function' && onClose();
          closeDialog();
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.colors.black,
        }}
      >
        <Close />
      </IconButton>
      <iframe
        onLoad={onLoad}
        title={'iframe-dialog'}
        name={'iframe'}
        src={src}
        {...(!!srcDoc ? { srcDoc } : {})}
        sandbox="allow-same-origin allow-scripts"
        style={{ width: '100vw', height: '100vh' }}
      />
    </Box>
  );

  openDialog({
    component: Component,
    noDialogContent: true,
  });
}

export function openStatusModal({
  status,
  onClickPrimary = () => {},
  onClose = null,
  primaryButtonText = '',
  title = '',
  subTitle = '',
  textAlignment = 'left',
}) {
  openDialog({
    noDialogContent: true,
    disableInteraction: true,
    fullScreen: true,
    component: (
      <StatusModal
        status={status}
        onClose={() => {
          typeof onClose === 'function' && onClose();
          closeDialog();
        }}
        primaryButtonText={primaryButtonText}
        onClickPrimary={onClickPrimary}
        title={title}
        textAlignment={textAlignment}
        subTitle={subTitle}
      />
    ),
  });
}

/**
 * According to new design system
 */
export function openBottomSheet({
  header = null,
  body,
  ctaPrimary = null,
  ctaSecondary,
  ctaAlignment = 'vertical',
  closable = true,
}) {
  const showCtaComponent = !!ctaPrimary || !!ctaSecondary;
  const showCtaPrimary = !!ctaPrimary;
  const showCtaSecondary = !!ctaSecondary;
  const buttonAlign = (alignment) => {
    return alignment === 'vertical' ? 12 : 6;
  };

  openBottomMenu({
    closable: closable,
    component: (
      <Grid container spacing={2} sx={{ padding: '24px 0px' }}>
        <When condition={header}>
          <Grid item xs={12}>
            {header}
          </Grid>
        </When>
        <When condition={body}>
          <Grid item xs={12}>
            {body}
          </Grid>
        </When>
        <When condition={showCtaComponent}>
          <Grid item xs={12}>
            <Grid container spacing={2} sx={{ padding: '0px 20px' }}>
              <When condition={!!showCtaPrimary}>
                <Grid item xs={buttonAlign(ctaAlignment)}>
                  <Button
                    fullWidth
                    variant={'PRIMARY'}
                    size={'medium'}
                    onClick={ctaPrimary?.onClick}
                  >
                    {ctaPrimary?.text}
                  </Button>
                </Grid>
              </When>
              <When condition={!!showCtaSecondary}>
                <Grid item xs={buttonAlign(ctaAlignment)}>
                  <Button
                    fullWidth
                    variant={'SECONDARY'}
                    size={'medium'}
                    onClick={ctaSecondary?.onClick}
                  >
                    {ctaSecondary?.text}
                  </Button>
                </Grid>
              </When>
            </Grid>
          </Grid>
        </When>
      </Grid>
    ),
  });
}

export function closeBottomSheet(data) {
  closeBottomMenu(data);
}

export function openDynamicModal({
  renderComponent = () => {},
  options = { slot: 4 },
  componentProps = {},
  overflowVisible = false,
}) {
  const isMobileAndTablet = window.innerWidth < 900;
  const modalCloseHandler = !isMobileAndTablet
    ? closeDialog
    : () => {
        closeBottomMenu({}, { slot: options?.slot });
      };
  if (isMobileAndTablet) {
    openBottomMenu(
      {
        component: (
          <Box
            sx={{
              backgroundColor: appTheme.palette.colors.white,
              borderRadius: '12px',
            }}
          >
            {renderComponent({ modalCloseHandler, isMobileAndTablet })}
          </Box>
        ),
        ...componentProps,
        onClose: () => {
          if (typeof componentProps?.onClose === 'function') componentProps?.onClose();
          modalCloseHandler();
        },
      },
      {
        slot: options?.slot,
      },
    );
  } else {
    openDialog({
      noDialogContent: options?.noDialogContent ?? true,
      component: (
        <Box
          sx={{
            backgroundColor: appTheme.palette.colors.white,
            borderRadius: '12px',
            width: options?.width || '470px',
          }}
        >
          {renderComponent({ modalCloseHandler, isMobileAndTablet })}
        </Box>
      ),
      ...componentProps,
    });
  }
}

export function closeDynamicModal(options = { slot: 4 }) {
  const isMobileAndTablet = window.innerWidth < 900;
  if (isMobileAndTablet) {
    closeBottomMenu({ clear: true }, { slot: options?.slot });
    closeBottomSheet({ clear: true }, { slot: options?.slot ?? 4 });
  } else {
    closeDialog();
  }
}
