import React from 'react';
import { Typography as MaterialTypography } from '../../mui';
import get from 'lodash/get';

function Typography(rest) {
  return (
    <MaterialTypography
      color={(theme) => {
        return get(theme, rest?.color, rest?.color);
      }}
      {...rest}
    />
  );
}

export default Typography;
