import { Box, styled } from '../../mui';
import Typography from '../Typography';
import { appTheme } from '../../configs/theme';

function CardOutline({ children, sx = {}, ...rest }) {
  return (
    <Box
      sx={{
        border: '1px solid ' + appTheme.palette.primary.main,
        padding: 1,
        marginBottom: 1,
        borderRadius: 1,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
const SmallInfoCardImage = styled('img')(({ theme }) => {
  return {
    width: '32px',
    height: '32px',
  };
});
export default function SmallInfoCard({ img = null, text = null, ...rest }) {
  return (
    <CardOutline display={'flex'} alignItems={'center'} marginRight={2} {...rest}>
      {!!img && (
        <Box>
          <SmallInfoCardImage src={img} />
        </Box>
      )}
      {!!text && (
        <Box marginLeft={1}>
          <Typography
            fontSize={{ xs: '16px', sm: '8px', md: '8px', lg: '16px' }}
            fontWeight={{ xs: '300', sm: '300', md: '300', lg: '300' }}
          >
            {text}
          </Typography>
        </Box>
      )}
    </CardOutline>
  );
}
