import { Waypoint } from "react-waypoint";
import { useMemo, useState } from 'react';
import { useTheme, Box } from '../../mui';

export default function SectionList({
    sectionData = [],
    onTriggerIndex = ()=>{}
}){

  const listJsx = useMemo(()=>{
    return sectionData.map((s, index) => {
        return <Waypoint
          key={index.toString()}
          onEnter={() => {
            onTriggerIndex(index);
          }}
          topOffset={"39%"}
          bottomOffset={'60%'}
        >
          <Box>{s}</Box>
        </Waypoint>
      })
  },[sectionData.length]);

  return <Box sx={{
    position:'relative', display:'flex', flexDirection:'column',
  }}>
    {listJsx}
  </Box>
}