import decode from 'jwt-decode';

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

export const getAvailableAssets = (arqs) => {
  if (!Array.isArray(arqs)) {
    return [];
  }
  return arqs.filter((arq) => arq.available > 0).map((a) => a.id);
};

export const shouldAssetOfMultiAssetBeListed = (arqs, arq) =>
  getAvailableAssets(arqs).indexOf(arq.id) > -1;

export function isLoggedIn() {
  const token = localStorage.getItem('token');
  if (token && token !== '' && token !== '""') {
    try {
      return isAuthenticated();
    } catch (e) {
      return false;
    }
  }

  return false;
}

export function getUserInfo() {
  let data = {};
  try {
    data = decode(getToken());
  } catch (e) {
    data = {
      exp: 0,
    };
  }
  return data;
}

const taxReportsEnabledFor = [48, 49, 42, 79, 36, 548, 2, 26, 678, 104, 184, 700];

export function isTaxReportEnabled() {
  return taxReportsEnabledFor.indexOf(getUserInfo().sub) > -1;
}

export function isEnabled(featureName) {
  switch (featureName) {
    case 'upside':
      return () => true;
  }
}

export function getToken() {
  return getCookie('authtoken') || localStorage.getItem('token');
}

export function getAndSetToken() {
  let token = getCookie('authtoken');
  if (!token) {
    token = localStorage.getItem('token');
  } else {
    eraseCookie('authtoken');
    localStorage.setItem('token', token);
  }
  return token;
}

export function isUserAuthenticated() {
  return getAndSetToken();
}

export function converObjectToJsonString(object) {
  return JSON.stringify(object).replace(/"/g, '\\"');
}

export function getDateFromSQLDate(date) {
  if (!date) {
    return '-';
  }
  return date.split('-').reverse().join('-');
}

export function dateDiffInDays(first, second) {
  return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
}

export function decimalPad(dec, len, chr) {
  chr = chr || '0';
  dec = '' + dec;

  if (!len) return dec;

  if (dec.indexOf('.') < 0) {
    dec += '.00';
  }
  let p = dec.indexOf('.');
  p = p !== -1 ? dec.length - p - 1 : -1;

  for (var m = p; m < len; m++) dec += chr;

  return dec;
}

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * To round down a string or number value.
 * @param value
 * @returns {number}
 */
export function toRoundDown(value) {
  if (!value && value !== 0) return null;
  if (typeof value === 'string') {
    return Math.floor(parseInt(value));
  }

  if (typeof value === 'number') {
    return Math.floor(value);
  }
}

export function hideWithExternalChar(text = null, char = '*', visibleLength = 4) {
  if (!text) return text;
  const lengthToHide = text.length > visibleLength ? text.length - visibleLength : text.length;
  const newText = text.split('').map((item, i) => {
    if (i < lengthToHide) {
      return char;
    } else {
      return item;
    }
  });

  return newText;
}

export function isAuthenticated() {
  let payload = getUserInfo();
  let now = Math.round(Date.now() / 1000);
  if (payload.exp - now < 3600) {
    return false;
  }
  return true;
}

export const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

// To set a cookie
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export const eraseCookie = (name) => {
  document.cookie = name + '=;path=/;domain=.tapinvest.in;Max-Age=-99999999;';
};

export function scrollToTop() {
  if (window) {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }
}

export function objectToUrlQueryParams(obj) {
  const keys = Object.keys(obj);
  if (keys.length > 0) {
    return keys.reduce((acc, key, index, ar) => {
      if (ar.length - 1 === index) {
        return acc + `${key}=${obj[key]}`;
      }
      return acc + `${key}=${obj[key]}&`;
    }, '?');
  }
  return '';
}

export function b64_to_utf8(str) {
  return decodeURIComponent(window.atob(str));
}

/**
 *
 * @param arr array of objects
 * @param key key of the object to be used as key name  [{ k: 1}, {k: 2}]  => {1: {k:1}, 2: {k:2}}
 * @param valueKey if you want to extract internal value [{ k: {valueKey: 4 }}, {k: {valueKey: 5 }}]  => {1: {k:4}, 2: {k:5}}
 * @param overrideVal  if you want to override value [{ k: {valueKey: 4 }}, {k: {valueKey: 5 }}]  => {1: overrideVal, 2: overrideVal}
 * @param firstValue  if you want to override firt value [{ k: {valueKey: 4 }}, {k: {valueKey: 5 }}]  => {1: overrideVal, 2: {valueKey: 5 }}
 * @returns {*}
 */
export function arrayToObj(arr, key, valueKey, overrideVal, firstValue) {
  return arr.reduce((acc, o, index) => {
    let obj = o;
    if (firstValue && index === 0) {
      obj = firstValue;
    } else if (typeof overrideVal !== 'undefined') {
      obj = overrideVal;
    } else if (valueKey) {
      obj = o[valueKey];
    }
    return { ...acc, [o[key]]: obj };
  }, {});
}

export function sumOfFieldsInObject(obj, key) {
  return Object.keys(obj).reduce((sum, o) => sum + o[key], 0);
}

export function getPaymentSchedules(
  unitPrice,
  selectedQty,
  tenure,
  payoutAmount,
  saleValue,
  firstPayoutDate,
  leaseEndDate,
) {
  const now = new Date();
  // Hack to set investment on last date of month
  // Setting month to next month and setting date 0 shifts date to last day of current month
  const investmentDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  return [
    {
      date: investmentDate,
      amount: -(unitPrice * (selectedQty || 1)),
    },
    ...[...Array(tenure)].map((v, index) => {
      let d = new Date(firstPayoutDate);
      d.setMonth(d.getMonth() + index);
      return {
        date: d,
        amount: payoutAmount * (selectedQty || 1),
      };
    }),
    {
      date: new Date(leaseEndDate),
      amount: saleValue * (selectedQty || 1),
    },
  ];
}

export function getFormattedDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
}

export function fetchHotjarId() {
  var hjUID = '';
  try {
    if (typeof window.hj != 'undefined' && window.hj.hasOwnProperty('globals')) {
      hjUID = window.hj.globals.get('userId');
    }
  } catch (err) {}

  // return hjUID or an empty string ""
  return hjUID;
}

// To set the character at the specific position in a text box
export function setCaretPosition(ref, caretPos) {
  var el = ref;
  if (el !== null) {
    if (el.createTextRange) {
      var range = el.createTextRange();
      range.move('character', caretPos);
      range.select();
      return true;
    } else {
      if (el.selectionStart || el.selectionStart === 0) {
        el.focus();
        el.setSelectionRange(caretPos, caretPos);
        return true;
      } else {
        // fail city, fortunately this never happens (as far as I've tested) :)
        el.focus();
        return false;
      }
    }
  }
}

export function shallowCheck(source, target) {
  // Array, Object, String, Number, Symbol
  const typeofSource = typeof source;
  const typeofTarget = typeof target;
  if (typeofSource === typeofTarget) {
    if (typeofTarget === 'object') {
      if (Array.isArray(source) && Array.isArray(target)) {
        if (source.length !== target.length) {
          return false;
        }
        if (!source.length) return false;

        let allTrue = true;
        for (let index = 0; index < source.length; index++) {
          let test = shallowCheck(source[index], target[index]);
          if (!test) {
            allTrue = false;
          }
        }

        return allTrue;
      } else if (Array.isArray(source) || Array.isArray(target)) {
        return false;
      } else if (source === null || target === null) {
        return false;
      } else {
        let keysInSource = Object.keys(source);
        let keysInTarget = Object.keys(target);
        if (keysInSource.length !== keysInTarget.length) {
          return false;
        }
        return keysInSource.every((key) => {
          const keyExist = target[key];
          if (!keyExist) return false;
          const ifSameValue = target[key] === source[key];
          console.log(ifSameValue);

          if (!ifSameValue) {
            return false;
          }

          return true;
        });
      }
    } else {
      return source === target;
    }
  } else {
    return false;
  }
}

export function getQueryParamFromUTMCookie() {
  const utms = getCookie('_lrutm');
  if (utms) {
    const utmObject = JSON.parse(atob(utms));

    return parseObjectToQueryParam(utmObject);
  }

  return '';
}

export function parseObjectToQueryParam(obj) {
  return Object.keys(obj).reduce((query, item) => {
    return query + (query === '' ? '' : '&') + `${item}=${obj[item]}`;
  }, '');
}

/**
 * To remove country code from phone
 * @param phone
 * @param countryISD
 * @returns {string}
 */
export const removeCountryCodeFromPhone = (phone, countryISD) => {
  const regexToMatchCountryCode = `^\\+${countryISD.slice(1)}`;
  let phoneWithoutCountryCode = '';
  if (new RegExp(regexToMatchCountryCode, 'i').test(phone)) {
    phoneWithoutCountryCode = phone.replace(countryISD, '');
  } else {
    phoneWithoutCountryCode = phone;
  }
  return phoneWithoutCountryCode;
};

// handling 2 decimal places in investment/add/withdraw amount
export const handleDecimalsOnValue = (value) => {
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  return value.match(regex)[0];
};
