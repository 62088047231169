import React from 'react';
import { Typography, Box } from 'ui-commons/ui-kit';

function TaxApplicableInvoiceDiscounting() {
  return (
    <Box>
      <Typography variant={'subtitle'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          fontWeight={'bold'}
          fontSize={'27px'}
          fontFamily={'Inter, sans-serif'}
          height={'100px'}
          marginLeft={'20px'}
        >
          Tax Note
        </Box>
      </Typography>
      <Box pl={'20px'} pr={'20px'}>
        <Typography
          fontSize={'16px'}
          lineHeight={'24px'}
          fontWeight={'300'}
          mb={2}
          display={'block'}
        >
          All investments in Invoice Discounting attract a flat 10% Tax Deducted at source (TDS) on
          the return amount.
        </Typography>
        <Typography
          fontSize={'16px'}
          lineHeight={'24px'}
          fontWeight={'300'}
          mb={2}
          display={'block'}
        >
          For any queries please write to
        </Typography>
        <a href={'mailto:support@tapinvest.in'} target={'_blank'}>
          <Typography
            fontSize={'16px'}
            lineHeight={'24px'}
            fontWeight={'300'}
            mb={2}
            display={'block'}
            sx={{
              overflowWrap: 'break-word',
            }}
          >
            support@tapinvest.in
          </Typography>
        </a>
      </Box>
    </Box>
  );
}

export default TaxApplicableInvoiceDiscounting;
