import { When } from 'react-if';
import { Box, Currency, IconButton, InfoIcon, Tooltip, Typography, useTheme } from '../../../../ui-commons/ui-kit';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { getFormattedDate, toRoundDown } from 'utils';
import React from 'react';
import { useMobileLayout } from '../../../../hooks/layout';

function InvestmentsTable({ investments = [], applyTDS = false, tdsPercentage = 0}){
  const isMobile = useMobileLayout();
  const theme = useTheme();
  return (<Box
    flex={1}
    onClick={(e) => {
      e.stopPropagation();
    }}
    sx={{
      height: '100%',
      overflowY: isMobile ? 'scroll' : 'auto',
    }}
  >
    <TableContainer sx={{ maxHeight: 240 }} >
    <Table stickyHeader aria-label="sticky table">
      <TableHead
        sx={{
          background: '#F6F7FB',
          color: '#4F4F4F',
        }}
      >
        <TableCell
          sx={{
            paddingLeft: '20px',
            background: theme.palette.background.paper,
            width: {
              xs: `20%'`,
              sm: `20%`,
            },
          }}
        >
          <Box>
            <Typography variant={'phTextSmall'} color={'text.label'}>#</Typography>
          </Box>
        </TableCell>
        <TableCell
          sx={{
            width: {
              xs: `20%'`,
              sm: `20%`,
            },
          }}
        >
          <Typography variant={'phTextSmall'} color={'text.label'}>Date</Typography>

        </TableCell>
        <TableCell
          sx={{
            width: {
              xs: `20%'`,
              sm: `20%`,
            },
          }}
        >
          <Typography variant={'phTextSmall'} color={'text.label'}>Investment</Typography>
        </TableCell>
        <TableCell
          sx={{
            width: {
              xs: `20%'`,
              sm: `20%`,
            },
          }}
        >
          <Typography variant={'phTextSmall'} color={'text.label'}>Payout Amount</Typography>

        </TableCell>
        <When condition={!!applyTDS}>
          <TableCell
            sx={{
              width: {
                xs: `15%'`,
                sm: `15%`,
              },
            }}
          >
            <Typography variant={'phTextSmall'} color={'text.label'}>TDS</Typography>

          </TableCell>
        </When>
        <When condition={!!applyTDS}>
          <TableCell
            sx={{
              width: {
                xs: `15%'`,
                sm: `15%`,
              },
            }}
          >
            <Typography variant={'phTextSmall'} color={'text.label'}>Net Amount</Typography>

          </TableCell>
        </When>

      </TableHead>
      {investments.map((value, index) => {
        // Get tds amount on monthly rental from lessee
        const tdsAmount = (tdsPercentage / 100)* value.monthlyRental;
        // Net amount
        const netAmount = value.monthlyPayout - tdsAmount;
        return (
          <TableRow
            key={+value.date}
            sx={{
              color: '#000000',
            }}
          >
            <TableCell sx={{ paddingLeft: '20px' }}>{index + 1}</TableCell>
            <TableCell>{getFormattedDate(new Date(value.date))}</TableCell>
            <TableCell>
              <Currency decimalScale={0} value={toRoundDown(value.amount) || '0'} />
            </TableCell>
            <TableCell>
              <Currency decimalScale={0} value={toRoundDown(value.monthlyPayout) || '0'} />
            </TableCell>
            <When condition={applyTDS}>
              <TableCell>
                <Currency decimalScale={0} value={toRoundDown(tdsAmount) || '0'} />
              </TableCell>
            </When>
            <When condition={applyTDS}>
              <TableCell>
                <Currency decimalScale={0} value={toRoundDown(netAmount) || '0'} />
              </TableCell>
            </When>
          </TableRow>
        )}
      )}
    </Table>
    </TableContainer>
  </Box>)
}

export default InvestmentsTable;