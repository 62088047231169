import { styled } from './../../mui';

const Bullet = styled('span')(({ theme }) => {
  return {
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: 1.5,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: 2,
    },
  };
});

export default Bullet;
