import React from 'react';
import {
  Box,
  PictureAsPdf,
  Card,
  DownloadForOfflineIcon,
  PictureAsPdfRoundedIcon,
  PlayCircleRoundedIcon,
  ArticleRoundedIcon,
  DescriptionRoundedIcon,
} from '../../mui';
import Typography from '../Typography';
import { appTheme } from '../../configs/theme';

const DOCTYPE = {
  youtube: PlayCircleRoundedIcon,
  pdf: PictureAsPdfRoundedIcon,
  xlsx: DescriptionRoundedIcon,
  xl: DescriptionRoundedIcon,
  file: ArticleRoundedIcon,
};
function ExternalDocument({
  type = '',
  href = '',
  linkText = '',
  sx = {},
  onClickLink = null,
  ...rest
}) {
  const IconToShow = DOCTYPE[type] || null;

  return (
    <a
      download
      onClick={(e) => {
        e.meta = { linkText };
        typeof onClickLink === 'function' && onClickLink(e);
      }}
      href={href}
      rel={'noreferrer'}
      target={'_blank'}
      style={{
        textDecoration: 'none',
      }}
    >
      <Card variant="outlined" sx={{ padding: 1.5, borderRadius:'0px', ...sx }} {...rest}>
        {!!IconToShow && <IconToShow style={{ width: '35px' }} />}
        <Box
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Typography variant={'phTextMedium'} color={'text.active'}>{linkText}</Typography>
          <span style={{ marginLeft: '8px' }}>
            <DownloadForOfflineIcon style={{ color: appTheme.palette.colors.gray.light }} />
          </span>
        </Box>
      </Card>{' '}
    </a>
  );
}

export default ExternalDocument;
